import XCore from "src/core/X-core";
import XData from 'src/data/X-data';
import XValidation from 'src/validation/X-validation'
import XFlow from 'src/flow/X-flow';
import XDebug from 'src/debug/X-debug';
import $ from "src/core/libs/zepto-1.1.3.custom";
import _ from "src/core/libs/underscore-1.6.custom";
import Logger from "src/core/logging";
import PubSub from "src/core/pubsub";
import Config from 'src/core/config/Config';
import Constants from 'src/application/constants';
import Registry from 'src/application/registry/applicationRegistry';
import ApplicationController from 'src/application/components/ApplicationController';
import applicationOptions from 'src/application/config/options';
import {toJSON} from "src/core/utils/JSONSerializer";
import {mergeObjects, getPathValue} from "src/core/utils/objectUtils";
import {attrToJSON} from "src/data/bindings/attributeBinder";
import {bindContainer, unbindContainer} from "src/application/bindings/containerBinder";
import {splitDataRef} from "src/data/utils/utils";
import XPromise from 'src/core/utils/defer';
import ViewResolver from "src/application/interfaces/ViewResolver";
import TemplateEngineUnderscore from "src/application/interfaces/TemplateEngineUnderscore";
import ScreenTransitioner from "src/application/interfaces/ScreenTransitioner";
import ModalWindow from "src/application/interfaces/ModalWindow";
import ABTestResolver from "src/application/interfaces/ABTestResolver";
import UIComponent from "src/application/ui/UIComponent";
import DataModel from "src/data/model/DataModel";
import Schema from "src/data/schema/Schema";

// import Zepto extensions for setup
import jqEventBinder from 'src/application/jqExtensions/jqEventBinder'; /* eslint no-unused-vars: 0 */


export default class extends XCore {

   constructor() {
      super(applicationOptions);

      // Export our Base Classes so clients can subclass from them
      this.UIComponent = UIComponent;
      this.DataModel = DataModel;
      this.Schema = Schema;
      this.Registry = Registry;

      // Add some core utility functions
      this.utils = _.extend(this.utils, {
         splitDataRef,
         attrToJSON
      });

      // 1) New up our sub projects so that they instantiate themselves appropriately
      let Nulo = new XData(); /* eslint no-unused-vars: 0 */
      Nulo = new XValidation();
      Nulo = new XFlow();
      Nulo = new XDebug(false);

      // 3) Set the default viewport to use the main validation and formatting options
      Config.addXinchOptions({
         application: {
            viewportOptions: {
               default: {
                  validationOptions: Config.get('validation'),
                  formatOptions: Config.get('formatter')
               }
            }
         }
      });

      // 4) Register our core interfaces and components
      Registry.registerInterface(Constants.interfaces.kViewResolver, new ViewResolver(), false);
      Registry.registerInterface(Constants.interfaces.kTemplateEngine, new TemplateEngineUnderscore(), false);
      Registry.registerInterface(Constants.interfaces.kScreenTransitioner, new ScreenTransitioner(), false);
      Registry.registerInterface(Constants.interfaces.kModalWindow, new ModalWindow(), false);
      Registry.registerInterface(Constants.interfaces.kABTestResolver, new ABTestResolver(), false);

      // 5) Listen for debug panels being added and bind them
      PubSub.subscribe(Constants.events.kDebugPanelAdded, (data) => {
         this.applyBindings(data.id);
      });

      // 6) Self init - if the user has added the data-xinch-app attribute to their app
      _selfInit(this);
   }

   /**
    * Initialize the application with user option overrides
    *   Self-Init will initialize as well, but this API give the user the option to add their own options
    *   And if they don't want to use the self initialization (by adding the data-xinch-app to their HTML)
    * @param userOptions
    * @param $containerToBind
    * @returns {*}
    */
   async init(userOptions = {}, $containerToBind) {
      super.init(userOptions);

      // If the DOM is loaded, then we can init.
      // Will get called when/if the document is ready so we can bind to the HTML
      if (_domReady) {
         // don't double bind!
         if (_initialized) {
            return _initPromise.resolve();
         }
         _initialized = true;

         // Let our submodules or anyone who cares to initialize themselves
         // Application Controller, this means you
         // Debug module too.
         PubSub.publishSync(Constants.events.kInitialize);

         // Apply bindings to application container
         bindContainer($containerToBind || $("html"));

         return _initPromise.resolve();
      }

      // If called externally, we'll resolve when the DOM is ready and our internal init is called.
      return _initPromise;
   }


   /**
    *  Register a uiWidget class as a template for re-use in your views
    * @param name
    * @param componentClass
    * @returns {*}
    */
   registerUIComponentClass(name, componentClass) {
      return Registry.registerUIComponentClass(name, componentClass);
   }

   /**
    * Register a custom binding function that will be called when <%= pkg.appName %> renders a page
    * Binders will be called with the parameters ($container, options) where options are page options
    * @param name - name of your binder
    * @param binderFunc
    * @param preBinder - boolean: execute before any <%= pkg.appName %> binders are run (false means run after)
    * @returns {*}
    */
   registerCustomBinder(name, binderFunc, preBinder) {
      return Registry.registerCustomBinder(name, binderFunc, preBinder);
   }

   /**
    * Set A/B test values into the system so that flows and views can be tested
    *
    * @param testVals - object containing ABTestName:RecipeName pairs
    *                   or query string of A/B test values i.e. test=recipe&test2=recipe2&...
    */
   setABTests(testVals) {
      const abtestResolver = Registry.getInterface(Constants.interfaces.kABTestResolver);

      abtestResolver.setABTests(testVals);
   }


   // Flow

   /**
    *  Start up the flow controller and return the first view to the user
    *
    *  @param flowName [string]  - name of the flow reference to load
    *  @param  options [object]      - [optional] all arguments are optional
    *                  * modal [object]
    *                          - closeButton [boolean] - put a close button on the modal window
    *                  * viewport [string] - load the flow into the specified viewport, will use default viewport specified in application options if
    *                                        none is specified
    *                  * inputVars [object] - name value pairs to be injected into the flow. Will be available via the <span>F</span>LOW_SCOPE model
    *                  * complete : [function] - callback when flow is finished
    *                  * error : [function] - callback if the flow (or contained subflows) errors out
    * @return : none
    */
   startFlow(flowName, options = {}) {
      // Initialize (register bind main page and register viewports
      // if not done so already
      this.init().then(() => {
         return ApplicationController.startFlow(flowName, options);
      });
   }


   /**
    *  Load a view (not as part of a flow)
    *
    *  @param pgReference [string] - reference to the view to load
    *  @param options [object] - [optional] all arguments are optional
    *                  * modal [object]
    *                          - closeButton [boolean] - put a close button on the modal window
    *                          - closeEvent : [string] - event to listen for to close the modal if clients want other ways to close than the close button
    *                          - closeCallback : [function] - function to call when the modal closes
    *                  * scrollTo - DOM element Id to scroll to once the page is finished loading
    *                  * viewport [string] - load the view into the specified viewport, will use viewport specified in application options if none
    *                                        specified
    *                  * success [function] - callback if success loading page
    *                  * error [function] - callback if error loading page
    *                  * complete [function] - callback when page navigated away from (using data-nav)
    * @return : none
    */
   loadPage(pgReference, options = {}) {
      // Initialize (register bind main page and register viewports
      // if not done so already
      this.init().then(() => {
         return ApplicationController.loadPage(pgReference, options);
      });
   }


   /**
    * show a modal window
    *
    * @param pgReference - page/view reference
    * @param options
    *  - closeButton : [boolean] - show close button (default is false)
    *  - closeEvent : [string] - event to listen for to close the modal if clients want other ways to close than the close button
    *  - closeCallback : [function] - function to call when the modal closes
    */
   showModal(pgReference, options = {}) {
      const opts = {
         modal: {...options}
      };
      // Initialize (register bind main page and register viewports
      // if not done so already
      this.init().then(() => {
         ApplicationController.loadPage(pgReference, opts);
      });
   }


   /**
    * Advance to the next state in the flow
    *
    * @param val - (string) navigation value to pass to the controller
    * @param options - navigation options
    *  -  viewport - perform navigation in the specified viewport, default viewport used if none specified
    */
   doNext(val, options = {}) {
      options.nav = val;
      const vp = options.viewport || Config.get('application.defaultViewport');
      PubSub.publish(Constants.events.kNavigation + '.' + vp, options);
   }


   /**
    * Jump to another state, possibly in a different flow
    *
    * @param path - (string) path value to pass to the controller ('~' delimited set of nodenames)
    * @param options - navigation options
    *  -  viewport - perform navigation in the specified viewport, default viewport used if none specified
    */
   doJump(path, options = {}) {
      options.jump = path;

      const vp = options.viewport || Config.get('application.defaultViewport');
      PubSub.publish(Constants.events.kNavigation + '.' + vp, options);
   }

   /*********************************************************
    // Bind to an any loaded data models and set up 2 way binding of values set from other elements
    // Bind events to "a" tags
    // Set up formatters and validators
    //
    // Parameters:
    //    containerId - the dom element identifier
    //                  or a X.$ element
    //    options - supports these boolean options
    //         viewport - containing viewport name
    //         silent - don't dispatch kBindingsApplied event
    **********************************************************/
   applyBindings(containerId, options = {}) {
      return bindContainer(containerId, options);
   }

   /**
    * Unbind from X.$ and internal events so that memory can be cleaned up
    *
    * @param domElement
    * @param excludeSelf
    */
   unbind(domElement, excludeSelf) {
      return unbindContainer(domElement, excludeSelf);
   }
}

// Assign our sub project APIs to our class prototype
Object.assign(XCore.prototype, XData.prototype);
Object.assign(XCore.prototype, XValidation.prototype);
Object.assign(XCore.prototype, XFlow.prototype);
Object.assign(XCore.prototype, XDebug.prototype);

//==============================
// Private
//==============================

let _domReady = false;
let _initialized = false;
const _initPromise = XPromise();

function _selfInit(context) {
   // Wait for the dom to be loaded, then initialize without
   $.ready(() => {
      _domReady = true;
      setTimeout(() => {
         let _opts = {};
         const $x = $("[data-xinch-app]");
         if ($x.length) {
            const appName = $x.attr("data-xinch-app");
            const options = $x.attr("data-xinch-app-options");

            if (options) {
               _opts = toJSON(options, true) || getPathValue(options);
               if (!_.isObject(_opts)) {
                  Logger.error("Invalid or missing Options file specified in data-xinch-app-options: " +
                     options + ".  Using default options", "X.application");
                  _opts = {};
               }
            }
            _opts.appId = appName || _opts.appId;

            // TODO should we only self initialize if we have a data-xinch-app attribute
            // TODO probably so, otherwise clients need to call init explicitly
         }
         context.init(_opts, $x.length ? $x : null);
      }, 0);
   });
}
