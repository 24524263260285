// NB some code from diveintohtml5.org <http://diveintohtml5.org/>
// extend Zepto.support functionality for input types and attributes

import Zepto from 'src/core/libs/zepto-1.1.3.custom';
import {extend_$} from "src/core/utils/$utils";
import PubSub from "src/core/pubsub";

(function ($) {
    $.extend($.support, (function () {
        let i, j;
        const input = document.createElement('input'),
            inputtypes = 'search number range color tel url email date month week time datetime datetime-local',
            inputattrs = 'autocomplete autofocus list placeholder max min multiple pattern required step';

        return {
            inputtypes : (function () {
                const types = inputtypes.split(' '),
                    ret = {};
                for (i = 0, j = types.length; i < j; i++) {
                    input.setAttribute('type', types[i]);
                    ret[types[i]] = (input.type !== 'text');
                }
                return ret;
            })(),

            input : (function () {
                const attrs = inputattrs.split(' '),
                    ret = {};

                for (i = 0, j = attrs.length; i < j; i++) {
                    ret[attrs[i]] = !!(attrs[i] in input);
                }
                return ret;
            })()
        };
    })());

})(Zepto);

extend_$({
    scrollTo : function (to, duration){
        const $el = this;
        const start = $el.scrollTop(),
            change = to - start,
            increment = 20;

        const animateScroll = function(elapsedTime) {
            elapsedTime += increment;
            const position = _easeInOut(elapsedTime, start, change, duration);
            $el.scrollTop(position);
            if (elapsedTime < duration) {
                setTimeout(() => {
                    animateScroll(elapsedTime);
                }, increment);
            }
        };

        animateScroll(0);

        function _easeInOut(currentTime, start, change, duration) {
            currentTime /= duration / 2;
            if (currentTime < 1) {
                return change / 2 * currentTime * currentTime + start;
            }
            currentTime -= 1;
            return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
        }
    },

    isTextInput : function () {
        const texttypes = 'text password hidden search number color tel url email date month week time datetime datetime-local'.split(" "),
            $el = Zepto(this);
        if ($el.is("textarea")) {
            return true;
        }
        else if ($el.is("input") && texttypes.includes($el.attr("type"))) {
            return true;
        }
        return false;
    },
    isUserInputElement : function () {
        const $el = Zepto(this);
        return ($el.is("input") || $el.is("select") || $el.is("textarea") );
    },

    //Helper Function for Caret positioning
    caret : function (begin, end) {
        let range;

        // check to see if the dom element supports selection
        const ok = (/text|password|search|tel|url/).test(this[0].type);
        if (!ok) {
            return {begin : 0, end : 0};
        }

        if (this.length === 0 || this.is(":hidden")) {
            return;
        }

        if (typeof begin == 'number') {
            const _end = (typeof end === 'number') ? end : begin;
            return this.each(function () {
                if (this.setSelectionRange) {
                    this.setSelectionRange(begin, _end);
                }
                else if (this.createTextRange) {
                    range = this.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', _end);
                    range.moveStart('character', begin);
                    range.select();
                }
            });
        }
        else {
            if (this[0].setSelectionRange) {
                begin = this[0].selectionStart;
                end = this[0].selectionEnd;
            }
            else if (document.selection && document.selection.createRange) {
                range = document.selection.createRange();
                begin = 0 - range.duplicate().moveStart('character', -100000);
                end = begin + range.text.length;
            }
            return { begin : begin, end : end };
        }
    },

    /**
     * Un bind from Xinch.$ events and Xinch events to avoid memory leaks
     * @param excludeSelf - [boolean]
     */
    detach : function (excludeSelf) {
        const container = this.get(0);
        PubSub.offIfContextCondition((context) => {
                if (context) {
                    if (context.nodeType === 1 || context.nodeType === 3) {  // element node or text node

                        if (excludeSelf && Zepto.contains(container, context) && container !== context) {
                            Zepto(context).off();
                            return true;
                        }

                        else if (!excludeSelf && (Zepto.contains(container, context) || (context === container))) {
                            Zepto(context).off();
                            return true;
                        }

                    }

                }
                return false;
            }
        );
    }
});