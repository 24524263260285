import XCore from "src/core/X-core";
import _ from 'src/core/libs/underscore-1.6.custom';
import PubSub from 'src/core/pubsub';
import Config from 'src/core/config/Config';
import Constants from 'src/core/constants';
import defaultDebugOptions from "src/debug/config/options";
import debugAPI from "src/debug/debugApi";

export default class XinchDebug extends XCore {
   constructor(enable = true) {
      super(defaultDebugOptions);
      if (enable) {
         this.enableDebugConsole(true);
      }

      /*
       Initialize the debug module in response to the init event
       */
      PubSub.subscribe(`${Constants.events.kOptions}.debug`, () => {
         // The console does not like bindings applied to it.
         this.enableDebugConsole(Config.get('debug.enableConsole'));

      });
   }
}

// add our Flow APIs to the prototype
const f = new debugAPI();
_.each(f, (impl, key) => {
   XinchDebug.prototype[key] = impl;
});
