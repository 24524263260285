import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import Config from 'src/core/config/Config';
import Logger from 'src/core/logging';
import PubSub from 'src/core/pubsub';
import {get$} from "src/core/utils/$utils";
import {toJSON} from "src/core/utils/JSONSerializer";
import {getPathValue} from "src/core/utils/objectUtils";
import {getDataResolver} from 'src/core/utils/getDataResolver';

import Constants from 'src/data/constants';
import ValidationRegistry from "src/validation/registry/ValidationRegistry";


let _jqDataAttributes;

// Create our list of attributes to manage
function _initializeJQDataAttributes() {
   _jqDataAttributes = _.map(Constants.booleanDOMAttributes, (attr) => {
      return "data-" + attr;
   });
   _jqDataAttributes = _.union(_jqDataAttributes, Constants.specialDOMAttributes);
   _jqDataAttributes = _.union(_jqDataAttributes, Config.get('data.attributeConversionList'));
}

_initializeJQDataAttributes();
// and set up for a listener to re-init if they change
PubSub.subscribe(Constants.events.kOptions, _initializeJQDataAttributes);


// -------------------------------
// Function: toJSON
// convert the HTML attribute to JSON
//
// Parameters:
//    htmlAttr - the HTML attribute
// -------------------------------
export function attrToJSON(htmlAttr) {

   if (typeof htmlAttr !== "string") {
      return {};
   }

   let str = htmlAttr.trim();

   // Add object notation if it doesn't exist
   if (str.charAt(0) !== "{") {
      str = '{' + str + '}';
   }

   // Do a non-strict conversion to allow for limited way to express
   // values in HTML without generating parsing errors
   //   - allows for single quotes around names and values
   const obj = toJSON(str, true);
   return obj || {};
}

//// -------------------------------
//// Function: toArray
//// Split attribute into an array of values/
//// Data inside of parentheses will be treated as one element
//// This allows to have comma separated values in parentheses that wont get divided up.
////
//// Parameters:
////    htmlAttr - the HTML attribute
//// -------------------------------
//toArray : function (htmlAttr) {
//    // we need to remove spaces around commas that are used to separate some parameters
//    // but leave other spaces, which could occur within a custom message (as in regex validation)
//    htmlAttr = htmlAttr.replace(/(^\s*)|(\s*$)/g, "");  // remove leading and trailing spaces
//    htmlAttr = htmlAttr.replace(/(\s*,\s*)/g, ","); // remove spaces around commas
//    // Encode stuff between parens (so we can split on commas)
//    htmlAttr = X.utils.replaceCharWithinParenthesis(htmlAttr, ",", "_comma_");
//
//    // now get the comma deliniated list
//    var items = htmlAttr.split(',');
//    X._.each(items, function (value, idx) {
//        // replace foo(something) with foo=something
//        value = value.replace(/([a-zA-Z]+\(.+\))+/g, function (str) {
//            return str.replace(/\(/, "=").replace(/\)$/, "");
//        });
//        // restore commas within individual arguments
//        items[idx] = value.replace(/_comma_/g, ",");
//    });
//    return items;
//}


/**********************************************************************************
 * @private
 * handle dataChange event that affects the data bound to a given attribute
 *
 * @param evtPayload - the published payload of the event
 * @param customArg - object containing the element and other information:
 *          el : the DOM element who's attribute needs to be updated,
 *          attributesToResolve : array of objects that specify the attributes to resolve - null if we need to resolve ALL attributes
 *              attrName : name of the attribute
 *              attrValue : the original attribute value, before it was resolved
 */
export function attrChangeHandler(elementToResolve, attributesToResolve) {
   // we only need the last parameter (our custom argument here)
   if (!elementToResolve) {
      Logger.error("attrChangeHandler error: bad param ", "attributeBinder");
      return;
   }
   const $el = get$(elementToResolve);
   // if we didn't get a list of attributes to resolve,
   // re-evaluate all the attributes in the element
   const attrs = $el.prop("attributesToResolve") || attributesToResolve;
   if (!attrs) {
      return;
   }

   _.each(attrs, (attrObj) => {
      const resolved = getDataResolver().resolveDynamicData(attrObj.attrValue, {});
      _setResolvedAttribute($el, attrObj.attrName, resolved, false /*not initial layout*/);
   });
}


/**********************************************************************************
 * @private
 * Resolve attributes associate with a DOM element that contain model references and/or could be an expression,
 * and listen for future changes that should trigger re-evaluating the attribute value
 *
 * @param el - the DOM element
 */
export function resolveAttributes(el) {
   const $el = get$(el);
   const attrArray = $el.prop("attributesToResolve");
   if (!attrArray) {
      return;
   }

   // Iterate over the attributes that need resolving and collect the model references
   // so we can subscribe for their change events.
   // along the way, resolve the attribute
   const eventToAttrMap = {};
   _.each(attrArray, (attrObj) => {
      const _modelRefs = {};
      const resolved = getDataResolver().resolveDynamicData(attrObj.attrValue, _modelRefs);
      //                if (resolved !== attrObj.attrValue) {
      _setResolvedAttribute(el, attrObj.attrName, resolved, true /* initial layout */);

      if (_.size(_modelRefs) > 0) {
         _.each(_modelRefs, (num, nameSpace) => {
            if (!eventToAttrMap[Constants.events.kDataChange + '.' + nameSpace]) {
               eventToAttrMap[Constants.events.kDataChange + '.' + nameSpace] = [];
            }
            eventToAttrMap[Constants.events.kDataChange + '.' + nameSpace].push(attrObj);
         });
      }
      //                }
   });

   // set up the listener callback for all data-change events
   // (event {type,listener,context} combo needs to be unique for the events to all be added)
   _.each(eventToAttrMap, (attributes, evt) => {
      PubSub.subscribe(
         evt,
         () => {
            attrChangeHandler(el, attributes);
         },
         el
      );
   });

}


/**********************************************************************************
 * @private
 * Set the element's attribute the the resolved value
 *
 * @param el - the element or X.$ object
 * @param attrName - the attribute. if starts with data- then gets converted appropriately before setting
 * @param resolvedValue - the value. in case of data-disabled and data-visible, this gets interpreted
 * @param bInitialLayout - Boolean indicating this is the first time the attribute is resolved.
 *                         we need to to keep some wonky animation happening during hide/show
 */
function _setResolvedAttribute(el, attrName, resolvedValue, bInitialLayout) {

   const $el = get$(el);
   const validationApi = ValidationRegistry.getComponent(Constants.components.kDefaultValidationApi);

   // remove any 'data-' from the attributes if specified
   if (_.contains(_jqDataAttributes, attrName)) {
      attrName = attrName.replace(/^data-/, "");
   }

   // if were dealing with a boolean attribute
   if (_.contains(Constants.booleanDOMAttributes, attrName)) {
      const _enabled = resolvedValue && resolvedValue !== "false" && resolvedValue !== "undefined" && resolvedValue !== "null";

      // DEFAULT FUNCTIONALITY
      if (_enabled) {
         $el.prop(attrName, true);
      }
      else {
         $el.prop(attrName, false);
      }
      return;
   }

   switch (attrName) {
      case 'disabled': {
         const disabled = resolvedValue && resolvedValue !== "false" && resolvedValue !== "undefined" && resolvedValue !== "null";
         // if the client has specifed their own hide/show functionality
         let ddo = $el.attr("data-disabled-options");
         ddo = toJSON(ddo, true) || {};
         let _disfunc = ddo.disabledFunction || Config.get('data.disabledFunction');
         if (_disfunc) {
            if (_.isString(_disfunc)) {
               _disfunc = getPathValue(_disfunc);
            }
            if (_.isFunction(_disfunc)) {
               _disfunc($el, disabled);
               return;
            }
            else {
               Logger.warn("disabled function does not exist - using default: ", "resolveAttributes: disabled");
            }
         }

         // DEFAULT FUNCTIONALITY
         if (disabled) {
            $el.prop("disabled", true);
            $el.addClass("disabled");
         }
         else {
            $el.prop("disabled", false);
            $el.removeClass("disabled");
         }
      }
         break;
      case 'visible':
      case 'hidden' : {
         let show = !!(resolvedValue && resolvedValue !== "false" && resolvedValue !== "undefined" && resolvedValue !== "null");
         show = (attrName == 'visible') ? show : !show;  // if we're here because of the data-hidden, invert the request

         // if the client has specifed their own hide/show functionality
         let dvo = $el.attr("data-visible-options") || $el.attr("data-hidden-options");
         dvo = toJSON(dvo, true) || {};
         let _visfunc = dvo.visibilityFunction || Config.get('data.visibilityFunction');
         if (_visfunc) {
            if (_.isString(_visfunc)) {
               _visfunc = getPathValue(_visfunc);
            }
            if (_.isFunction(_visfunc)) {
               _visfunc($el, show);
               return;
            }
            else {
               Logger.warn("visiblilty function does not exist - using default: ", "resolveAttributes: visible");
            }

         }

         // DEFAULT FUNCTIONALITY
         const status = $($el).prop("x_isVisible");
         if (show) {
            if (status == "true") {
               return;
            }
            if ($($el).parent().is(":hidden") || bInitialLayout) {
               $el.show();
            }
            else {
               $el.show();
               // $el.fadeToggle(300);
               // $el.animate({height : 'toggle', opacity : 'toggle'}, 300);
            }
            $($el).prop("x_isVisible", "true");
         }
         else {
            if (status == "false") {
               return;
            }

            if ($($el).parent().is(":hidden") || bInitialLayout) {
               $el.hide();
            }
            else {
               validationApi.removeErrorTips($el);
               $el.hide();
               // $el.fadeToggle(300);
               // $el.animate({height : 'toggle', opacity : 'toggle'}, 300);
            }
            $($el).prop("x_isVisible", "false");

         }
      }
         break;
      default:
         $el.attr(attrName, resolvedValue);
         break;
   }
}


