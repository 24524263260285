/**
 *
 * Class encapsulation of a model schema
 *
 * Valid elements in a model schema are:
 *      metaData : Object
 *      groupId  : String or Array
 *      key      : {
 *          "defaultValue" : JS Primitive
 *          "validate": Array
 *          "format": String,
 *          "type": String
 *          "accessibility": String,
 *      }
 *
 */
import _ from 'src/core/libs/underscore-1.6.custom';
import {getPathValue} from "src/core/utils/objectUtils";

export default class Schema {

    constructor (def = {}) {
        this._def = JSON.parse(JSON.stringify(def)); // deep clone
        this._def = _.omit(this._def, "metaData");
        this._metaData = def ? def.metaData : undefined;
        this._mutable = def ? (def.metaData ? def.metaData.mutable : false) : true;
        this._groupId = def ? (def.metaData ? def.metaData.groupId : undefined) : undefined;
    }

    hasDefinition () {
        return this.keys().length > 0;
    }

    mutable () {
        return this._mutable;
    }

    groupId  () {
        return this._groupId;
    }

    hasMetaData () {
        return this._metaData !== undefined;
    }

    metaData () {
        return this._metaData || {};
    }

    hasKey (key) {
        const found = getPathValue(key, this._def);
        return !!found;
    }

    defForKey (key) {
        return getPathValue(key, this._def);
    }

    getAll  () {
        return this._def;
    }

    getOptionForKey (key, opt) {
        const k = this.defForKey(key);
        if (k) {
            return (typeof k[opt] !== "undefined") ? k[opt] : undefined;
        }
        return undefined;
    }

    keys () {
        return _.keys(this._def);
    }
}

