/*********************************************************
 // Bind to an any loaded data models and set up 2 way binding of values set from other elements
 // Bind events to "a" tags
 // Set up formatters and validators
 //
 // Parameters:
 //    containerId - the dom element identifier
 //                  or a $ element
 //    options - supports these boolean options
 //         viewport - containing viewport name
 //         silent - don't dispatch kBindingsApplied event
 //         viewscope - the current viewscope instance requesting the binding
 //         flowscope - the current flowscope instance requesting the binding
 **********************************************************/
import $ from 'src/core/libs/zepto-1.1.3.custom';
import XPromise from "src/core/utils/defer";
import PubSub from 'src/core/pubsub';
import {get$} from "src/core/utils/$utils";

import Registry from 'src/application/registry/applicationRegistry';
import Constants from 'src/application/constants';
import {executeCustomBinders, bindTemplate, bindComponents, bindEvents, bindRepeater, bindViewports} from "src/application/view/bindings/binder";
import {bindData, bindAttributes, bindText} from "src/data/bindings/binder";
import {bindValidators, bindFormatters} from "src/validation/bindings/binder";

export function bindContainer(containerId, options = {}) {
   const promise = XPromise();
   const $container = get$(containerId);


   // Execute any registerd prebinders first
   executeCustomBinders($container, true, options);

   bindRepeater($container, options).then(() => { // this returns a promise as the repeater may have templates in it
      bindTemplate($container, options).then(() => { // and templates may have repeaters (that have templates)
         bindViewports($container, options);
         bindComponents($container, options);
         bindEvents($container, options);
         bindAttributes($container, options);
         bindText($container, options);
         bindData($container, options);
         bindValidators($container, options.validationOptions);
         bindFormatters($container, options.formatOptions);

         // Execute any registered post binders
         executeCustomBinders($container, false, options);

         if (!options.silent) {
            PubSub.publish(Constants.events.kBindingsApplied, options);
         }

         promise.resolve();
      });
   });

   return promise;
}


/**********************************************************************************
 * Clean up of memory by unbinding all events associated with children of the DOM element
 *    - should be called BEFORE removing a container from the DOM
 *    - unsubscribe from all events
 *    - remove all viewports in the container
 *
 * @param containerId - the DOM element id or the $ element who's children to unbind
 * @param excludeSelf - don't unbind the passed in parent container, just the children
 */
export function unbindContainer(containerId, excludeSelf) {
   const $container = get$(containerId);
   $container.detach(excludeSelf);

   $("*[data-viewport]", $container).each((idx, el) => {
      const $el = $(el);
      const vid = $el.attr("data-viewport");
      Registry.removeViewport(vid);
   });

   PubSub.publish(Constants.events.kBindingsRemoved);

}

