//-------------------------------------------------------------------------------------
// class: Viewport Controller Class
//
//  About : This class manages the flows and views of in a specific viewport and the navigation events posted by the application to that drive those flows.
//
//  Events :
//      X.constants.events.kBeforePageUnload : page is about to be unloaded
//          {"pageAlias" : <page reference>}
//
//      X.constants.events.kBeforePageLoad : After the page has been resolved, but before the page has loaded;
//          {"pageAlias" : <page reference>, "customerProfile" : <customer profile information - how long they were on the page>}
//
//      X.constants.events.kPageLoaded : after a page is finished loading - before bindings applied to page
//          {"pageAlias": <page reference>}
//
//      X.constants.events.kPageFinalized : after a page is finished loading - and all processing is complete
//          {"pageAlias": <page reference>, "pageProfile" : <page profile information>}
//
//      X.constants.events.kEndFlow : ran off the end of the controller, no more pages
//          X.flow.flowResponse that contains information about the last node
//
//-------------------------------------------------------------------------------------
import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import Logger from 'src/core/logging';
import PubSub from 'src/core/pubsub'
import Profiler from 'src/core/profiler';
import HTMLLoader from 'src/core/loaders/HTMLLoader';
import FlowController from 'src/flow/engine/FlowController';
import ValidationEngine from 'src/validation/engine/ValidationEngine';
import FlowNavigationObject from 'src/flow/engine/FlowNavigationObject';
import uuid from 'src/core/utils/UUID';
import {mergeObjects} from "src/core/utils/objectUtils";

import Constants from 'src/core/constants';
import Config from 'src/core/config/Config';

import ViewportHistory from 'src/application/view/ViewportHistory';
import Registry from "src/application/registry/applicationRegistry";
import {bindContainer, unbindContainer} from "src/application/bindings/containerBinder";
import {getDataApi, getDataResolver} from "src/core/utils/getDataResolver";
import {isElementCompletelyInView, isElementTopInView} from "src/core/utils/html_utils";
import ApplicationController from "src/application/components/ApplicationController";

export default function ViewController (viewId, options = {}) {

    const _viewport = {

        init : function () {
            // Now see if we got a directive to start up a flow or page
            const flow = getDataResolver().resolveDynamicData(_options.getSome || _options.loadFlow);
            const flowOptions = _options.loadFlowOptions || _options.getSomeOptions;
            const page = _options.loadPage;
            const pageOptions = _options.loadPageOptions;
            _.omit(_options, ['getSome', 'loadFlow', 'loadPage', 'getSomeOptions', 'loadPageOptions', 'loadFlowOptions']);


            // set up options based on default settings, config overrides, and passed in settings
            _setOptions();

            // Gen up our history if necessary
            const history = _options.viewportHistory || {};
            // see if we need to track history for this viewport
            if (history.enableTracking) {
                _autoBackNavEvent = history.autoBackNavigationEvent;
                if (_autoBackNavEvent) {
                    _history = new ViewportHistory(_name, history);
                    _history.start();
                }
                else {
                    _publishError("History enabled but no back event specified");
                }
            }

            PubSub.subscribe(Constants.events.kNavigation + "." + _name, _handleNavEvent, _viewport);

            setTimeout(() => {
                if (flow) {
                    _viewport.startFlow(flow, flowOptions);
                }
                else if (page) {
                    _viewport.loadPage(page, pageOptions);
                }
            }, 0);


        },

        deinitilaize : function () {
            PubSub.unsubscribe(_viewport);
            _onControllerEnd();
            if (_history) {
                _history.stop();
                _history = null;
            }
        },

        isBusy : function () {
            return _flowController ? _flowController.isBusy() : false;
        },


        //========================================
        // START A FLOW - creates a new controller
        //    args - options
        //         - inputVars
        //         - complete
        //         - error
        //========================================
        startFlow : function (flowName, args) {
            args = args || {};
            args.options = args.options || {};
            // _isModal = args.modal;

            // if we're already running, clean up the current controller before starting a new one
            if (_flowController) {
                Logger.info("Starting flow with existing controller - deleting current flow");
                _onControllerEnd(null, _startFlow);
            }
            else {
                _startFlow();
            }

            // start a new controller
            function _startFlow () {
                _flowController = new FlowController({
                    context : _name,
                    onEndCB : function (resp) {
                        _onControllerEnd(resp, () => {
                            if (_.isFunction(args.complete)) {
                                args.complete(resp);
                            }
                        });
                    },
                    onErrorCB : function (resp) {
                        _onControllerError(resp, () => {
                            if (_.isFunction(args.error)) {
                                args.error(resp);
                            }
                        });
                    },
                    onModalCB : _onControllerModal

                });


                // jump to the first page
                _viewport.doJump(flowName, args.options, args.inputVars);
            }

        },

        //========================================
        // LOAD A PAGE - does not require a controller
        // args -
        //      options
        //      success
        //      error

        //========================================
        loadPage : function (pageRef, args) {
            args = args || {};
            // _isModal = args.modal;
            args._samePage = (pageRef == _currentPage);
            _flowscopeNameForNonFlows = args._flowscopeName;
            // If we are currently on a page, clean it up
            _endCurrentPage(args, () => {
                _isBusy = true;
                _loadContent(pageRef, args)
                    .then((results) => {
                        if (args.success) {
                            args.success(results);
                        }
                    })
                    .catch((err) => {
                        if (args.error) {
                            args.error(err);
                        }
                    })
                    .then(() => {
                        _isBusy = false;
                    });
            });

        },

        //========================================
        // GET NEXT PAGE - in a flow
        //========================================
        doNext : function (response, options) {

            // If we are currently on a page, clean it up
            _endCurrentPage(options, () => {
                if (_flowController) {
                    _isBusy = true;
                    let _expectingAnotherPage = false;
                    const __loadContent = function (flowResp) {
                        _loadContent(flowResp.value, options)
                            .then(() => {
                                _autoNavHandler.handleAutoNav(flowResp.autoNav, options);
                            })
                            .catch((err) => {
                                _publishError(err)
                            });
                    };

                    _flowController.getNextView(response, (flowResp) => {
                        if (_expectingAnotherPage) {
                            // end the current page to clean up
                            _endCurrentPage(options, () => {
                                __loadContent(flowResp);
                            });
                        }
                        else {
                            __loadContent(flowResp);
                        }
                        _expectingAnotherPage = flowResp.data.messageNode;
                    });
                }

            });
        },

        //========================================
        // JUMP - in a flow
        //========================================
        doJump : function (path, options, inputVars) {
            if (!path) {
                _publishError("doJump: No path specified");
                return;
            }
            // If we are currently on a page, clean it up
            _endCurrentPage(options, () => {
                if (_.isString(path)) {
                    path = path.split(Constants.kNavigationSeperator);
                    _.each(path, (itm, idx) => {
                        if (0 === idx) {
                            path[idx] = new FlowNavigationObject(itm, inputVars, options);
                        }
                        else {
                            path[idx] = new FlowNavigationObject(itm);
                        }
                    });
                }

                _isBusy = true;
                let _expectingAnotherPage = false;
                const __loadContent = function (flowResp) {
                    _loadContent(flowResp.value, options)
                        .then((/* result */) => {
                            _autoNavHandler.handleAutoNav(flowResp.autoNav, options);
                        })
                        .catch((err) => {
                            _publishError(err)
                        })
                };
                _flowController.navigateTo(path, (flowResp) => {
                    if (_expectingAnotherPage) {
                        // end the current page to clean up
                        _endCurrentPage(options, () => {
                            __loadContent(flowResp);
                        });
                    }
                    else {
                        __loadContent(flowResp);
                    }
                    _expectingAnotherPage = flowResp.data.messageNode;

                });
            });

        },

        //========================================
        // Validation of screen elements in this view
        //========================================
        validate : function () {
            let valid = true;
            if (!_options.validationOptions.useValidator) {
                return valid;
            }

            const _validationEngine = ValidationEngine;

            // If we need to validate this form then validate and return true if no errors are present
            // Init the form with default settings (this could be placed in the section where each form is loaded)
            let options = _.extend({}, _options.validationOptions);
            if (window[_currentViewScope] && window[_currentViewScope].validationOptions) {
                options = _.extend(options, window[_currentViewScope].validationOptions);
            }

            // call the validationEngine's validateAll() method which will loop through all of the input fields with "validator" attribute and apply validation logic on them
            valid = _validationEngine.validateAll(_$viewPort, options);

            // CHeck to see if the view scope has an onValidate function that may prevent navigation
            // call it if field validation passed and it exists
            if (valid && window[_currentViewScope] && _.isFunction(window[_currentViewScope].onValidate)) {
                try {
                    Logger.info("Calling onValidate for page: " + _currentPage);
                    valid = window[_currentViewScope].onValidate();
                }
                catch (ex) {
                    _publishError("Page: '" + _currentPage + "' threw an exception onValidate", ex);
                }
            }

            return valid;

        },

        //-------------------------------------------
        // Function: getCurrentFlowVariable
        // get a flow scoped variable out of the current flow
        //  - may return null or undefined
        //
        // Parameters:
        //   name - the name of the variable to get
        //-------------------------------------------
        getCurrentFlowVariable : function (name) {
            if (_flowController) {
                return _flowController.getCurrentFlowVariable(name);
            }
        },

        //-------------------------------------------
        // Function: getCurrentFlowScopeName
        // get the name of the current flow scope
        //  - may return null or undefined
        //
        //-------------------------------------------
        getCurrentFlowScopeName : function () {
            if (_flowController) {
                return _flowController.getCurrentFlowScopeName();
            }
            else if (_flowscopeNameForNonFlows) {
                return _flowscopeNameForNonFlows;
            }

        },

        //-------------------------------------------
        // Function: getCurrentViewScopeName
        // get the name of the current view scope
        //  - may return null or undefined
        //
        //-------------------------------------------
        getCurrentViewScopeName : function () {
            return _currentViewScope;
        },


        //-------------------------------------------
        // Function: forceEnd
        // Force the controller into a premature ending state
        //
        //-------------------------------------------
        forceEnd : function (callback) {
            _onControllerEnd(null, callback);
        }
    };

    //----------------------------------------------------------------------------------------
    // Private
    //----------------------------------------------------------------------------------------

    const _name = viewId,
        _viewResolver = Registry.getInterface(Constants.interfaces.kViewResolver),
        _pageTransitoner = Registry.getInterface(Constants.interfaces.kScreenTransitioner);

    let _flowController = null,
        _options = options,
        _history = null,
        _flowscopeNameForNonFlows = null,// used for modal views as part of a flow.  The view needs access to the current flowscope
        _$viewPort, // = $("[data-viewport='" + viewId + "']"),
        _currentViewScope = null,
        _currentPage = null,
    // _isModal                  = false,
        _autoBackNavEvent = null,
        _isBusy = false,
        _pageProfiler = null,
        _navigationProfiler = null,
        _customerTimeProfiler = null;


    if (!_viewResolver) {
        _publishError("Missing view resolver");
    }

    //-------------------------------------------
    // Navigation functionality
    //  Listener for navigation events
    //-------------------------------------------
    function _handleNavEvent (options) {
        options = options || {};
        options.currentPage = _currentPage;
        const _vo = _options.validationOptions;

        // Get the right view
        if (_viewport.isBusy()) {
            Logger.info("Not navigating while Flow Controller is busy");
            return;
        }

        //----------------------------------------
        // Do any validation on the current viewport
        //----------------------------------------
        let doValidate = true;
        if (options.validate === false) {
            doValidate = false;
        }
        if (options.nav &&
            (options.nav === 'back' || options.nav === _autoBackNavEvent) && options.validate === false) {
            doValidate = _vo.validateOnBack;
        }
        else if (options.jump && options.validate === false) {
            doValidate = _vo.validateOnJump;
        }
        else if (options.load && options.validate === false) {
            doValidate = _vo.validateOnJump;
        }
        else if (options.flow && options.validate === false) {
            doValidate = _vo.validateOnJump;
        }
        if (doValidate && !_viewport.validate()) {
            return;
        }

        // Now navigate to the next page
        if (options.nav) {
            // got an auto-back event, use the history to go back
            if (_history && options.nav === _autoBackNavEvent) {
                const navEvt = _history.processBack(options);
                _viewport.doJump(navEvt.jump, navEvt);
            }

            else {
                _viewport.doNext(options.nav, options);
            }
        }
        else if (options.jump) {
            _viewport.doJump(options.jump, options);
        }
        else if (options.load) {
            _viewport.loadPage(options.load, options);
        }
        else if (options.flow) {
            _viewport.startFlow(options.flow, options);
        }
    }


    //------------------------------------------
    // Function: _loadContent
    //  1) load the content from the server
    //  2) put the content in a document fragment so we can do manipulation really quickly
    //  3) then bind any data to the input
    //  4) then attach the document fragment to the viewport
    // optional callback when content is loaded
    //-------------------------------------------
    async function _loadContent (pageAlias, options = {}) {
        // take care of some business
        _navigationProfiler.end("navTime", {toPage : pageAlias});
        _navigationProfiler.record();
        _pageProfiler = new Profiler("Page Profiling", {page : pageAlias});
        _pageProfiler.start();


        // if the viewport hasn't been defined yet (due to it being loaded as part of a document fragment
        // create it here.
        _$viewPort = _$viewPort || $("[data-viewport='" + viewId + "']");


        // we should be in the midst of navigating if we get here
        if (!_isBusy) {
            Logger.info("_loadContent returning without loading page because the busy flag is not set");
            return;
        }

        // Set up before the page loads - get an ID for the VIEW_SCOPE
        _onBeforePageLoad(pageAlias, options);

        _pageProfiler.mark("load");

        // Load the page off the server into memory.
        let htmlToLoad = await _viewResolver.resolve(pageAlias);


        // Replace VIEW_SCOPE and FLOW_SCOPE with their IDs to enable multiple ones going on.
        // This needs to be done prior to actually loading the HTML into the DOM.
        htmlToLoad = htmlToLoad.replace(/FLOW_SCOPE/g, _viewport.getCurrentFlowScopeName());
        htmlToLoad = htmlToLoad.replace(/VIEW_SCOPE/g, _currentViewScope);

        // Load the HTML into a temporary container.
        // which essentially creates a document fragment
        // BIND PAGE's <%= pkg.appName %> bindings to the document fragment,
        // its tons faster to work on a document fragment rather than the actual document
        const frag = $("<div></div>");
        HTMLLoader.injectHTML({container : frag, html : htmlToLoad, ref : pageAlias});

        _pageProfiler.measure("load", "loadTime");

        _currentPage = pageAlias;

        // Do any page setup that is necessary
        _beginPage(pageAlias, options);

        //  BIND PAGE's <%= pkg.appName %> ATTRIBUTES to the document fragment,
        //  its tons faster to work on a document fragment rather than the actual document
        _pageProfiler.mark("bind");
        await bindContainer(frag, _.extend(_options, {
            viewscope : _currentViewScope,
            flowscope : _viewport.getCurrentFlowScopeName()
        })).catch((ex) => {
            _publishError("Error binding page", ex);
        });
        __pageFragmentReady(frag);
        // End the current page loading profiler
        _pageProfiler.end("total Xinch page loading time");
        _pageProfiler.record();


        // Start a page timer once the page is viewable and usable
        _customerTimeProfiler = new Profiler("Customer Page Time", {page : pageAlias});
        _customerTimeProfiler.start();


        //---------------------------------------------------
        // Function: once the page is loaded in the DOM
        //---------------------------------------------------
        function __pageFragmentReady (frag) {

            // now attach the fragment to the document and off we go
            _$viewPort.empty().append(frag.contents());

            // show the page
            _doTransition(true, options);


            // Do any processing when the page is completely ready by Xinch.
            _pageReady(pageAlias, options);


            // if there is an anchor, scroll to it
            const scrollTo = options.scrollTo;
            if (scrollTo) {
                const $el = $("#" + scrollTo);
                if ($el[0]) {
                    $el.scrollTo(0, 200);
                }
                else {
                    _publishError("Page Request: '" + pageAlias + "' could not find the Hash: '" + scrollTo);
                }
            }
        }
    }

    // ------------------------------------------------
    // Invoke the screen transitioner
    function _doTransition (show, opts, cb) {
        if (!_pageTransitoner) {
            return;
        }
        if (show) {
            _pageTransitoner.show({$target : _$viewPort, options : opts, callback : cb});
        }
        else {
            _pageTransitoner.hide({$target : _$viewPort, options : opts, callback : cb});
        }
    }

    // Do any necessary page initialization before the page has loaded into the window scope
    //------------------------------------------------------
    function _onBeforePageLoad (pgAlias) {

        // publish an event
        PubSub.publish(Constants.events.kBeforePageLoad, {"pageAlias" : pgAlias});

        // create a VIEW_SCOPE namespace
        _currentViewScope = Constants.scopes.kViewScope + "_" + uuid(true);

        // Create a default VIEW_SCOPE
        window[_currentViewScope] = {};

        // Add the VIEW_SCOPE model
        getDataApi().addModel(_currentViewScope);

    }

    // Do any necessary page initialization
    // After the page has loaded into the window scope
    // But before the bindings
    //------------------------------------------------------
    function _beginPage (pageAlias /*, options*/) {

        // if the newly loaded page has an onBeforeReady() function, call it in the view name space
        if (window[_currentViewScope]) {
            // set the VIEW_SCOPE object as the value of the model
            // This way we don't have confusing VIEW_SCOPE objects and VIEW_SCOPE models.  They are both the same thing
            const vsm = getDataApi().getModel(_currentViewScope);
            if (vsm) {
                vsm._model = window[_currentViewScope];
            }

            if (_.isFunction(window[_currentViewScope].onBeforeReady)) {
                try {
                    Logger.info("Calling onBeforeReady for page: " + pageAlias);
                    window[_currentViewScope].onBeforeReady();
                }
                catch (ex) {
                    _publishError("Page: '" + pageAlias + "' threw an exception onBeforeReady", ex);

                }
            }
        }


        // Let anyone that cares that the page is loaded
        PubSub.publish(Constants.events.kPageLoaded, {"pageAlias" : pageAlias, "viewscope" : _currentViewScope});

    }

    // Do any necessary page processing
    // After all <%= pkg.appName %> related manipulation of the page
    //------------------------------------------------------
    function _pageReady (pageAlias, options) {

        const setFocus = (_.isBoolean(options.setFocusOnFirstInput)) ? options.setFocusOnFirstInput : _options.setFocusOnFirstInput;
        // set focus on the first field - only if it is in view
        // otherwise its a crappy experience scrolling past stuff that may be important
        if (setFocus) {
            const inputTypes = _.isArray(_options.setFocusInputTypes) ? _options.setFocusInputTypes.join(",") : '';
            if (inputTypes) {
                const fields = _$viewPort.find(inputTypes)
                    .filter(function () {
                        return (
                            $(this).is(":visible") &&
                            $(this).is(":enabled") &&
                            isElementCompletelyInView(this)
                        );
                    });

                if (fields[0]) {
                    fields[0].focus();
                }
            }

        }

        // if the newly loaded page has an onReady() function, call it in the view name space
        if (window[_currentViewScope]) {
            if (_.isFunction(window[_currentViewScope].onReady)) {
                try {
                    Logger.info("Calling onReady for page: " + pageAlias);
                    window[_currentViewScope].onReady();
                }
                catch (ex) {
                    _publishError("Page: '" + pageAlias + "' threw an exception onReady", ex);
                }
            }
        }

        // Let everyone know were ready
        PubSub.publish(Constants.events.kPageFinalized, {
            "pageAlias" : pageAlias,
            "viewscope" : _currentViewScope
        });

    }


    // Do any necessary cleanup before leaving the current View
    //----------------------------------------------------------
    function _endCurrentPage (options, callback) {

        // Reset our page profiler
        _navigationProfiler = new Profiler("Page Navigation", {fromPage : _currentPage});
        _navigationProfiler.start();

        if (!_currentPage) {
            callback();
            return;
        }

        // in case any input still has focus trigger a blur event, which will cause a model update before transitioning
        $('input:focus').trigger('blur');
        // scroll to the top when removing a page - if not told to do otherwise

        const scroll = (_.isBoolean(options.scrollToTop)) ? options.scrollToTop : _options.scrollToTop || _options.scrollOptions.toViewport;
        if (scroll) {
            if (!isElementTopInView(_$viewPort)) {
                // scroll to 100px above the current viewport or to the options
                let offset = _$viewPort.offset().top - 100;

                // if the option is set to scroll to the browser top, set our target to 0
                if (_options.scrollToTop) {
                    offset = 0;
                }

                // Note: Firefox honors scrolling to the top via the "html" tag while WebKit/standard scroll via "body".
                $('body').scrollTo(offset, 200);
                $('html').scrollTo(offset, 200);

            }
        }

        // Capture time spent on page
        _customerTimeProfiler.end('time on page');
        _customerTimeProfiler.record();

        // Let everyone know that we're ending the current page
        PubSub.publish(Constants.events.kBeforePageUnload, {
            "pageAlias" : _currentPage
        });

        // If there is an onEnd specified in the VIEW_SCOPE, call it
        if (window[_currentViewScope] && _.isFunction(window[_currentViewScope].onEnd)) {
            try {
                Logger.info("Calling onEnd for page: " + _currentPage);
                window[_currentViewScope].onEnd();
            }
            catch (ex) {
                _publishError("Page: '" + _currentPage + "' threw an exception onEnd", ex);
            }
        }

        //Clear any straggling errors from a previous page
        ValidationEngine.hideErrorTooltips(_$viewPort);

        // Now unsubscribe all VIEW_SCOPE subscriptions
        PubSub.unsubscribe(window[_currentViewScope]);

        // Now remove the VIEW_SCOPE model for the page
        getDataApi().removeModel(_currentViewScope, {silent : true});

        // Get the viewport that the view is bound to and unbind everything
        unbindContainer(_$viewPort); // Clean up the current view port, not the target view port

        // nullify the onEnd function in the global scope
        // cannot use delete, because delete can only be used on var and functions that are assigned rather
        // than defined through declaration
        window[_currentViewScope] = null;

        // Transition off the page
        if (options._samePage || options.noTransition) {
            callback();
        }
        else {
            _doTransition(false, options, callback);
        }

    }


    //------------------------------------------------
    // Controller callbacks
    //------------------------------------------------
    function _onControllerEnd (flowResponse, callback) {
        // Clean up
        _endCurrentPage({noTransition : true}, () => {
            _currentPage = null;
            _flowController = null;

            // publish an event that we're done
            PubSub.publish(Constants.events.kEndViewController + "." + _name, {viewport : _name, response : flowResponse});

            if (_.isFunction(callback)) {
                callback();
            }
        });


    }

    //------------------------------------------------
    function _onControllerModal (flowResponse) {
        // show the underlying page
        _doTransition(true, {});

        _flowController.pause();
        _isBusy = true;
        let resumeVal = null;

        // See if we got an indicator that we should navigate when the modal flow is done.
        // Get the list of values that we'll navigate on
        let navValues = flowResponse.modal.navWhenDone;
        if (_.isString(navValues)) {
            navValues = [navValues];
        }

        // set up options to pass to the modal API's
        const _fs = getDataApi().getModel(_flowController.getCurrentFlowScopeName()).getAll();
        const opts = {
            inputVars : _.extend(_.extend({}, _fs), flowResponse.inputVars),
            modal : flowResponse.modal
        };
        _.each(flowResponse.options, (key, val) => {
            opts[key] = val;
        });
        opts.complete = function (responseFromModal) {
            const _navVal = responseFromModal ? (responseFromModal.value || responseFromModal.nav) : null;

            // Now see if our response from the modal is in the list of values
            if (responseFromModal &&
                (_.contains(navValues, _navVal) ||
                 _.contains(navValues, Constants.kWildCard))) {
                resumeVal = _navVal;
            }

            _flowController.resume(resumeVal, (flowResp) => {
                _loadContent(flowResp.value, flowResponse.options)
                    .then(() => {
                        _isBusy = false;
                    });
            });
        };

        switch (flowResponse.state_type) {
            case Constants.flow.kFlowState :
                ApplicationController.startFlow(flowResponse.value, opts);
                break;

            case Constants.flow.kViewState :
                opts._flowNode = flowResponse;
                opts._flowscopeName = _viewport.getCurrentFlowScopeName();  // pass in the current flowscope name so the modal page has access to flowscope information
                ApplicationController.loadPage(flowResponse.value, opts);
                break;
        }
    }

    //------------------------------------------------
    function _onControllerError (flowResponse) {
        Logger.error(flowResponse.error, "View Controller");
    }

    function _setOptions () {
        // Start with the default options
        // Deep clone the default options so that the options does not get stomped on.
        let _opts = mergeObjects({}, Config.get('application.viewportOptions.default'));

        // if there is an override object, it takes precedence over default
        const vo = Config.get(`application.viewportOptions.${name}`);
        if (_.isObject(vo)) {
            _opts = mergeObjects(_opts, vo);
        }

        // now passed in options take ultimate precedence
        _options = mergeObjects(_options, _opts);

        _options.viewport = _name;

    }

    // -------------------------------
    // Function: _publishError
    // publish error with message
    //
    // Parameters:
    //   msg - the message of the error
    // -------------------------------
    function _publishError (msg, ex) {
        Logger.error(`Viewport error:${msg}`, `ViewController: ${_name}`, ex)
    }


    // Manage autoNavigation instructions
    // either auto advance the flow controller in a specified time
    // or listen to an event to advance the flow controller
    const _autoNavHandler = (function () {
        const __handler = {
            handleAutoNav : function (autoNavOptions) {
                // if we still have a registered event handler when the next page comes in,
                // remove it (ya, it can happen)
                if (__registeredAutoNav.navEvent) {
                    PubSub.unsubscribe(__registeredAutoNav.navEvent, __doAutoNav, __handler);
                }
                if (__timer) {
                    clearTimeout(__timer);
                }

                __registeredAutoNav = _.clone(autoNavOptions || {});

                if (__registeredAutoNav.navEvent) {
                    PubSub.subscribe(__registeredAutoNav.navEvent, __doAutoNav, __handler);
                }
                else if (__registeredAutoNav.time) {
                    __timer = setTimeout(() => {
                        __doAutoNav();
                    }, __registeredAutoNav.time);
                }
            }
        };
        let __registeredAutoNav = {}, __timer;

        function __doAutoNav () {
            _viewport.doNext(__registeredAutoNav.nav, options);
        }

        return __handler;
    })();


    return _viewport;
}
