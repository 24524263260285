import _ from 'src/core/libs/underscore-1.6.custom';
import Constants from 'src/core/constants';

Constants.addValues({
   eventDirectives : ["data-nav", "data-jump", "data-event", "data-loadflow", "data-loadpage", "data-set"],
   appDirectives : ["data-xinch-app", "data-viewport", "data-bind", "data-validate", "data-format", "data-listen", "data-repeat", "data-template"],

   events : {
      kNavigation : "navigation",
      kBeforePageLoad : "beforePageLoad", /* new page is about to be loaded */
      kPageLoaded : "pageLoaded", /* new page is loaded */
      kPageFinalized : "pagefinal", /* new page is completed loading and all processing is done */
      kBeforePageUnload : "endPage", /* page is about to be blown away */
      kBindingsApplied : "bindingsApplied", /* bindings have been applied to the new page*/
      kBindingsRemoved : "bindingsRemoved",
      kTemplateReady : "templateReady",
      kEndViewController : "endViewController",
      kConsoleEnabled : "debugConsoleEnabled",
      kConsoleDisabled : "debugConsoleDisabled"
      //kStartModalFlow : "startModalFlow",
      //kEndModalFlow : "endModalFlow",
   },

   interfaces : {
      kViewResolver : "viewResolver",
      kTemplateEngine : "templateEngine",
      kScreenTransitioner : "screenTransitioner",
      kModalWindow : "modalWindow"
   },

   registry : {
      kViewports : "viewports",
      kUIComponents : "uiComponents",
      kTemplates : "templates",
      kCustomBinderPre : "customBinderPre",
      kCustomBinderPost : "customBinderPost"
   }
});

// combine the app and event directives
// then add the -options to the list of all directives
const allDirectives = _.union(Constants.appDirectives, Constants.eventDirectives);
const allOptions = _.map(Constants.allDirectives, (str) => {
   return str + "-options";
});

Constants.addValues({
   allDirectives: _.union(allDirectives ,allOptions)
});

export default Constants;