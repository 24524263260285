/***
 *  HTML Loader: dynamically loads html files
 */

import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import Constants from 'src/core/constants';
import Logger from 'src/core/logging';
import http from 'src/core/http/http';

import {get$} from "src/core/utils/$utils";

const HTMLLoader = function () {

    const _instance = {

        /***
         * Load a specific html file from the server and execute the provided callback function.
         * @param url  View Reference.
         * @param hash load the snippet of the HTML associated with the hash #
         *
         * @return promise, resolved with raw HTML, rejected with an Exception
         */
        load : function (url, hash) {
            const options = {
                json: false
            };
            return http.get(url, options)
                .then((data) => {
                    if (hash) {
                        const $el = $(data);
                        let htmlSnippet;
                        if ($el.attr('id') === hash) {
                            htmlSnippet = $el;
                        }
                        else {
                            htmlSnippet = $el.find("#" + hash).filter("#" + hash);
                        }
                        if (htmlSnippet.length > 0) {
                            data = htmlSnippet[0].outerHTML;
                        }
                        else {
                            _logError("Error loading HTML: '" + url + "' could not find the Hash: '" + hash +
                                      "' in Page: " + url);
                        }
                    }
                    return data;
                })
                .catch((err) => {
                    _logError(`Error loading HTML page: ${url} with error ${err.message}`  );
                    throw(err);
                });
        },

        /***
         * Function to take in raw html, strip out and add all javascript to global.  Then load the clean html into the provided container.
         * @param {Object} paramObject -  An object that should contain the following:
         *  - html: Raw html to be processed.
         *  - container: DOM container to load the html in.
         *  - ref: View Reference (Used for error logging).
         */
        injectHTML : function (paramObject) {
            const rawHTML = paramObject.html;

            let $container;

            // Pull out the scripts
            // We'll do it here because some older browsers can't do it using X.$'s html() function.
            const htmlWithoutScripts = rawHTML.replace(Constants.scriptRegex, "");
            const scripts = rawHTML.match(Constants.scriptRegex);

            _evaluatePageScripts(scripts, paramObject.ref);

            // Inject the HTML without the scripts if there is a provided container.
            if (paramObject.container) {
                $container = get$(paramObject.container);
                $container.html(htmlWithoutScripts);
            }
        }

    };

    //------- PRIVATE -------------------------------------------

    /***
     * Evaluate any scripts on a page.
     * Do this because X.$ struggles getting it to work with old android devices, IOS4, and some safari browsers
     * @param scripts
     * @param htmlPage
     * @private
     */
    function _evaluatePageScripts (scripts, htmlPage) {

        _.each(scripts, (script) => {
            try {
                if (_isExternScript(script) || !_isJavaScript(script)) {
                    // Append external scripts and non-javascript so the browser can handle those
                    // First check for and remove a previous copy of the script that might have been appended already
                    const $script = $(script);
                    const scriptId = $script.attr('id');
                    if (scriptId) {
                        const existingScript = document.getElementById(scriptId);
                        if (existingScript && (existingScript.nodeName === $script.get(0).nodeName) && (existingScript.type === $script.get(0).type)) {
                            existingScript.parentNode.removeChild(existingScript);
                        }
                    }
                    $("body").append(script);
                }
                else {
                    // Extract and evaluate the script inside the <script> tag
                    const scriptText = script.substring(script.indexOf(">") + 1, script.lastIndexOf("<"));
                    $.globalEval(scriptText);
                }
            }
            catch (ex) {
                _logError("Failed to evaluate scripts in: " + htmlPage, ex);
            }
        }, this); // jshint ignore:line

    }

    /***
     * determine if the script is a JavaScript, as opposed to a template or something else
     * @param script
     * @private
     */
    function _isJavaScript (script) {
        const openTag = script.substring(0, script.indexOf(">") + 1);
        return (!/ type/i.test(openTag) || /javascript/i.test(openTag) || (/ecmascript/i.test(openTag)));
    }

    /***
     * determine if a script is external
     * @param script
     * @private
     */
    function _isExternScript (script) {
        const openTag = script.substring(0, script.indexOf(">") + 1);
        return (/ src\s*=/i.test(openTag));
    }

    /***
     * Publish an Exception with message.
     * @param msg - Error Message
     * @param ex - Exception
     * @private
     */
    function _logError (msg, ex) {
        Logger.error(msg, "HTMLLoader", ex);
    }


    return _instance;

};

export default new HTMLLoader();