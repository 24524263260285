import Constants from 'src/core/constants';

Constants.addValues({
   events : {
      kFlowStart : "startFlow",
      kFlowTransition : "flowTransition",
      kFlowEnd : "endFlow",
      kFlowEndController : "endFlowController"
   },
   interfaces : {
      kFlowResolver : "flowResolver"
   },

   registry : {
      kFlows : "flows"
   },

   flow: {
      kViewState : "VIEW",
      kActionState : "ACTION",
      kFlowState : "FLOW",
      kEndState : "END",
      kErrorState : "ERROR",

      kNavigationSeperator : "~",

      historyType : {
         kNever : "never",
         kSession : "session",
         kAlways : "always"
      }
   }
});

export default Constants;