import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import XPromise from 'src/core/utils/defer';
import Logger from 'src/core/logging';
import PubSub from 'src/core/pubsub';
import HTMLLoader from 'src/core/loaders/HTMLLoader';
import {get$} from "src/core/utils/$utils";
import {toJSON} from "src/core/utils/JSONSerializer";
import {getDataResolver} from 'src/core/utils/getDataResolver';
import Config from 'src/core/config/Config';

import DataConstants from 'src/data/constants'

import Constants from 'src/application/constants';
import UIComponentFactory from 'src/application/components/UIComponentFactory';
import Registry from 'src/application/registry/applicationRegistry';
import {parseRepeatAttr, renderRepeater} from "src/application/ui/repeater";
import {unbindContainer, bindContainer} from "src/application/bindings/containerBinder";
import ViewController from "src/application/view/ViewController";

// create an array of events to listen to
const _jqEventList = _.map(Constants.eventDirectives, (str) => {
    return '*[' + str + ']';
}).join(',');


/**
 * Ferret out any specified viewports in the content and register them
 *
 * @param containerId - the containing DOM element id, or X.$ object
 */
export function bindViewports (containerId) {
    const $container = get$(containerId);
    $("*[data-viewport]", $container).each((idx, el) => {
        const $el = $(el);
        const vid = $el.attr("data-viewport");
        let options = $el.attr("data-viewport-options");
        options = toJSON(options, true) || {};
        Registry.registerViewport(vid, new ViewController(vid, options), true);

        // If the default viewport has not been set yet, do it here
        const dv = Config.get('application.defaultViewport');
        if (!dv) {
            Config.addXinchOptions({
                application : {
                    defaultViewport : vid
                }
            });
        }
    });
}


/**
 *
 * @param containerId
 * @param options
 * @returns {*}
 */
export function bindRepeater (containerId, options = {}) {
    let promise = XPromise();

    const $container = get$(containerId);
    const $repeaters = $($container).find("[data-repeat]");
    if ($repeaters.length === 0) {
        return promise.resolve();
    }
    $repeaters.each(function () {
        const $el = $(this);
        let repeaterOpts = $el.attr("data-repeat-options");
        repeaterOpts = toJSON(repeaterOpts, true) || {};
        const _repeaterInfo = parseRepeatAttr($el);
        if (_repeaterInfo) {
            renderRepeater($el, _repeaterInfo, repeaterOpts.customArgs);

            //                    // push on any nested repeaters
            //                    _promises.push(X.view.binder.bindRepeater($el, options));

            // but push any nested templating on the promise stack
            promise = bindTemplate($el, options);

            // get the default events to listen to based on the data-bindings in the attribute
            let _databindingEvents = [];
            _.each(_repeaterInfo.bindings, (val, nameSpace) => {
                _databindingEvents.push(DataConstants.events.kDataChange + '.' + nameSpace);
            });


            // if we have a list of other events to listen for
            // add them to our list
            let _listenEvts = $el.attr("data-listen");
            if (_listenEvts) {
                _listenEvts = _listenEvts.match(/([\.\w]+)/g);
            }
            _databindingEvents = _.union(_listenEvts, _databindingEvents);

            // set up a listener to re-evaluate ALL attributes when one of these events happens
            if (_.size(_databindingEvents) > 0) {
                PubSub.subscribe(
                    _databindingEvents,
                    () => {
                        // var customArg = arguments[arguments.length - 1];
                        // // re-evaluate the collection that we're binding to... since it most likely changed
                        const _newInfo = parseRepeatAttr($el);

                        const focusedId = document.activeElement ? document.activeElement.id : null;    // keep track of the element that has focus
                        unbindContainer($el, true); // exclude self since we don't want to unbind our listeners

                        // only re-render if the collection length has changed or one of the listeners was fired;
                        // if (arguments.length <= 1 || _.size(_repeaterInfo.collection) != _.size(_newInfo.collection)) {
                        _repeaterInfo.collection = _newInfo.collection;
                        renderRepeater($el, _repeaterInfo, repeaterOpts);
                        //}

                        bindContainer($el, options);
                        // restore focus in case it was lost due to re-rendering the template
                        // FF and some other browsers loose the caret position when setting focus
                        if (focusedId && focusedId != document.activeElement.id) {
                            const _$el = $("#" + focusedId);
                            _$el.focus();
                            try {
                                if (_$el.val) {
                                    _$el.caret(_$el.val().length);
                                }
                            }
                            catch (e) {
                                // do nothing
                            }
                        }
                    },
                    $el[0]
                );
            }

        }
        else {
            promise.resolve();
        }
    });

    return promise;

}


/**********************************************************************************
 * bind the events
 *
 * @param containerId - the DOM element id or the $ element
 * @param options - contains
 *              - viewport - the viewport that is currently being bound
 */
export function bindEvents (containerId, options) {
    const $container = get$(containerId);
    //bind any <a>, <input type="button">, and <input type="submit"> to events based
    //on properties in the tag (i.e. data-nav, data-event, data-jump)
    // submit, buttons will be bound to a function that prevents immediate propagation of the event.
    $(_jqEventList, $container).each(function () {
        $(this).bindEvents($container, options);

    });
}

// -------------------------------
// Function: bindComponents
// bind the UI Widgets
//
// Parameters:
//    containerId - the dom element identifier
//                  or the $ element
// -------------------------------
export function bindComponents (containerId) {
    const $container = get$(containerId);

    // iterate through all of the elements that have are <component>
    // And replace the <component> tag with the resulting $ element
    $($container).find("uiwidget").each(function () {
        const $el = UIComponentFactory.create($(this));
        $(this).replaceWith($el);
    });
}

/***
 * Function to bind HTML templates to a provided DOM element.
 * @param containerId - DOM element ID or the $ element.
 * @param options - options passed to the binder
 * @param excludeContainer - indicates whether to check the parent/container for template binding.  by default only descendants are searched.
 */
export function bindTemplate (containerId, options = {}, excludeContainer) {
    const _viewResolver = Registry.getInterface(Constants.interfaces.kViewResolver);
    const promise = XPromise(),
        _promises = [];


    // Determine whether a $ element or just an ID.
    const $container = get$(containerId);

    // iterate through all of the elements that have a "data-template" attribute
    let $templateElements;

    if (!_viewResolver) {
        Logger.error("Missing View Resolver", "bindTemplate")
        return promise.reject("Missing View Resolver");
    }

    if (!excludeContainer && $container.attr("data-template")) {
        $templateElements = $container;
    }
    else {
        $templateElements = $container.find("[data-template]");
    }

    if ($templateElements.length === 0) {
        return promise.resolve();
    }
    $templateElements.each(function () {

        // Get the template reference and load the HTML into the container
        const $el = $(this),
            templateReference = getDataResolver().resolveDynamicData($el.attr("data-template")),
            lastHashIndex = templateReference.lastIndexOf('#');

        // template is a node that's already in the DOM
        if (lastHashIndex === 0) {
            const $template = $(templateReference);
            if ($template.is('script')) {
                processScriptTemplate($el, $template, options);
            }
            else {
                $el.empty().append($template.contents().clone());  // clone() in order to leave the original template intact
            }
            _promises.push(XPromise().resolve());
            PubSub.publish(Constants.events.kTemplateReady + "." + templateReference.substr(1));
        }

        // Load the page off the server into memory.
        else {
            if (templateReference) {
                const p = XPromise();
                _viewResolver.resolve(templateReference).then(
                    (rawHTML) => {
                        const _subPromises = [];
                        // Set up any VIEW or FLOW scope info
                        if (options.viewport) {
                            const _vp = Registry.getViewport(options.viewport);
                            if (_vp) {
                                rawHTML = rawHTML.replace(/FLOW_SCOPE/g, _vp.getCurrentFlowScopeName());
                                rawHTML = rawHTML.replace(/VIEW_SCOPE/g, _vp.getCurrentViewScopeName());
                            }
                        }
                        if (isScriptTemplate(rawHTML)) {
                            processScriptTemplate($el, $(rawHTML).filter('script'), options);  // need to filter in case there is a comment above the script
                        }
                        else {
                            // Process and Load the HTML into the element.
                            HTMLLoader.injectHTML({container : $el, html : rawHTML, ref : templateReference});
                            _subPromises.push(bindRepeater($el, options));
                            _subPromises.push(bindTemplate($el, options, true));
                        }
                        Promise.allSettled(_subPromises).then(() => {
                            p.resolve();
                        });
                        PubSub.publish(Constants.events.kTemplateReady + "." + templateReference);
                    },
                    (ex) => {
                        Logger.error("Error Details: Unable to load data-template. Template Reference: " + templateReference, "bindTemplate", ex)
                        p.reject();

                    }
                );
                _promises.push(p);
            }
            else {
                Logger.info("Template reference is NULL");
            }
        }

    });

    Promise.allSettled(_promises).then(() => {
        promise.resolve();
    });

    return promise;
}


/**
 * @private
 * Execute an custom binders that have been registered
 * @param containerId
 * @param preBinder
 */
export function executeCustomBinders (containerId, preBinder, options={}) {
    const $container = get$(containerId);
    const binders = Registry.getCustomBinders(preBinder);
    if (!binders) {
        return;
    }
    _.each(binders, (bindFunc) => {
        if (_.isFunction(bindFunc)) {
            bindFunc($container, options);
        }
    });
}


/**
 * @private
 * Process a template by extracting the data attribute and sending it to the template engine.
 * Also listen for events that trigger a re-render of the template.
 * @param $el - the element into which the template is rendered
 * @param $template - the template
 * @param options - script options
 */
function processScriptTemplate ($el, $template, options = {}) {
    const templateType = $template.attr('type');
    if (!templateType || /javascript|ecmascript/i.test(templateType)) {
        Logger.error("Wrong type of template for binding: " + $template.attr('id'), "processScriptTemplate");
        return;
    }
    const templateEngine = Registry.getInterface(Constants.interfaces.kTemplateEngine);

    const _modelRefs = {},
        dataAttr = $el.attr('data-template-options');
    let templateData;

    if (dataAttr) {
        templateData = getDataResolver().resolveDynamicData(dataAttr, _modelRefs); // resolve any dyamic text in options and save off models refs to listen to
        templateData = toJSON(templateData, true);
    }

    // Render the template
    templateEngine.process($el, $template, templateData);

    // Rerender based on data model references and on the data-template-listen attribute
    const listenAttr = $el.attr("data-listen");
    let eventTypes = _.map(_.keys(_modelRefs), (val) => {
        return 'dataChange.' + val;
    });
    eventTypes = _.union(eventTypes, listenAttr ? listenAttr.match(Constants.eventSeparatorMatch) : []);
    if (eventTypes) {
        if (!$el[0].templateListener) {
            $el[0].templateListener = function (/*ev*/) {
                // var self = $el[0];
                unbindContainer($el, true); // exclude self since we don't want to unbind our listeners
                bindContainer($el, _.extend(options, {silent : true, skipTemplates : true}));
            };
            //var eventTypes = listenAttr.match(X.constants.eventSeparatorMatch);
            _.each(eventTypes, (eventType) => {
                PubSub.subscribe(eventType, $el[0].templateListener, $el[0]);
            });
        }
    }

}

/**
 * @private
 * Determine whether a raw string contains a dynamic template, based on the script type
 * @param rawTxt
 * @returns {boolean}
 */
function isScriptTemplate (rawTxt) {
    const rawNoComment = rawTxt.replace(/<!--[\s\S]*?-->/g, "");  //will also strip out the comment pattern within js strings, which is ok in this case but not in general.
    const tag = rawNoComment.match(/<.*?>/);
    if (!tag) {
        return false;
    }
    const firstTag = tag[0];
    const isJavaScript = !/type.*=/i.test(firstTag) || /javascript|ecmascript/i.test(firstTag); // no-type or javascript-type = javascript
    return !isJavaScript;
}
