import _ from 'src/core/libs/underscore-1.6.custom';
import {extend_$} from "src/core/utils/$utils";
import {replaceCharWithinParenthesis} from "src/core/utils/function_utils";
import PubSub from "src/core/pubsub";
import Constants from 'src/validation/constants';
import EventExecutor from 'src/validation/engine/eventExecutor';

extend_$({

   validate: function (showMultipleErrors) {
      const $el = this;

      // accepts a single validator string with commas already replaced with _comma_
      // returns an object with properties {type, args, message}
      function _parseSingleValidator(validatorStr) {
         const validator = {};
         validatorStr = replaceCharWithinParenthesis(validatorStr, ":", "_colon_");
         const parts = validatorStr.split(':');

         // now put back the colons
         _.each(parts, (itm, idx) => {
            parts[idx] = itm.replace(/_colon_/g, ":");
         });

         // parse out the validator name and any arguments
         const validationParts = Constants.functionPartsRegex.exec(parts[0]);
         if (validationParts) {
            validator.type = validationParts[1];
            validator.args = validationParts[3] ? validationParts[3].replace(/_comma_/g, ",") : null;
         }
         validator.options = {};

         // get the options, which are after the colon (currently only :message(string) is used)
         for (let optionIndex = 1; optionIndex < parts.length; optionIndex += 1) {
            const optionParts = Constants.functionPartsRegex.exec(parts[optionIndex]);
            const optionName = optionParts[1];
            validator.options[optionName] = optionParts[3] ? optionParts[3].replace(/_comma_/g, ",").replace(/_colon_/g, ":").removeQuotes() : null;
         }

         // Set the information up as a property
         const dv = $el.prop("validate") || {};
         dv[validator.type] = validator;
         $el.prop("validate", dv);

         return validator;
      }

      function _parseValidators(htmlAttr) {
         // we need to remove spaces around commas that are used to separate some parameters
         // but leave other spaces, which could occur within a custom message (as in regex validation)
         htmlAttr = htmlAttr.replace(/(^\s*)|(\s*$)/g, "");  // remove leading and trailing spaces
         htmlAttr = htmlAttr.replace(/(\s*,\s*)/g, ","); // remove spaces around commas
         // Encode stuff between parens (so we can split on commas)
         htmlAttr = replaceCharWithinParenthesis(htmlAttr, ",", "_comma_");

         const validatorStrings = htmlAttr.split(',');
         const validators = [];
         _.each(validatorStrings, (validatorStr) => {
            validators.push(_parseSingleValidator(validatorStr));
         });
         return validators;
      }

      // if this element doesn't have a validator attribute, or if the value of the validator attribute is empty, null or false,
      // or if the element is not an input, return right away
      if (!$el.attr("data-validate")) {
         return "";
      }

      const validatorStr = $el.attr("data-validate"),
            errors       = [],
            validators   = _parseValidators(validatorStr);
      let validationResult = null;

      _.each(validators, (validator) => {
         validationResult = EventExecutor.validate($el, validator.type, validator.args);
         if (validationResult) {
            if (!errors.length || showMultipleErrors) {
               PubSub.publish(Constants.events.kValidationFailed, $el.get(0));
               errors.push(validationResult);
            }
         }
      });

      return errors;

   }
});
