import Registry from "src/core/registry/Registry";
import Logger from 'src/core/logging';
import {mergeObjects} from "src/core/utils/objectUtils";
import Constants from 'src/application/constants';

// import ViewController from 'src/application/view/ViewController';
import UIComponent from "src/application/ui/UIComponent";

export default mergeObjects(Registry, {

   /**
    * Register a viewport
    *
    * @param name
    * @param viewport
    * @param replace
    * @returns {*} - the registered viewport
    */
   registerViewport: function (name, viewport, replace = true) {
      //Component must implement a Viewport
      // if (!(viewport instanceof ViewController)) {
      //    return Logger.error("registerViewport: view must be a ViewController", "Application Registry")
      // }
      let vp = this.getViewport(name);
      if (vp && replace) {
         Logger.info("Viewport: " + name + " already registered -- deleting old one");
         vp.deinitilaize();
      }
      if (vp && !replace) {
         return vp;
      }
      // this call will delete the viewport if it exitsts then add a new one
      vp = this._register(Constants.registry.kViewports, name, viewport, replace);
      vp.init();

      return vp;

   },


   getViewport: function (name) {
      return this._get(Constants.registry.kViewports, name);
   },

   removeViewport: function (name) {
      const vp = this._get(Constants.registry.kViewports, name);
      if (!vp) {
         return;
      }
      vp.deinitilaize();
      this._remove(Constants.registry.kViewports, name);

   },

   /**
    * @param name - name of the binder to register
    * @param binderFunc - [function] to execute as part of bindings, the container will be passed as the argument of the function
    * @param preBinder - [boolean] true will execute the function BEFORE any of the bindings, false will execute the function AFTER the bindings
    */
   registerCustomBinder: function (name, binderFunc, preBinder) {
      this._register(preBinder ? Constants.registry.kCustomBinderPre : Constants.registry.kCustomBinderPost, name, binderFunc);
   },

   getCustomBinders: function (preBinder) {
      return this._getAll(preBinder ? Constants.registry.kCustomBinderPre : Constants.registry.kCustomBinderPost);
   },

   registerUIComponentClass: function (name, ComponentClass, replace = true) {

      // Component must implement a UI Component Interface
      // So lets do a quick check here
      let component = new ComponentClass();
      if (!(component instanceof UIComponent)) {
         component = null;
         return Logger.error("registerUIComponentClass: view must be a UIComponent", "View Registry")
      }

      this._register(Constants.registry.kUIComponents, name, ComponentClass, replace);
      Logger.info("added UI component, name = " + name);
   },

   // Look up the registered UI component in our registry and then
   // new one up and return it.
   // May return null if the new fails, or the component doesn't exist
   getUIComponent: function (name) {
      return this._get(Constants.registry.kUIComponents, name);
   }

});