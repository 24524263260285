/**
 * returns the input value unchanged if input is empty
 * replaces every % NOT followed by a two-digit (hex) number with %25
 * returns the decoded string
 * @param s
 * @returns {string|*}
 */
export default function decodeURIComponentSafe(s) {
   if (!s) {
      return s;
   }
   return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
}