export default {

   events: {
      kInitialize: "initialize",
      kOptions: "options", // setOptions call is made /* payload is the new options */
      kLogEvent: "log",
      kPerformance: "performance" /* profiling is finished  - publishers should publish the X.utils.profiler object */
   },

   interfaces: {
      kABTestResolver: "abTestResolver",
      kDataResolver: "dataResolver"

   },
   components: {
      kDefaultDataApi : "defaultDataApi",
      kDefaultValidationApi : "defaultValidationApi"
   },

   jqEvents: {},

   scopes: {
      kApplicationScope: "APPLICATION_SCOPE",
      kFlowScope: "FLOW_SCOPE",
      kViewScope: "VIEW_SCOPE"
   },

   log: {
      INFO: "info",
      WARN: "warn",
      ERROR: "error",
      DEBUG: "debug",
      DEPRECATED: "deprecated",
      PERFORMANCE: "performance"
   },

   registry: {
      kComponents: "components",
      kInterfaces: "interfaces"
   },

   kWildCard: "*",

   functionPartsRegex: /^(.*?)(\((.*?)\))?$/,
   functionSearchPattern: /([$A-Za-z_][0-9A-Za-z_$.]*?)\((.*?)\)/g,

   nameSpaceRegex: /^([^\s\r\n\'\"]+)\.([^\s\r\n\'\"]+)$/,

   scriptRegex: /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,  // regex to match scripts in an html page (source: jquery)

   expressionRegexPattern: /\@\{(.*?)\}/g,

   eventSeparatorMatch: /[^\s,]+/g
};
