import $ from 'src/core/libs/zepto-1.1.3.custom';
import _ from 'src/core/libs/underscore-1.6.custom';
import PubSub from 'src/core/pubsub';
import Config from 'src/core/config/Config';
import {getDataResolver} from 'src/core/utils/getDataResolver';
import {get$} from "src/core/utils/$utils";

import Constants from 'src/data/constants';
import Registry from 'src/data/registry/dataRegistry';
import {splitDataRef} from "src/data/utils/utils";
import {attrChangeHandler, resolveAttributes} from "./attributeBinder";

/**********************************************************************************
 * Search all the text nodes and do our replacement algorithms on them
 *
 * @param containerId - the DOM element id or the X.$ element who's children to resolve
 */
export function bindText(containerId) {
   const $container = get$(containerId);

   // We won't just do an innerHTML replace because X.$ listeners and bound elements lose their references
   // when doing html()
   $container.find("*").bindText();
}

// Set up two way binding (MVVC binding)
//-----------------------------------------
// -------------------------------
// Function: bindData
// bind the data
//
// Parameters:
//    containerId - the dom element identifier
//                  or the X.$ element
// -------------------------------
export function bindData(containerId, options) {
   const $container = get$(containerId);

   // Iterate over the sub-elements that have the data-bind attribute associated with them
   // and attach the element to the pubsub mechanism for two way data-binding
   $("[data-bind]", $container).each(function () {

      const $el = $(this);
      let def = null;

      // Get the data-bind attribute and see if it has any dynamic information in it that need dereferenceing
      const db = $el.attr("data-bind");

      // resolve model references in the data-bind  attribute and set the attribute to the resolved
      const _modelRefs = {};
      const resolvedDataBind = getDataResolver().resolveDynamicData(db, _modelRefs);

      // if there are model references in here, set up listeners to re-bind when one changes
      if (_.size(_modelRefs) > 0) {
         // save off the original val
         $el.prop("unresolvedDataBind", db);
      }

      $el.attr("data-bind", resolvedDataBind);

      const m = splitDataRef(resolvedDataBind);
      const _model = Registry.getModel(m.modelName, Config.get('data.autoCreateModels'));
      if (_model) {
         def = _model.getSchemaforKey(m.key);
      }


      // See if this property references a model schema
      // if it does grab the info from the definition and
      // set them on the control
      // Note - existing validate/format DOM attributes override the ones supplied in the Definition
      const isInput = $el.is("input") || $el.is("select") || $el.is("textarea");
      if (def) {
         if (isInput) {

            // Set formatters and validators
            const v = $el.attr("data-validate");
            const f = $el.attr("data-format");
            const p = $el.attr("placeholder");
            if (def.validate && !v) {
               $el.attr("data-validate", def.validate.toString());
            }
            if (def.format && !f) {
               $el.attr("data-format", def.format);
            }
            if (def.placeholderText && !p) {
               $el.attr("placeholder", def.placeholderText);
            }

            // set up accessibility
            if (def.accessibility) {
               $el.attr("aria-label", def.accessibility);
            }
         }

         // Not needed since we set default value on Model construction now
         //                    defaultValue = def.defaultValue;
         //
         //                    // if there is a default value specified, add it to the bind-options.
         //                    // Explicitly check for undefined and null, don't just test for falsiness, because we also
         //                    // want to pick up the defaultValue if it's a boolean(for example boolean value of false)
         //                    if (defaultValue != undefined && defaultValue != null) {
         //                        var BO = X.utils.attributeUtil.toJSON($el.attr("data-bind-options"));
         //
         //                        // if the defaultValue is a boolean, preserve the value as a boolean and don't cast it to a string
         //                        BO.defaultValue=defaultValue
         //                        $el.attr("data-bind-options", X.utils.jsonSerializer.toString(BO));
         //                    }
      }

      $el.bindToModel(options);

   });
}

/**********************************************************************************
 * bind all attributes in elements of a given container to models (if applicable)
 *
 * @param containerId - the container of the elements to bind, can be a $ object or an id.
 */
export function bindAttributes(containerId /*, options*/) {
   const $container = get$(containerId);

   $("*", $container).each((idx, el) => {
      if ($(el).prop("attributesToResolve")) {
         // don't replace the property with a new (potentially smaller) array of attributes to resolve.
         // the presence of the attributesToResolve property indicates that this element is already bound.
         return;
      }

      const elAttrToResolve = [];

      // add the attribute to the binding list if it contains an expression or model reference
      // AND it is NOT one of our special Xinch directives
      _.each(el.attributes, (attr) => {

         if (!_.contains(Constants.allDirectives, attr.nodeName) &&
            (attr.nodeValue.indexOf("${") >= 0 || attr.nodeValue.indexOf("@{") >= 0)) {
            elAttrToResolve.push({el: el, attrName: attr.nodeName, attrValue: attr.nodeValue});
         }
      });

      // Resolve attributes in our list and set up pubsub listeners to re-evaluate
      // the attriubtes when dataChange events occur
      if (elAttrToResolve.length > 0) {
         $(el).prop("attributesToResolve", elAttrToResolve);
         resolveAttributes(el);
      }

      // if we have a list of other events to listen for
      // set up a listener to re-evaluate ALL attributes when one of these events happens
      let listenEvts = $(el).attr("data-listen");
      if (listenEvts) {
         listenEvts = listenEvts.match(/([\.\w]+)/g);

         PubSub.subscribe(
            listenEvts,
            () => {
               attrChangeHandler(el);
            },
            el
         );
      }

   });
}

