//-------------------------------------------------------------------------------------
// class: Application Controller Class
//
//  About : This class manages the flows and views and the navigation events posted by the application to that drive those flows.
//
//
//  Note : Modal functionality - the way that I implemented this is that waaaay down in a flow, if a node is marked as "modal", it will 'pause' itself.
//              Then it will call the X.getSome (for a modal flow) or X.loadPage (for a single page).  This class will then create a brand new flow controller to handle the modal flow.
//              When the modal flow is finished it will reinistigate the original flowcontroller and call the supplied callback passing the response from the modal flow.
//              The flow can then decide to resume of stay on the current page.
//
//
//-------------------------------------------------------------------------------------
import _ from "src/core/libs/underscore-1.6.custom";
import uuid from 'src/core/utils/UUID';
import Config from 'src/core/config/Config';
import Logger from 'src/core/logging';
import PubSub from 'src/core/pubsub';
import Constants from 'src/application/constants';
import Registry from 'src/application/registry/applicationRegistry';
import {getDataApi} from "src/core/utils/getDataResolver";

function ApplicationController() {

   const _application = {

      init: function () {
         if (!_initialized) {
            // Create our session id
            _sessionId = uuid();
            _modalWindow = Registry.getInterface(Constants.interfaces.kModalWindow);

            // Create an application scope model
            getDataApi().addModel(Constants.scopes.kApplicationScope);

            _initialized = true;
         }
      },


      //-------------------------------------------
      // Function: start
      // Start 'r up
      //   and load the first page of a flow
      //   /*options, inputVars, viewport, success, error*/
      //-------------------------------------------
      startFlow: function (flowName, args) {
         args = args || {};
         args.options = args.options || {};
         let view;

         // Set up for modal if we get an indicator that this page is modal
         //=========================================================
         if (args.modal) {
            // Save off the current flow context so we can restore it when the modal closes
            const _oldContext = _currentContext;
            // set up the modal to close on 'endFlow' event
            // and then pass control back to the calling code to deal with the response
            // in the case of an embedded modal flow in a flow, the caller is the viewport.
            args.modal.closeEvent = Constants.events.kEndViewController + "." + _modalWindow.getViewPortId();
            args.modal.closeCallback = function (flowResp) {
               _currentContext = _oldContext;
               // kill the flow controller in the modal view
               // if closed by close button (i.e. no flowResp)
               if (!flowResp) {
                  view.forceEnd();
               }

               flowResp = flowResp || {}; // may be null due to a close button click
               if (flowResp.error) {
                  if (_.isFunction(args.error)) {
                     args.error(flowResp.response);
                  }
               }
               // get us back to our previous state and let the flow get to right node
               if (_.isFunction(args.complete)) {
                  args.complete(flowResp.response);
               }
            };

            // Show the modal window
            _startModal(args.modal);

            // set the viewport to the modal window and start the flow in it
            args.viewport = _modalWindow.getViewPortId();
            view = _getView(args.viewport);
            // Don't pass ALL arguments to the modal flow because well get double complete callbacks called
            if (view) {
               view.startFlow(flowName,
                  {
                     modal: true,
                     options: args.options || {},
                     inputVars: args.inputVars
                  });
            }
            else {
               Logger.error(`Could not find view Controller for ${_modalWindow.getViewPortId()}`, "View Controller")
            }

         }
         //=========================================================
         // End Modal functionality
         else {
            // Get the right view
            view = _getView(args.viewport);
            if (view) {
               view.startFlow(flowName,
                  {
                     modal: false,
                     options: args.options,
                     inputVars: args.inputVars,
                     complete: args.complete,
                     error: args.error
                  });
            }
            else {
               Logger.error(`Could not find view Controller for ${args.viewport}`, "View Controller")
            }

         }
      },


      //--------------------------------------
      // Load a page - this will mess up the flow controller
      //  If one is running in the viewport
      //--------------------------------------
      loadPage: function (pageRef, args) {
         let view;
         args = args || {};

         // Set up for modal if we get an indicator that this page is modal
         //=========================================================
         if (args.modal) {

            // if we got here from a modal view in a flow
            // set up the modal to close on 'navigation' events
            // and then pass control back to the calling viewport to deal with the response
            if (args._flowNode) {
               args.modal.closeEvent = Constants.events.kNavigation;
               args.modal.closeCallback = function (navObj) {
                  if (_.isFunction(args.complete)) {
                     args.complete(navObj);
                  }
               };
            }

            // Show the modal window
            _startModal(args.modal);

            // set the viewport to the modal window and load the page in it
            args.viewport = _modalWindow.getViewPortId();
            view = _getView(args.viewport);
            view.loadPage(pageRef, args);
            if (args.modal.autoClose && args.modal.autoClose.time) {
               setTimeout(() => {
                  _modalWindow.hide({nav: args.modal.autoClose.nav});
               }, args.modal.autoClose.time);
            }
         }
         //=========================================================
         // End Modal functionality
         else {
            // Get the right view
            view = _getView(args.viewport);
            view.loadPage(pageRef, args);
         }

      },

      inModal: function () {
         return _inModal;
      },

      //-------------------------------------------
      // Function: forceEnd
      // Allow an outside influence the ability to stop the controller and end it
      //-------------------------------------------
      forceEnd: function (viewport) {
         if (!viewport) {
            Logger.info("forceEnd: No viewport specified in options - using default");
            viewport = Config.get('application.viewPortId');
         }
         const view = _getView(viewport);
         view.forceEnd();
      },

      //-------------------------------------------
      // Function: getSessionId
      // get a flow scoped variable out of the current flow
      //  - may return null or undefined
      //
      // Parameters:
      //   name - the name of the variable to get
      //-------------------------------------------
      getSessionId: function () {
         return _sessionId;
      },

      //-------------------------------------------
      // Function: getCurrentFlowVariable
      // get a flow scoped variable out of the current flow
      //  - may return null or undefined
      //
      // Parameters:
      //   name - the name of the variable to get
      //-------------------------------------------
      getCurrentFlowVariable: function (viewport, name) {
         const vp = viewport || _currentContext;
         const view = _getView(vp);
         return view.getCurrentFlowVariable(name);
      },

      //-------------------------------------------
      // Function: getCurrentFlowScopeName
      // get the name of the current flow scope
      //  - may return null or undefined
      //
      //-------------------------------------------
      getCurrentFlowScopeName: function (viewport) {
         const vp = viewport || _currentContext;
         let view = _getView(vp);
         if (!view) {
            return;
         }
         let fs = view.getCurrentFlowScopeName();
         if (!fs) {
            view = _getView();
            fs = view.getCurrentFlowScopeName();
         }
         return fs;

      },

      //-------------------------------------------
      // Function: getCurrentViewScopeName
      // get the name of the current view scope
      //  - may return null or undefined
      //
      //-------------------------------------------
      getCurrentViewScopeName: function (viewport) {
         const vp = viewport || _currentContext;
         const view = _getView(vp);
         return view.getCurrentViewScopeName();
      }

   };

   /*
    Initialize the application controller in response to the init event
    */
   PubSub.subscribe(Constants.events.kInitialize, () => {
      // The console does not like bindings applied to it.
      _application.init();
   });


   //=====================================================
   // Private
   //=====================================================
   let _initialized    = false,
       _sessionId      = null,
       _modalWindow    = null,
       _inModal        = false,
       _currentContext = null;

   function _getView(viewId) {
      // If the viewport is not registered, use the default one
      if (!viewId) {
         viewId = Config.get('application.defaultViewport');
      }

      const vp = Registry.getViewport(viewId);
      if (!vp) {
         Logger.error("No default viewport specified in options", "ApplicationController");
      }
      _currentContext = viewId;
      return vp;

   }

   //--------------------------------------
   // Group: Modal Functionality
   //--------------------------------------
   // Function: startModal
   function _startModal(options) {
      const opts = options || {};
      const cb = options.closeCallback;
      opts.closeCallback = function (data /*from close event*/) {
         _inModal = false;
         if (_.isFunction(cb)) {
            cb(data /*from close event*/);
         }
      };
      _modalWindow.show(opts);
      _inModal = true;
   }


   return _application;
}

export default new ApplicationController();
