import BaseStrategy from './BaseStrategy';
import _ from 'src/core/libs/underscore-1.6.custom';

export default class StrategyInstance extends BaseStrategy {
   constructor(name, strategyObj) {
      super(name);

      // if overriding functions we'll save off a copy of the so the can be referenced in the subclass
      // We have to do this wonky way of handling Super because we're dynamically generating this class from an object
      // and objects cannot refer to 'super' in them.  Babel hates it.
      this._super = {};

      // Take each of the keys of the strategy object and make it part of 'this'
      _.each(strategyObj, (value, key) => {
         // if overriding functions we'll save off a copy of the so the can be referenced in the subclass
         if (this[key]) {
            this._super[key] = this[key].bind(this);
         }
         this[key] = value;
      })
   }
}