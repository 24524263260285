import Profiler from './Profiler';
import Logger from "src/core/logging";

class XinchProfiler extends Profiler {
    /**
     * Log the performance object
     * @returns {Profile}
     */
    record() {
        Logger.performance(this.data());
        return this.data();
    }
}


export default XinchProfiler