/**
 *
 * About:
 * This class is used to keep track of the current set of AB tests and configurations.
 * Based on the current set of tests, the getABTestPage and getABTestFlow methods will return views and flows that should be used instead of the defaults.
 * The directory structure and file names for the AB Test versions of the flows and views are the same as the default versions, but they are preceded by this directory structure:
 * <ABTestRoot>/test_name/recipe_name/
 *
 * If mainRoot is specified, and the default flow or view path starts with that path, then it will be omitted from the A/B test version of the path.
 * For example, if
 * mainRoot = "main",
 * testRoot = "abtest",
 * and the default path to a view is "main/html/views/someView.html",
 * then the path to that file while in test1, recipe b, would be: "abtest/test1/b/html/views/someView.html"  (note that 'main' was omitted.)
 *
 * Below is an example of an A/B test configuration.  In this example the <ABTestRoot> shown above is set to "abtest".
 * Each of the tests has 2 recipes called a and b.  The views and flows that are overwritten for those tests are enumerated in the views and flows arrays.
 *
 * In addition, certain variables can be set for each of the tests.  The variables in the vars object for the active recipes are accessible at run time
 * as properties of a model called ABTest.  By using the properties of the ABTest model, the default views can behave slightly differently, without needing custom views for small changes.
 *
 * Care must be taken to ensure that conflicting variable values are not set.
 * In the example below, if the application is in (test1, recipe a) and at the same time in (test2, recipe b), then conflicting values are defined for 'testVar' and 'otherTestVar'.
 * Similarly, there are conflicting views defined for databinding.Pg1 if multiple tests are enabled.  In these cases, the first value found in the configuration file is the one that's used.
 *
 *
 * var abtestConfig = {
    testRoot : "abtest",
    mainRoot : "main",
    tests : {
        test1:{
            a:{
                views:["databinding.Pg1"],
                flows:[],
                vars:{
                    testVar : "testValue1a",
                    otherTestVar : "otherValue1a"
                }
            },
            b:{
                views:["databinding.Pg1"],
                flows:["subflow"],
                vars:{}
            }
        },
        test2:{
            a:{
                views:["databinding.Pg1"],
                flows:[],
                vars:{
                    value1:"First Test Value",
                    value2:"Second Test Value"
                }
            },
            b:{
                views:["databinding.Pg1"],
                flows:[],
                vars:{
                    testVar : "testValue2b",
                    otherTestVar : "otherValue2b"
                }
            }
        }
    }
  };
 *
 */

import _ from 'src/core/libs/underscore-1.6.custom';
import PubSub from "src/core/pubsub";
import Logger from 'src/core/logging';
import Constants from 'src/application/constants'
import Config from "src/core/config/Config";
import {getDataApi} from "src/core/utils/getDataResolver";

const ABTestModelName = "ABTest";

export default function () {

   // -------------------------------
   // Function: setABTests
   // sets the current set of tests and recipe for each
   //
   // Parameters:
   //    values - object containing ABTestName:RecipeName pairs
   //             or query string of A/B test values i.e. test=recipe&test2=recipe2&...
   // -------------------------------
   this.setABTests = function (values) {
      const testVars = {};
      const testVals = _.isObject(values) ? values : {};

      // Delete the ABTest model
      // in case a previous test model exists from a different set of tests
      getDataApi().removeModel(ABTestModelName);

      _active = false;

      if (_.isString(values)) {
         const _tmp = values.split('&');
         _.each(_tmp, (currentABTestPair) => {
            const currentABTestPairArr = currentABTestPair.split("=");
            if (currentABTestPairArr.length === 2) {
               testVals[currentABTestPairArr[0]] = currentABTestPairArr[1];
            }
         });
      }
      _abTests = {};
      if (!_abtestConfig.tests) {
         return;
      }
      _.each(testVals, (recipe, abtest) => {
         _abTests[abtest] = recipe;
         if (_abtestConfig.tests[abtest] && _abtestConfig.tests[abtest][recipe]) {
            _active = true;  // active if any test is set for which there is a configuration
            _.each(_abtestConfig.tests[abtest][recipe].vars, (value, key) => {
               if (testVars[key]) {
                  Logger.error("Conflicting ABTest variable: " + key, "abTestResolver")
               }
               else {
                  testVars[key] = value;
               }
            });
         }
      });

      // if the data package is present, create a ABTest model
      getDataApi().addModel(ABTestModelName, {inData: testVars});
   };

   // -------------------------------
   // Function: getABTestPage
   // return the path to the page under the current test conditions
   //
   // Parameters:
   //    pageRef - the page reference
   //    defaultPagePath - the default (non-test) path to the page
   // -------------------------------
   this.getABTestPage = function (pageRef, defaultPagePath) {
      if (!_active) {
         return null;
      }

      const rootPath = _abtestConfig.testRoot || "";
      let pageFound = null;

      // iterate through all of the abtest key/value pairs in the model
      // and look for the corresponding value in the config
      _.each(_abTests, (recipe, test) => {
         if (_abtestConfig.tests[test] && _abtestConfig.tests[test][recipe] && _.isArray(_abtestConfig.tests[test][recipe].views)) {
            const testViews = _abtestConfig.tests[test][recipe].views;
            if (_.contains(testViews, pageRef) && !pageFound) {
               pageFound = rootPath + "/" + test + "/" + recipe + "/" + _stripDefaultRoot(defaultPagePath);
            }
         }

      });

      return pageFound;
   };

   // -------------------------------
   // Function: getABTestFlow
   // return the path to the flow definition file, given the current set of active tests
   //
   // Parameters:
   //    flowRef - the flow reference
   //    defaultFlowPath - the default (non-test) path to the flow
   // -------------------------------
   this.getABTestFlow = function (flowRef, defaultFlowPath) {
      if (!_active) {
         return null;
      }

      const rootPath = _abtestConfig.testRoot || "";
      let flowFound = null;

      // iterate through all of the abtest key/value pairs in the model
      // and look for the corresponding value in the config
      _.each(_abTests, (recipe, test) => {
         if (_abtestConfig.tests[test] && _abtestConfig.tests[test][recipe] && _.isArray(_abtestConfig.tests[test][recipe].flows)) {
            const testFlows = _abtestConfig.tests[test][recipe].flows;
            if (_.contains(testFlows, flowRef) && !flowFound) {
               flowFound = rootPath + "/" + test + "/" + recipe + "/" + _stripDefaultRoot(defaultFlowPath);
            }
         }
      });
      return flowFound;
   };

   // -------------\\
   // -- PRIVATE -- \\
   //----------------\\

   let _abTests      = {},
       _abtestConfig = Config.get('application.ABTestConfig', {}),
       _active       = false;


   //-------------------------------
   // take the default path returned by the flow or view resolver, and remove the root part
   //
   //-------------------------------
   const _stripDefaultRoot = function (path) {
      let defaultRoot = _abtestConfig.mainRoot;
      if (!defaultRoot || defaultRoot.length === 0) {
         return path;
      }

      // add trailing slash if it's not already there
      /\/$/.test(defaultRoot) || (defaultRoot += "/");  // jshint ignore:line
      if (path.indexOf(defaultRoot) !== 0) {
         return path;
      }
      else {
         return path.substr(defaultRoot.length);
      }
   };

   // listen for changes to the config.
   PubSub.subscribe(Constants.events.kOptions, () => {
      const newConfig = Config.get('application.ABTestConfig');
      _abtestConfig = newConfig;
   }, this);
}


