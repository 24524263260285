/**
 * This class represents data that will be logged
 * LogType will be re-set appropriately in the logger function as it passes out to the app
 */
import Constants from 'src/core/constants';
import pkg from '../../../package.json';

const defaultComponent = pkg.name;

export default class LogObj {
   constructor(
      messageOrObject,
      component = defaultComponent,
      errorType = Constants.log.ERROR,
      ex /* javascript exception if applicable */
   ) {
      let logObj = {
         xinch_version: pkg.version,
         message: messageOrObject,
         component,
         logType: errorType,
         exception: ex ? ex.message || '' : undefined,
         stack: ex ? ex.stack || '' : undefined,
         browser: window.navigator.userAgent
      };
      if (typeof messageOrObject === 'object') {
         logObj = Object.assign(logObj, messageOrObject);
         logObj.message = messageOrObject.message || component;
      }

      // now re-assign the object to 'this' so that we can do a 'instanceof LogObj' against this class
      Object.keys(logObj).forEach((key) => {
         this[key] = logObj[key];
      });
   }
}
