//-------------------------------------------------------------------------------------
// class: FlowController
//
// About:
//  This class manages the state machine of X.
//  It manages the lifecycle of flows and is in charge of progressing the state machine from view to view.
//      running actions and subflows along the way.
//
//  As a response to the calls to 'getNextView' and 'navigateTo', this class will return a reference to a view that higher level calling code must
//  Resolve to an actual implementation of that view.

// This class uses a flow resolver that has been injected into the system.
// If clients have special requirements around resolving flow references, they must supply a flow resolver options file to let the system know
// where and how to resolve flow references to actual flow definitions

//  Options : onEndCB, onModalCB, onErrorCB
//
//-------------------------------------------------------------------------------------
import _ from 'src/core/libs/underscore-1.6.custom'
import Logger from 'src/core/logging';
import LogObj from "src/core/logging/LogObj";
import uuid from 'src/core/utils/UUID';

import Constants from 'src/flow/constants';
import Flow from 'src/flow/engine/Flow';
import NavigationObject from 'src/flow/engine/FlowNavigationObject';
import Registry from 'src/flow/registry/flowRegistry';
import {getDataApi} from "src/core/utils/getDataResolver";

/**
 *
 * @param options
 *          - onEndCB : callback when the flow sequence ends
 *          - onErrorCB : callback when the flow errors out
 *          - onModalCB : callback when the flow encounters modal functionality
 *
 * @returns {{start: start, navigateTo: navigateTo, getNextView: getNextView, pause: pause, resume: resume, getStateToCurrentFlow: getStateToCurrentFlow, getCurrentFlowVariable: getCurrentFlowVariable, setCurrentFlowVariable: setCurrentFlowVariable, getCurrentFlowScopeName: getCurrentFlowScopeName, isBusy: isBusy, getId: getId}}
 * @constructor
 */
export default function (options) {

   const component = "Flow Controller";

   const _instance = {

      /**
       *  start the specifed flow
       * @param flowName
       * @param inputVars
       * @param options
       * @param callback  function callback to execute when we're done navigating to first view,
       *                  will return a flowResponse object
       */
      start: function (flowName, inputVars, options, callback) {
         const nav = new NavigationObject(flowName, inputVars, options);
         this.navigateTo(nav, callback);
      },

      //-------------------------------------------
      // Function: navigateTo
      // jump to the specifed node in the heirarchy of flows specified by the target (path)
      //
      // Parameters:
      //   pathElements - array of NavigationObjectObjs that specify how to get to the requested node,
      //   callback - function callback to execute when we're done navigating, will return a flowResponse object
      //-------------------------------------------
      navigateTo: function (pathElements, callback) {
         if (!callback) {
            logError("navigateTo() called without callback");
            return;
         }
         else if (!pathElements) {
            callback(_createErrorResponse("No pathElements passed to navigateTo"));
            return;
         }


         // Throw the old stack away
         _currentFlow = null;
         _flowStack = [];


         // Save off the first and the last
         //  - The first must be the main flow reference
         //  - The last can be any state-type
         const start = pathElements[0];
         const end = pathElements[pathElements.length - 1];

         if (!(start instanceof NavigationObject)) {
            return callback(_createErrorResponse("navigateTo : Start node is not a NavigationObject Object"));
         }
         if (!(end instanceof NavigationObject)) {
            return callback(_createErrorResponse("navigateTo : end node is not a NavigationObject Object"));
         }


         // Only one thing in the jump path, basically if the user only passed in the flow name
         if (start === end) {
            _loadFlow(start.nodeName, start.inputVars, start.options, start.nodeName, (err) => {
               if (err) {
                  _onErrorCallback(err);
               }
               else {
                  _runToView(null, false, (flowResp) => {
                     callback(flowResp);
                  });
               }
            });
         }
         else {
            _loadFlow(start.nodeName, start.inputVars, start.options, start.nodeName, (err) => {
               if (err) {
                  _onErrorCallback(err);
               }
               else {
                  _currentFlow.onStart(() => {
                     _loadPathElements(1, pathElements, (err) => {
                        if (err) {
                           _onErrorCallback(err);
                        }
                        else {
                           _runToView(end.nodeName, true, (flowResp) => {
                              callback(flowResp);
                           });
                        }
                     });
                  });
               }
            });
         }
      },

      //-------------------------------------------
      // Function: getNextView
      // Return the next page based on the response passed in from the current page
      // If no response is passed in, we'll assume this is a request for the first view of the flow
      //
      // Parameters:
      //   response - the response from which to determine the next view
      //-------------------------------------------
      getNextView: function (response, callback) {
         if (!callback) {
            logError("getNextView() called without callback");
            return;
         }
         if (!response) {
            return callback(_createErrorResponse("No response passed to getNextView"));
         }

         _runToView(response, false, (flowResp) => {
            callback(flowResp);
         });

      },

      //---------------------------------------
      //  Pause/Resume functionality
      //---------------------------------------
      // pause: function () {
      //    _paused = true;
      // },

      resume: function (response, callback) {
         // _paused = false;
         if (response) {
            _runToView(response, false, (flowResp) => {
               callback(flowResp);
            });
         }
         else {
            _currentFlow.restoreToLastView(callback);
         }
      },


      //-------------------------------------------
      // Function: get the path to the current flow
      //  - returns an array of flowinfoobjects
      //------------------------------------------
      getStateToCurrentFlow: function (currentNodeName) {
         const path = [];
         for (let i = 0; i < _flowStack.length; i++) {
            path.push({"nodeName": _flowStack[i].getNodeName(), "id": _flowStack[i].getId()});
         }
         path.push({"nodeName": currentNodeName, "id": _currentFlow.getId()});
         return path;
      },

      //-------------------------------------------
      // Function: getCurrentFlowVariable
      // get a flow scoped variable out of the current flow
      //  - may return null or undefined
      //
      // Parameters:
      //   name - the name of the variable to get
      //------------------------------------------
      getCurrentFlowVariable: function (name) {
         if (_currentFlow) {
            return _currentFlow.getFlowVariable(name);
         }
      },

      //-------------------------------------------
      // Function: setCurrentFlowVariable
      // set a flow scoped variable out of the current flow
      //  - may return null or undefined
      //
      // Parameters:
      //   name - the name of the variable to set
      //   value - the value of the variable to be set
      //-------------------------------------------
      setCurrentFlowVariable: function (name, value) {
         if (_currentFlow) {
            _currentFlow.setFlowVariable(name, value);
         }
      },

      //-------------------------------------------
      // Function: getCurrentFlowScopeName
      // get the name of the current flow scope
      //  - may return null or undefined
      //
      //-------------------------------------------
      getCurrentFlowScopeName: function () {
         if (_currentFlow) {
            return _currentFlow.getflowScopedModelName();
         }
      },

      //-------------------------------------------
      // Function: isBusy
      // indicates whether flow resolver is still waiting for a flow definition to load
      //
      //-------------------------------------------
      isBusy: function () {
         if (_currentFlow && _currentFlow.isBusy()) {
            Logger.warn("current flow is busy waiting for asynchronous functionality to complete", component);
            return _currentFlow.isBusy();
         }
         return false;
      },

      getId: function () {
         return _id;
      }
   };

   //-------------------------------------------------------------------------------------
   // Group: Private
   // PRIVATE STUFF  - dont look below this line for your API!
   //      - not included in the public API returned in the _instance Object
   //-------------------------------------------------------------------------------------
   const _id              = uuid(),
         _flowResolver    = Registry.getInterface(Constants.interfaces.kFlowResolver),
         // _paused          = false,
         _context         = options.context || "",  // the viewport context that this flow is currently running in.
         _onEndCallback   = options.onEndCB || function () {
         },
         _onModalCallback = options.onModalCB || function () {
         },
         _onErrorCallback = options.onErrorCB || function () {
         };
   let _currentFlow = null,
       _flowStack   = [];


   //--------------------------------------------
   // Function: _loadPathElements
   // used to recursively load each step of a path during a navigational jump
   //
   // Parameters:
   //   step - index of current element in the pathElements array
   //   pathElements - array of path elements
   //   callback - function to execute when reaching the last path element
   //--------------------------------------------
   function _loadPathElements(step, jumpNodes, callback) {
      if (step < jumpNodes.length - 1) {
         const jumpNode = jumpNodes[step];
         if (!_currentFlow.allowRandomAccess()) {
            callback(_createErrorResponse("Cannot jump- flow does not allow jump access: " + _currentFlow.getName()));
         }
         if (!_currentFlow.has(jumpNode.nodeName)) {
            callback(_createErrorResponse("Cannot jump- flow: " + _currentFlow.getName() + " - does not have element: " + jumpNode.nodeName));
         }
         _currentFlow.jumpToState(jumpNode.nodeName, (rsp) => {
            _loadFlow(rsp.value, jumpNode.inputVars, jumpNode.options, rsp.nodeName, (err) => {
               if (err) {
                  _onErrorCallback(err);
               }
               else {
                  _currentFlow.onStart(() => {
                     _loadPathElements(step + 1, jumpNodes, callback);
                  });
               }
            });
         });

      }
      else {
         callback();
      }
   }

   //--------------------------------------------
   // Function: _runToView
   // Step through the flow to the next view
   // Running actions, executing subflows, etc.
   // Returns a string representing the resolved view
   //  - if the flow sequencing is done, it will return an indicator that the
   //    flow is finished plus any output of the flow
   //
   // Parameters:
   //   response - the response to determine the next view
   //   isJump - boolean to identify a jump is required
   //--------------------------------------------
   function _runToView(response, isJump, callback) {

      if (isJump) {
         _currentFlow.jumpToState(response, (flowResp) => {
            _handleFlowResponse(flowResp, callback);
         });
      }
      else if (!response) {
         _currentFlow.start((flowResp) => {
            _handleFlowResponse(flowResp, callback);
         });
      }
      else {
         _currentFlow.doNext(response, (flowResp) => {  // Do next with no 'response' starts the flow from the beginning
            _handleFlowResponse(flowResp, callback);
         });
      }
   }

   function _handleFlowResponse(flowResp, callback) {
      //------------------------------
      // Handle a Modal response
      //------------------------------
      // if modal - pause our current flow and pass back controll to the caller
      if (!!flowResp.modal && _onModalCallback) {
         // pause the current flow
         _instance.pause();

         // Now handle the modal and return;
         _onModalCallback(flowResp);
         return;
      }


      //---------------------------------
      // Handle a normal response
      //---------------------------------
      switch (flowResp.state_type) {

         case Constants.flow.kViewState :
            // Return a reference to a view
            callback(flowResp);
            break;

         case Constants.flow.kFlowState :
            // load the new Flow (making it current), then run
            _loadFlow(flowResp.value, flowResp.inputVars, flowResp.options, flowResp.nodeName, (err) => {
               if (err) {
                  _onErrorCallback(err);
               }
               else {
                  // Pass empty response to get the start state of the flow
                  _runToView(null, false, (rsp) => {
                     callback(rsp);
                  });
               }
            });
            break;


         case Constants.flow.kEndState :
            // pop back up to the parent flow and run
            _currentFlow.end(() => {
               _currentFlow = _flowStack.pop();
               if (_currentFlow) {
                  _runToView(flowResp.value, false, (rsp) => {
                     callback(rsp);
                  });
               }
               else {
                  if (_.isFunction(_onEndCallback)) {
                     _onEndCallback(flowResp);
                  }
                  else {
                     callback(flowResp);
                  }
               }

            });
            break;


         case Constants.flow.kErrorState :
         /* falls through */
         default :
            if (_.isFunction(_onErrorCallback)) {
               _onErrorCallback(flowResp);
            }
            break;
      }
   }


   //--------------------------------------------
   // Function: _loadFlow
   // Load a new flow definition and initialize it
   // Push the current one on the stack
   //
   // Parameters:
   //   flowRef - the reference to the flow
   //   inputVars - the variables to be passed into a flow
   //   options     - external options about the flow to be passed into it
   //--------------------------------------------
   function _loadFlow(flowRef, inputVars, options, nodeName, callback) {
      options = options || {};

      // pass the current flowscope down into the subflow
      if (_currentFlow) {
         const defaultDataApi = getDataApi();
         const _fs = defaultDataApi.getAllDataInModel(_instance.getCurrentFlowScopeName());
         inputVars = _.extend(_.extend({}, _fs), inputVars);
      }

      // lookup/load the new flow
      _flowResolver.resolve(flowRef)
         .then(
            (flowDefObj) => {
               // push the current flow
               if (_currentFlow) {
                  _flowStack.push(_currentFlow);
               }

               // Create a new flow and initialize it.
               try {
                  _currentFlow = new Flow(flowRef, flowDefObj);
                  _currentFlow.__ref = nodeName;
               } catch (ex) {
                  callback(_createErrorResponse("No Flow found for flow reference" + ": " + flowRef));
                  return;
               }

               // get the current path and nodeName and pass it to the flow,
               // so the flow has some notion of its context
               const _flowInfo = {
                  "path": _instance.getStateToCurrentFlow(nodeName),
                  "nodeName": nodeName
               };
               options.context = _context;
               _currentFlow.init(inputVars, options, _flowInfo, callback);
            },
            (ex) => {
               logError(ex)
               callback(_createErrorResponse(ex));
            }
         );
   }


   //--------------------------------------------
   // Function: logError
   // Publishes an error
   //
   // Parameters:
   //   msg - the message for the error
   //--------------------------------------------
   function logError(msg) {
      Logger.error(msg, component);
   }

   /* -------------------------------
    // Function: _createResponse
    // construct the response
    {
    state_type, // type of current node
    value = null, // navigation value from the current node
    data = null, // any data specified in the current node
    options = null,
    inputVars = null,
    modal = null,
    autoNav = null
    error = Exception; // will be populated with X.Exception if there is an error;
    }
    // -------------------------------
    */
   function _createErrorResponse(msg) {
      return {
         state_type: Constants.flow.kErrorState,
         error: new LogObj(msg, component)
      };
   }

   return _instance;

}

