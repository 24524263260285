import XCore from 'src/core/X-core';
import defaultFlowOptions from "src/flow/config/options";
import Registry from "src/flow/registry/flowRegistry";
import Constants from "src/flow/constants";
import FlowResolver from "src/flow/interfaces/FlowResolver";
import flowApi from 'src/flow/flowApi'
import _ from "src/core/libs/underscore-1.6.custom";

export default class XinchFlow extends XCore {

   constructor() {
      super(defaultFlowOptions);

      // Register our default components
      Registry.registerInterface(Constants.interfaces.kFlowResolver, new FlowResolver(), false);
   }
}

// add our Flow APIs to the prototype
const f = new flowApi();
_.each(f, (impl, key) => {
   XinchFlow.prototype[key] = impl;
});
