import _ from 'src/core/libs/underscore-1.6.custom';
import {convertStringToJSPrimitive} from 'src/core/utils/JSONSerializer';
import {getPathValue} from "src/core/utils/objectUtils";
import Constants from "src/flow/constants";
import Logger from "src/core/logging";

/**
 * Given a string tht represents a function call, execute it.
 *   - assume its a promise since we really don't know at this generic level
 *   - if a promised is not returned, javascript will do us a 1-up and resolve the promise with the result.
 *   - .... cool, right!
 * @param stringFunc
 * @param options
 * @param outModelRefs
 * @returns {Promise<*>}
 */
export async function executeStringAsFunction (stringFunc, options = {}, outModelRefs = {}) {
   /* eslint-disable-next-line no-unused-vars */
   const {returnStringInQuotes, additionalContext, ...rest} = options;

   // 1) pull apart the string into well known pieces of a function call.
   const parts = Constants.functionPartsRegex.exec(stringFunc.trim());
   let funcName, params;
   if (parts) {
      funcName = parts[1];
      params = parts[3];
   }

   // 2) Take the arguments list and resolve any model refs
   const resolvedParams = getArrayOfArgs(params, outModelRefs);

   // 3) Get the actual executable function from the window or additional context passed in
   const fn = getPathValue(funcName, {...window, ...additionalContext});
   let context = funcName.match(/(.*)\./);
   context = context? context[1]: funcName;

   // 4) Now execute the function, we don't know if its async or not, so we'll assume it is.
   //    Javascript is cool that way that it'll resolve
   let result;
   try {
      result = await fn.apply(context, resolvedParams)
   }
   catch (ex) {
      Logger.error(`Invalid Function: ${funcName}`, "executeStringAsFunction", ex);
      throw ex;
   }
   if (_.isString(result) && !!returnStringInQuotes) {
      result = result.addQuotes();
   }
   return result;
}

/**
 * Replace a character that appears within parenthesis in a given string
 * @param str - the string to search and replace within
 * @param charToReplace - the character to replace
 * @param replacement - the replacement for the character
 * @return - the new string
 */
export function replaceCharWithinParenthesis(str, charToReplace, replacement) {
   let newStr = "";
   let parenCount = 0;
   for (let i = 0; i < str.length; i++) {
      const currChar = str.charAt(i);
      if (currChar === "(") {
         parenCount += 1;
      }
      else if (currChar === ")") {
         parenCount = Math.max(parenCount - 1, 0);  // decrement, but not less than zero
      }
      if (currChar === charToReplace && parenCount > 0) {
         newStr += replacement;
      }
      else {
         newStr += currChar;
      }
   }
   return newStr;
}


// extract an array of arguments from a comma separated values within parenthesis
// arguments will be resolved to their native form and model references will be resolved
//-----------------------------------------------------------------------------
export function getArrayOfArgs(argsStr, outModelRefs = {}) {

   if (!argsStr || argsStr.length === 0) {
      return [];
   }
   // ensure commas within quotes are treated as part of the string, not as arg separators
   argsStr = _replaceCommasWithinQuotes(argsStr);

   // remove spaces around commas, and leading & trailing spaces
   argsStr = argsStr.replace(/\s*,\s*/g, ",").replace(/^\s*/, "").replace(/\s*$/, "");
   if (argsStr.length === 0) {
      return null;
   }
   const args = argsStr.split(",");

   // evaluate each argument to get into its native form (array, string, number, or another expression)
   for (let i = 0; i < args.length; i++) {
      args[i] = args[i].replace(/_comma_/g, ",");  // restore commas that might have been replaced above
      args[i] = convertStringToJSPrimitive(args[i], outModelRefs);
   }

   // replace commas within single or double quotes, so we can split arguments on remaining commas
   //---------------------------------------------------------------------------------------------
   function _replaceCommasWithinQuotes(str) {
      let newStr = "";
      let quoteCount = 0;
      let dblQuoteCount = 0;
      for (let i = 0; i < str.length; i++) {
         const currChar = str.charAt(i);
         if (currChar === "'" && dblQuoteCount === 0) {
            quoteCount = 1 - quoteCount;
         }
         else if (currChar === '"' && quoteCount === 0) {
            dblQuoteCount = 1 - dblQuoteCount;
         }
         if (currChar === "," && (quoteCount > 0 || dblQuoteCount > 0)) {
            newStr += "_comma_";
         }
         else {
            newStr += currChar;
         }
      }
      return newStr;
   }

   return args;
}


