/**
 * Split a model data reference into parts
 *
 * @param dataRef a fully specified data name, such as namespace.ModelName.propertyName[index]
 * @return {Object} contains properties including
 *      modelName
 *      key
 *      index (only applies to collections)
 *      isCollection - boolean
 *      property - property name of collection item (only applies to collections)
 */
import {normalizePath} from "src/core/utils/objectUtils";

export function splitDataRef(dataRef) {
   const result = {
      modelName: "",
      key: ""
   };
   // normalize the name
   // i.e. change the associated arrays in the format foo['bar'] -> foo.bar
   // also will normalize non-quoted indexes that are non-numeric foo[bar] -> foo.bar
   // but leaves along valid array indexes foo[0] -> foo[0]
   dataRef = normalizePath(dataRef, true);

   const _nv = dataRef.match(/^(.*?)\.(.*)$/);  // non-greedy will get modelName . ref
   if (_nv) {
      result.modelName = _nv[1];
      result.key = _nv[2];
   }
   else {
      result.modelName = dataRef;
   }
   return result;
}

// If modelNames is passed in, this function will act as a filter
// otherwise, we'll use all the models in the system.
// export function getModelsForDAO (daoName, modelNames, infilters) {
//     if (daoName._name) {
//         daoName = daoName._name;
//     }
//     var filters = {daoName : daoName};
//     X._.extend(filters, infilters);
//
//     return X.getModels(filters, modelNames, true);
// }


// export function convertNamesToModels(names) {
//    var modelArray = [];
//    _.each(names, (val) => {
//       var m;
//       if (_.isString(val)) {
//          m = Registry.getModel(val);
//       }
//       else if (val instanceof DataModel) {
//          m = val;
//       }
//       if (m) {
//          modelArray.push(m);
//       }
//    });
//    return modelArray;
// }
