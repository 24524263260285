import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import Logger from 'src/core/logging';
import {getDataResolver} from "src/core/utils/getDataResolver";

import {htmlEscape, htmlUnescape} from "src/core/utils/html_utils";
import {convertStringToJSPrimitive} from "src/core/utils/JSONSerializer";
import {getPathValue} from "src/core/utils/objectUtils";

import DataModel from 'src/data/model/DataModel';
import {getModelGroup} from "src/data/utils/modelAccessor";

import Constants from 'src/application/constants'

/**
 * Parse an element with the data-repeat attribute;
 *
 * @param $el
 * @returns {key, collection, stamp, bindings}
 */
export function parseRepeatAttr($el) {
   let attr = $el.attr("data-repeat");
   attr = attr.trim();
   const bindings = {};
   const dataResolver = getDataResolver();

   // get the bindings from the top level element
   dataResolver.resolveDynamicData(attr, bindings);


   // split the attribute into its parts (key operator collection)
   // and collection will be considered all text after the operator
   const parts = attr.split(/\s+/);
   if (!parts || parts.length < 3 || !_.contains(["in", "inModelGroup"], parts[1])) {
      Logger.error("Invalid data-repeat: " + attr, "parseRepeatAttr")
      return null;
   }

   // remove the (key and operator) from the attribute and piece the rest back to get the collection.
   let collection = convertStringToJSPrimitive(parts.splice(2).join(" "));
   if (parts[1] === "inModelGroup") {
      collection = getModelGroup(collection, true);
   }
   else if (_.isString(collection)) {
      collection = getPathValue(collection);
   }

   return {
      "key": parts[0],
      "collection": collection,
      "stamp": htmlUnescape($el.html()),
      "bindings": bindings
   };
}

/**
 * Given the
 * @param $el - the X.$ element that represents the parent of the repeated elements
 * @param repeaterInfo
 *  - key
 *  - collection
 *  - stamp
 *  - bindings
 *  @param args - extra arguments passed to the repeater (object of n/v pairs)
 * @returns {string}
 */
export function renderRepeater($el, repeaterInfo = {}, args = {}) {

   // Function to stamp out the HTML of the repeater element
   function _repeaterFunc() {
      if (!repeaterInfo.collection) {
         Logger.info("Repeater collection is empty, no data rendered. Repeater Element: " + htmlEscape($el[0].outerHTML));
         return "";
      }
      if (!repeaterInfo.key || !repeaterInfo.stamp) {
         let _msg = !repeaterInfo.collection ? "collection" : !repeaterInfo.key ? "key" : "stamp";
         _msg = "Invalid " + _msg;
         Logger.error("Invalid arguments passed to repeater: " + _msg + " Repeater Element: " + $el[0].outerHTML, "repeaterFunc")
         return "";
      }

      const _count = _.size(repeaterInfo.collection);
      let _index = -1,
          _first = false,
          _last = false,
          _middle = false,
          result = "";

      _.each(repeaterInfo.collection, (item, key) => {
         _index++;
         _first = (_index === 0);
         _last = (_index === repeaterInfo.collection.length - 1);
         _middle = !_first && !_last;
         const _args = _.extend({
            _count: _count,
            _index: _index,
            _key: key,
            _first: _first,
            _last: _last,
            _middle: _middle
         }, args);
         _args[repeaterInfo.key] = (item instanceof DataModel) ? item.getAll() : item;

         result += _resolveRepeaterArgs(repeaterInfo.stamp, _args);
      });
      return result;
   }

   // Function to replace any repeater arguments with their values in the HTML stamp
   function _resolveRepeaterArgs(inStr, args) {
      const dataResolver = getDataResolver();

      // change all model references so the dont interfere with the search for expressions
      inStr = inStr.replace(/\$\{([^\s]*)\}/g, function () {
         return "X_MODEL(" + arguments[1] + ")";
      });

      // evaluate only the expressions that contain any references to our arguments
      const keys = _.keys(args).join("|");
      inStr = inStr.replace(Constants.expressionRegexPattern, function () {
         const regex = new RegExp("(" + keys + ")");
         const found = arguments[1].match(regex);

         // the expression has one of our arguments in it
         // resolve the whole thing
         // (change the model references back, first)
         if (found) {
            const expStr = arguments[0].replace(/X_MODEL\((.*?)\)/g, function () {
               return "${" + arguments[1] + "}";
            });
            return dataResolver.resolveDynamicData(expStr, {}, args);
         }
         else {
            return arguments[0];
         }

      });

      // Now that we're done replacing repeater arguments in the html string,
      // return the model references back
      inStr = inStr.replace(/X_MODEL\((.*?)\)/g, function () {
         return "${" + arguments[1] + "}";
      });

      return inStr;
   }


   // create the contents of the repeater in a temporary div that is not attached to the DOM
   // which essentially creates a document fragment
   // BIND PAGE's <%= pkg.appName %> bindings to the document fragment,
   // its tons faster to work on a document fragment rather than the actual document
   const frag = $("<div></div>");
   frag.html(_repeaterFunc());

   // now append the document fragment back to the element
   // TODO - this can't be the best way to do this because during a re-render, if any
   // of the elements have state (i.e. checked or disabled) it'll be lost.
   $el.empty().append(frag.contents());

}



