/*
 * class: JSFlow
 * X.flow.Flow
 *
 * about:
 * This class manages the state-machine within a flow definintion
 * 
 * Transmission map for JSFlows
 *
 * Events :
 *      X.constants.events.kFlowStart  -- when a flow starts
 *      {
 *          "name" : flow name,
 *          "id" : uuid of the flow
 *          "options" : options passed into the flow,
 *          "metaData" : metadata passed in as part of the flow definition, plus
 *          {
 *              path : array of NavigationObject objects that tells the system how to statefully get to this flow
 *              nodeName : the nodename of this flow
 *          }
 *      }
 *
 *      X.constants.events.kFlowEnd  -- when a flow ends
 *      {
 *          "name" : flow name,
 *          "id" : uuid of the flow
 *          "options" : options passed into the flow,
 *          "metaData" : metadata passed in as part of the flow definition, plus
 *          {
 *              path : array of NavigationObject objects that tells the system how to statefully get to this flow
 *              nodeName : the nodename of this flow
 *          }
 *       }
 *
 *      X.constants.events.kFlowTransition  -- on every transition of the flow
 *      {
 *         "name" : flow name,
 *         "id" : uuid of the flow,
 *         "nodeName" : name of the transition node,
 *         "stateDef" : object describing the nodename,
 *         "path" : path to the current flow
 *      }
 *
 * 
 * Note : As a bonus, this class will automatically generate a flow scoped model that will be valid for the lifetime of the flow
 *        All input variables into the flow will be added to the flow scoped model by default
 *        Data in the flow scoped model can be accessed outside of this class by referencing it in a view using on of the following notations:
 *           1) ${FLOW_VAR.<key>}  - this will immediately replace contents with the value out of flow scope
 *           2) FLOW_VAR.<key>  - these will be treated as dynamic data-binding so elements on a page can update the flow scope.
 * 
 *      : By default views states get a history attribute of 'always', flows get 'always', and actions get 'never'
 *
 * Note : By default, history attributes will be attached to each node unless otherwise specified
 *  ACTION : never
 *  VIEW : always (unless modal - then NEVER)
 *  FLOW : always (unless modal - then NEVER)
 *
 *
 * FlowDef:
 *  {
 *      require : { // any resources that will be needed before the flow starts [optional]
 *                   css : [ "path/to/css/file.css", "path/to/css/file2.css", ... ],
 *                   js : [ "path/to/css/file.js", "path/to/css/file2.js", ... ],
 *                   schema : [ "schema", "schema2", ... ],
 *                   module : [ "path/to/css/file.css", "path/to/css/file2.css", ... ]
 *                }
 *
 *      models : {  // Model that this flow will need [ optional ]
 *                  name : <string>,
 *                  className : <string>,  // if not specified, will use the one in the Options file
 *                  definition : <string>
 *                  DAO : <string - name of the DAO to use for this model> if not specified, will use the one in the Options file
 *              },
 *
 *      modal : { [ optional ] // show the flow in a modal window
 *              closeButton : <true | false > // have <%= pkg.appName %> supply a close button on the modal,
 *              navWhenDone : <true | false > // allow the content of a modal window navigate the flow controller
 *              blockCloseWhenErrors : <true | false> // don't allow modal to close if errors are present
 *      },
 *
 *
 *      onStart : {
 *          ref : <ref> // Action to perform on flow startup (maybe populating the model) [optional]
 *          params : parameters to pass to the javascript function
 *          exp : <%= pkg.appName %> expression
 *      }
 *
 *
 *      onEnd : {
 *          ref : <ref> // Action to perform on flow startup (maybe populating the model) [optional]
 *          params : parameters to pass to the javascript function
 *          exp : <%= pkg.appName %> expression
 *      }
 *
 *      startState : <name>, // name of the first node in the flow to execute
 *
 *      allowRandomAccess : <true | false>  - defaults to true // Can we navigate via jump inside this flow
 *
 *
 *
 *      <name>: {
 *          history : <never | always | session >
 *          state_type:<VIEW | ACTION | FLOW | END>,
 *          ref: <string>, // reference to a VIEW, ACTION, or FLOW
 *          data : {n:v, n:v, ...} // optional if we want to associate any data with this state
 *          transitions :{
 *              <on>: <to>,
 *              <on>: <to>,
 *              etc...
 *          }
 *      },
 *      <name> : .....
 *      }
 *
 *   Notes:
 *      In the transitions, ALL <on> values are required to be Strings.  No looking for transitions on booleans, nulls, undefineds, etc.
 *      Will turn all responses from Actions or values referenced in Models into Strings when looking up a transition.
 *
 *      for example, you need to do something like this:
 *
 *      ...
 *      transitions : {
 *          "false" : "goToFalse",
 *          "null"  " "goToNull",
 *          "undefined " : "goToUndefined",
 *          "0" : goToZero
 *       }
 *
 * 
 * Constructor:
 *      name : name of the flow
 *      flowDef : JSON definition of the flow
 *
 *
 *  Notes:
 *      Action states can take an 'exp' key.  And the value of that key would be a valid action expression
 *      End states can take an expression as a value of the 'outcome'.  And the value of that key would be a valid action expression
 */
import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';
import LogObj from "src/core/logging/LogObj";
import PubSub from 'src/core/pubsub';
import {getDataApi, getDataResolver} from 'src/core/utils/getDataResolver';
import {executeStringAsFunction} from "src/core/utils/function_utils";
import uuid from 'src/core/utils/UUID';

import Constants from 'src/flow/constants';
import ResourceLoader from 'src/flow/loaders/ResourceLoader';
import Profiler from "src/core/profiler";

export default function (name, flowDef) {

   const _instance = {

      //---------------------------------------------------------
      // Function: init
      // Initialize a flow
      //  - initialize the flow with input parameters
      //---------------------------------------------------------
      init: function (inputVars, options, flowInfo, callback) {

         // Handle any options passed in
         _options = options || {};
         _context = _options.context;

         // Set up the meta data and add any flowInfo to it.
         _metaData = _flowDef.metaData || {};
         _.defaults(_metaData, flowInfo);

         //            // Force an ID if one is passed in
         //            // Used when using the back history and we need to hydrate a flow back
         //            // to its original state
         //            if (_options.forceFlowId)
         //                _id = _options.forceFlowId;

         // Create a flow scoped Model
         // And add the input options to the model
         _defaultDataApi.addModel(this.getflowScopedModelName());
         if (inputVars) {
            _.each(inputVars, (val, name) => {
               _defaultDataApi.setDataVal(this.getflowScopedModelName(), name, val);
            });

         }

         // Load any requested resources
         // Then the call the _loadModels when done
         const require = _flowDef.require || {};
         ResourceLoader.loadResources(require).finally(() => {
            // if we need to gen up any models
            if (require.models) {
               if (_.isString(require.models)) {
                  _defaultDataApi.addModel(require.models);
               }
               else if (_.isObject(require.models)) {
                  _defaultDataApi.addModels(require.models);
               }
            }

            callback();
         });
      },


      //---------------------------------------------------------
      // Function: start
      // Start a flow
      //  - start up the flow and run to the first view
      //  - return the first view response
      //---------------------------------------------------------
      start: function (callback) {
         if (_busy) {
            Logger.info("Cannot start flow: " + _name + " We are busy");
            return;
         }

         const self = this;
         _errorStatus = null;

         PubSub.publish(Constants.events.kFlowStart + "." + _context, _getFlowInfoObj());

         _onStart(() => {         // first, execute onStart instructions, if present
            self.doNext(null, (resp) => {
               callback(resp);
            });
         });

      },


      //---------------------------------------------------------
      // Function: end
      // This is called when an kEndState is encountered
      // checks to see if this modal was displayed in a modal, if true, end it.
      // In the flow definition, if there's an onEnd action specified, execute it
      // Publishes a flowEnd event with the flows name
      //---------------------------------------------------------
      end: function (callback) {
         const flowName = this.getflowScopedModelName();
         _onEnd(() => {
            // publish event before blowing away the model.  Listeners may want to capture info out of the model
            PubSub.publish(Constants.events.kFlowEnd + "." + _context, _getFlowInfoObj());
            _defaultDataApi.removeModel(flowName, {silent: true});
            callback();
         });
      },


      //---------------------------------------------------------
      // Function: doNext
      // Get the appropriate response out of the next logic state in the flow
      //  - will execute through action states without returning a response out
      //
      // Parameters:
      //   response - the response used to determine the current state
      //---------------------------------------------------------

      doNext: function (response, ctrlCallback) {
         if (_busy) {
            Logger.info("Cannot doNext in flow: " + _name + " We are busy");
            ctrlCallback();
         }

         _errorStatus = null;
         _busy = true;
         // Set the current state based on the response from the previous state
         _setCurrent(response);

         // If there was an error setting the current node in the flow
         if (_errorStatus) {
            return ctrlCallback(_createResponse());
         }


         // Now run the State-machine to the first logical page
         _run(() => {
            const resp = _createResponse();
            _busy = false;
            if (ctrlCallback) {
               ctrlCallback(resp);
            }
         });

      },

      //---------------------------------------------------------
      // Function: jumpToState
      // Jump into the middle of the flow
      //  - return the response of the appropriate state
      //  - will run through actions to get the the next state-type
      //
      // Parameters:
      // stateName - the state name to jump to
      //---------------------------------------------------------
      jumpToState: function (stateName, ctrlCallback) {

         if (_busy) {
            Logger.info("Cannot jump in flow: " + _name + " We are busy");
            ctrlCallback();
         }

         _errorStatus = null;
         _busy = true;
         if (this.allowRandomAccess()) {
            _setCurrent(stateName, true);

            if (!_currentState) {
               _setErrorState("jumpToState: State Name '" + stateName + "' does not exist!");
               return null;
            }
            _currentState.name = stateName;
         }


         _run(() => {
            const resp = _createResponse();
            _busy = false;
            if (ctrlCallback) {
               ctrlCallback(resp);
            }
         });
      },


      //---------------------------------------------------------
      // Function: restoreToLastView
      // Restore this flow to the last visted view state
      //
      // Parameters:
      // callback - callback with the response of the function
      //---------------------------------------------------------
      restoreToLastView: function (callback) {
         _currentState = _lastViewState;

         // if no last view, start at the beginning
         if (_currentState) {
            const resp = _createResponse();
            callback(resp);
         }
         else {
            this.start(callback);
         }

      },


      //---------------------------------------------------------
      // Function: has
      // Does this flow contain a state-type with the passed name
      //
      // Parameters:
      // name - the name of the flow definition
      //---------------------------------------------------------
      has: function (name) {
         return (typeof _flowDef[name] != 'undefined');
      },

      //---------------------------------------------------------
      // Function: allowRandomAccess
      // Does this flow allow random access into middle of it	for the case of a jumpTo
      //---------------------------------------------------------
      allowRandomAccess: function () {
         if (this.has('allowRandomAccess')) {
            return _flowDef.allowRandomAccess;
         }
         else {
            return true;
         }
      },

      //---------------------------------------------------------
      // Function: getName
      // Get the name of the flow
      //---------------------------------------------------------
      getName: function () {
         return _name;
      },

      //---------------------------------------------------------
      // Function: getId
      // Get the id of the flow
      //---------------------------------------------------------
      getId: function () {
         return _id;
      },

      getNodeName: function () {
         return _metaData.nodeName;
      },

      //---------------------------------------------------------
      // Function: getFlowVariable
      // Get the value of a flow scoped variable
      //---------------------------------------------------------
      getFlowVariable: function (name) {
         return _defaultDataApi.getDataVal(this.getflowScopedModelName(), name);
      },

      //---------------------------------------------------------
      // Function: setFlowVariable
      // Set the value of a flow scoped variable
      //---------------------------------------------------------
      setFlowVariable: function (name, value) {
         return _defaultDataApi.setDataVal(this.getflowScopedModelName(), name, value);
      },

      //---------------------------------------------------------
      // Function: getflowScopedModelName
      // Get the name of the model that represents this flow scope
      //---------------------------------------------------------
      getflowScopedModelName: function () {
         return _id;
      },


      //---------------------------------------------------------
      // Function: isBusy
      // Indicate whether flow is waiting for an asynchronous action to complete
      //---------------------------------------------------------
      isBusy: function () {
         return _busy;
      }

   };

   //============================================================================
   //============================================================================
   // Group: Private
   // PRIVATE STUFF  - dont look below this line for your API!
   //============================================================================
   //============================================================================
   const _name           = name,
         _id             = Constants.scopes.kFlowScope + "_" + uuid(true),
         _flowDef        = flowDef,
         _defaultDataApi = getDataApi();
   let _options       = {},
       _metaData      = {},
       _currentState  = null,
       _context       = null,  // the viewport context that this flow is currently running in.
       _lastViewState = null,
       _busy          = false,
       _errorStatus   = null;


   if (!name) {
      _logError("Constructor: Flow name not specified", true);
   }
   if (!flowDef) {
      _logError("Constructor: Flow Definition Object not specified", true);
   }

   //---------------------------------------------------------
   // Function: _onStart
   // Run the action defined in the onStart node, if any
   // @param callback - function to call when done
   //---------------------------------------------------------
   function _onStart(callback) {
      // execute any onStart instructions
      if (_flowDef.onStart) {
         _runAction(_flowDef.onStart, callback);
      }
      else {
         callback();
      }
   }

   //---------------------------------------------------------
   // Function: _onEnd
   // Run the action defined in the onEnd node, if any
   // @param callback - function to call when done
   //---------------------------------------------------------
   function _onEnd(callback) {
      // execute any onEnd instructions
      if (_flowDef.onEnd) {
         _runAction(_flowDef.onEnd, callback);
      }
      else {
         callback();
      }
   }

   //--------------------------------------------------------------------------
   // Function: _setCurrent
   // Set the new current State based on the outcome of the old current State
   // Sets _errorStatus if there is a failure
   //
   // Parameters:
   //   response - the response used to determine the current state
   // Return value:
   //   boolean indicating success
   //--------------------------------------------------------------------------
   function _setCurrent(response, bJumpTo) {
      let next        = null,
          curNodeName = _currentState ? _currentState.nodeName : "",
          trans       = null;

      // if directly setting the node
      if (bJumpTo) {
         next = response;
      }
      // If no currentState, or no response lets start at the beginning
      else if (null === _currentState || null === response) {
         if (!_instance.has("startState")) {
            _setErrorState("No 'startState' defined");
            return false;
         }
         curNodeName = "startState";
         next = _flowDef.startState;
      }


      // find the next state
      else {
         if (!_currentState.transitions) {
            _setErrorState("Flow Object: " + _name + " - No transitions defined for '" + _currentState.nodeName + "'");
            return false;
         }
         trans = _currentState.transitions;
         next = trans[response];
         if (!next && trans[Constants.kWildCard]) {
            next = trans[Constants.kWildCard];
         }
      }

      // Ok we have somewhere to go, lets find it in the flow definition
      if (next) {
         next = _resolveFlowValue(next);
         _currentState = _flowDef[next];

         if (!_currentState) {
            if (bJumpTo) {
               _setErrorState("Cannot jump, node does not exist: " + next);
            }
            else {
               _setErrorState("No Transition for: " + curNodeName + " -answer: " + next);
            }
            return false;
         }
         _currentState.nodeName = next;

      }
      else {
         response = response || "<EMPTY or NULL>";
         _setErrorState("No Transition for: " + curNodeName + ": " + response);
         return false;
      }

      //  DEBUG Stuff
      if (curNodeName) {
         Logger.info("Flow: - " + _name + " Transition from state '" + curNodeName + "' response: '" + response + "' to state '" +
            _currentState.nodeName + "'", "Flow");
      }
      else {
         Logger.info("Flow: - " + _name + " Starting flow with state '" + _currentState.nodeName +
            "'", "Flow");
      }

      PubSub.publish(Constants.events.kFlowTransition + "." +
         _context, {
         "name": _name,
         "id": _id,
         "nodeName": next,
         "stateDef": _currentState,
         "metaData": _metaData,
         "modal": !!_currentState.modal
      });

      return true;
   }

   //--------------------------------------------------------------------------
   // Function: _run
   //  Expects the current node of the flow (_currentState) to be set before entering this function.
   //  Run the state-machine to the next logical VIEW
   //  Does not return anything, just advances the currentState to the next
   //   logical view, flow or end state, executing actions along the way.
   //  If we're currently on one of those states, cool, just return.
   //--------------------------------------------------------------------------
   function _run(respToController) {

      if (!_currentState) {
         _setErrorState("JSFLow._run - No current state: ");
         respToController();
         return;
      }
      if (!_currentState.state_type) {
         _setErrorState("No 'state_type' defined for '" + _currentState.nodeName + "'");
         respToController();
         return;
      }

      const type = _currentState.state_type;

      if (type == Constants.flow.kViewState) {
         const modal = !!_currentState.modal;
         if (!modal) {
            _lastViewState = _currentState;
         }
      }
      if (type == Constants.flow.kViewState ||
         type == Constants.flow.kFlowState ||
         type == Constants.flow.kEndState) {
         // Nothing to run to.
         respToController();
      }

      // Run actions through to completion
      else if (type == Constants.flow.kActionState) {
         let _actionResponse = '_x_null';
         const _actionState = _currentState;
         let _waitingOnTimer = false;

         // if there is an indicator to show a messge while the action runs
         const showMessage = _currentState.showMessage;
         if (showMessage) {
            // inject a special node into the flow to that will show the message
            // and navigate to it
            // then wait for either the timer to expire of the action to return
            // when it does, perform the navigation of the action.
            _injectMessageNode(_currentState);
            _currentState.transitions.__action_show_message__ = "__ACTION_SHOW_MESSAGE__";
            _setCurrent("__action_show_message__");
            _run(respToController);

            // If there is a timeer, set it and wait to navigate
            // the action response will be updated from _x_null to the actual value
            // when the action returns, we'll use this to indicate if we should navigate
            // when the timer expires, or wait until the action completes
            if (showMessage.minTime) {
               _waitingOnTimer = true;
               setTimeout(() => {
                  _waitingOnTimer = false;
                  if (_actionResponse != '_x_null') {
                     _setCurrent(_actionResponse);
                     if (!_errorStatus) {
                        _run(respToController);
                     }
                     else  // error, just return
                     {
                        _logError(_errorStatus.msg);
                        respToController();
                     }
                  }

               }, showMessage.minTime);
            }

         }


         _runAction(_actionState, (response) => {
            if (!_errorStatus) {
               // turn all responses to Strings
               _actionResponse = "" + response;


               // Based on the result of the action, advance the State-machine to the next state
               // and run again
               if (!_waitingOnTimer) {
                  _setCurrent(_actionResponse);
                  if (!_errorStatus) {
                     _run(respToController);
                  }
                  else  // error, just return
                  {
                     _logError(_errorStatus.msg);
                     respToController();
                  }
               }

            }
            else {
               respToController();
            }

         });
      }
      else {
         _setErrorState("Invalid State Type: " + type + " in " + _currentState.name);
         respToController();
      }

   }


   //--------------------------------------------
   // Run a javascript action
   //
   //--------------------------------------------
   function _runAction(node, callback) {
      let act = node.ref;
      const exp = node.exp;

      if (act) {
         // convert references to FLOW_SCOPE to the actual flowscope instance
         act = _resolveFlowValue(act);

         // auto-invoke an async call to execute the action.
         /* eslint-disable-next-line no-inner-declarations */
         (async function __executeAction() {
            const profiler = new Profiler("Action", {action: act});
            profiler.start();
            await executeStringAsFunction(act)
               .then((response) => {
                  profiler.end("executeTime");
                  profiler.record();
                  callback(response)
               })
               .catch((error) => {
                  profiler.end("error");
                  profiler.record();
                  _setErrorState(error);
                  callback();
               })
         })();
      }
      else if (exp) {
         // convert references to FLOW_SCOPE to the actual flowscope instance
         const resp = _resolveFlowValue(exp);
         Logger.info("Flow: - " + _name + " Executing expression '" + exp + "' response: '" + resp + "'", "Flow");
         callback(resp);
      }
      else {
         return _setErrorState("Invalid ACTION node - missing ref or exp");
      }
   }

   //---------------------------------------------------------
   // Function: getFlowInfoObj
   // Get an object that represents the information about the current flow
   //---------------------------------------------------------
   function _getFlowInfoObj() {
      return {"name": _name, "metaData": _metaData, "options": _options, "id": _id};
   }


   // -------------------------------
   // Function: _setErrorState
   // publish error with message
   //
   // Parameters:
   //   msg - the message of the error
   // -------------------------------
   function _setErrorState(msg) {
      _errorStatus = new LogObj(`Flow Object: ${_name} - ${msg}`, "Flow");
   }

   // -------------------------------
   // Function: _logError
   // _logError error with message
   //
   // Parameters:
   //   msg - the message of the error
   // -------------------------------
   function _logError(msg, throwEx) {
      Logger.error(`Flow Object: ${_name} - ${msg}`, "Flow")
      if (throwEx) {
         throw(new Error(`Flow Object: ${_name} - ${msg}`));
      }
   }

   /* -------------------------------
    // Function: _createResponse
    // construct the response
    {
    state_type, // type of current node
    value, // navigation value from the current node
    data, // any data specified in the current node
    options,
    inputVars,
    modal,
    autoNav
    error = null; // will be populated with X.Exception if there is an error;
    }
    // -------------------------------
    */
   function _createResponse() {
      const response = {};

      // Check to see if we're in an error state;
      if (_errorStatus) {
         response.state_type = Constants.flow.kErrorState;
         response.error = _errorStatus;
         return response;
      }
      response.state_type = _currentState.state_type;
      switch (_currentState.state_type) {
         case Constants.flow.kFlowState:
         case Constants.flow.kViewState :
            response.value = _currentState.ref;
            response.options = _currentState.options;
            response.modal = _currentState.modal;
            response.autoNav = _currentState.autoNav;
            response.inputVars = _resolveInputVars(_currentState.inputVars);
            response.metaData = _currentState.metaData;
            break;
         case Constants.flow.kActionState:
            response.value = _currentState.ref;
            break;
         case Constants.flow.kEndState :

            if (_currentState.outcome) {
               response.value = _resolveFlowValue(_currentState.outcome);
            }
            else {
               _setErrorState("Node " + _currentState.nodeName + " does not have an outcome");
               response.error = _errorStatus;
            }
            break;
      }

      // Turn ALL responses into Strings
      if (typeof (response.value) !== "string") {
         response.value = String(response.value);
      }

      // Set output here
      response.outputVars = null;
      response.data = _resolveInputVars(_currentState.data);
      response.nodeName = _currentState.nodeName;

      return response;
   }

   function _resolveInputVars(iv) {
      // Resolve any inputvars
      const out = {};
      if (iv) {
         _.each(iv, (val, name) => {
            out[name] = (_.isString(val)) ? (_resolveFlowValue(val)) : val;
         });
      }
      return out;
   }

   // Resolve dynamic data as well as substituting in the current flowscope name if it exists in the value
   function _resolveFlowValue(inval) {
      let tmp = JSON.stringify(inval);
      tmp = tmp.replace(Constants.scopes.kFlowScope, _instance.getflowScopedModelName());
      tmp = JSON.parse(tmp);
      return getDataResolver().resolveDynamicData(tmp);
   }

   function _injectMessageNode(currentNode) {
      const bind = currentNode.showMessage.message;
      if (bind) {
         _.each(bind.data || {}, (val, key) => {
            _defaultDataApi.setDataVal(bind.model, key, _resolveFlowValue(val));
         });
      }
      _flowDef.__ACTION_SHOW_MESSAGE__ = {
         state_type: "VIEW",
         history: "never",
         transitions: currentNode.transitions,
         ref: currentNode.showMessage.ref,
         data: {
            messageNode: true
         }
      };
   }

   return _instance;
}

