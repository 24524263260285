import XCore from "src/core/X-core";
import _ from 'src/core/libs/underscore-1.6.custom';
import defaultDataOptions from "src/data/config/options";
import Constants from 'src/data/constants';
import DataResolver from 'src/data/components/DataResolver';
import Registry from 'src/data/registry/dataRegistry';
import {attach, detach} from "src/data/bindings/bindingAPI";
import DataModel from "src/data/model/DataModel";
import Schema from "src/data/schema/Schema";
import dataAPI from './dataAPI';

// Import files that do set up
import objectExtensions from 'src/core/utils/objectExtensions'; /* eslint no-unused-vars: 0 */
import jqTextbinder from 'src/data/jqExtensions/jqTextBinder'; /* eslint no-unused-vars: 0 */
import jqDatabinder from 'src/data/jqExtensions/jqDataBinder'; /* eslint no-unused-vars: 0 */

/**
 * Data Model and Binding functionality
 *
 * Only add the class methods that will be used in this project.
 * The Data APIs will be added to the prototype and can be added to other projects prototypes if desired
 */
export default class XinchData extends XCore {

   constructor () {
      super(defaultDataOptions);

      // export some common utilitities
      this.DataModel = DataModel;
      this.Schema = Schema;
      this.Registry = Registry;

      // Register our default data resolver
      Registry.registerInterface(Constants.interfaces.kDataResolver, new DataResolver(), true);

      // Register/override the default data API that other packages may be using
      Registry.registerComponent(Constants.components.kDefaultDataApi, {
        ...dataAPI
      }, true);

   }

   bindData () {
      attach.apply(this, arguments)
   }

   detachData () {
      detach.apply(this,arguments)
   }
}

// Add our extenalized / reusable APIs to our Data Prototype
_.each(dataAPI, (impl, key) => {
  XinchData.prototype[key] = impl;
});

