import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';
import LogObj from "src/core/logging/LogObj";
import PubSub from 'src/core/pubsub';
import uuid from 'src/core/utils/UUID';

import Constants from 'src/flow/constants'
import FlowController from 'src/flow/engine/FlowController';
import FlowNavigationObject from 'src/flow/engine/FlowNavigationObject';

// Create a singleton flow controller
// The returned API will be the API for the flow module
export default function flowAPI() {

   const _api = {
      /**
       *  Start up the Flow controller and return the first view (reference) to the user
       *
       *  @param flowName [string]  - name of the flow reference to load
       *  @param  args [object]      - [optional] all arguments are optional
       *                  * inputVars [object] - name value pairs to be injected into the flow. Will be available via the <span>F</span>LOW_SCOPE model
       *                  * complete : [function] - callback when flow is finished
       *                  * error : [function] - callback if the flow (or contained subflows) errors out
       *  @param callback [function] - response object that specifies the first page reference
       *
       *  @return : none
       */

      startFlow: function (flowName, args, callback) {
         args = args || {};

         // if we're already running, clean up the current controller before starting a new one
         if (_flowController) {
            Logger.info("Starting flow with existing controller - deleting current flow", "FlowController")
            _onControllerEnd();
         }


         // start a new controller
         _flowController = new FlowController({
            context: _id,
            onEndCB: function (resp) {
               _onControllerEnd(resp);
               if (_.isFunction(args.complete)) {
                  args.complete(resp);
               }
            },
            onErrorCB: function (resp) {
               _onControllerError(resp);
               if (_.isFunction(args.error)) {
                  args.error(resp);
               }
            }
         });

         // jump to the first page
         _api.doJump(flowName, args.inputVars, callback);

      },

      /**
       * Advance to the next view state in the flow
       *  - flow Controller will execute any actions and return to next view (reference)
       *
       *  @param response - [string] navigation value to pass to the controller
       *  @param callback [function] - response object that specifies the first page reference
       *
       *  @return : none
       */
      doNext: function (response, callback) {
         if (_isBusy) {
            return _logError("doNext: flow controller is busy");
         }
         if (!_flowController) {
            return _logError("doNext: Flow Controller not started ");
         }

         _isBusy = true;

         _flowController.getNextView(response, (flowResp) => {
            _isBusy = false;
            _autoNavHandler.handleAutoNav(flowResp.autoNav, callback);
            callback(flowResp);
         });

      },

      /**
       * Jump to another state, possibly in a different flow
       *
       *  @param path - [string] path value to pass to the controller ('~' delimited set of nodenames)
       *  @param inputVars - [object] collection of values to be injected into the FLOW_SCOPE
       *  @param callback [function] - response object that specifies the first page reference
       *
       *  @return : none
       */
      doJump: function (path, inputVars, callback) {
         if (!path) {
            return _logError("doJump: No path specified");
         }
         if (!_flowController) {
            return _logError("doNext: Flow Controller not started ");
         }

         if (arguments.length == 2) {
            callback = inputVars;
            inputVars = {};
         }


         if (_.isString(path)) {
            path = path.split(Constants.flow.kNavigationSeperator);
            _.each(path, (itm, idx) => {
               if (0 === idx) {
                  path[idx] = new FlowNavigationObject(itm, inputVars);
               }
               else {
                  path[idx] = new FlowNavigationObject(itm);
               }
            });
         }

         _isBusy = true;

         _flowController.navigateTo(path, (flowResp) => {
            _isBusy = false;
            _autoNavHandler.handleAutoNav(flowResp.autoNav, callback);
            callback(flowResp);
         });
      },

      //-------------------------------------------
      // Function: getCurrentFlowVariable
      // get a flow scoped variable out of the current flow
      //  - may return null or undefined
      //
      // Parameters:
      //   name - the name of the variable to get
      //-------------------------------------------
      getCurrentFlowVariable: function (name) {
         if (_flowController) {
            return _flowController.getCurrentFlowVariable(name);
         }
      },

      //-------------------------------------------
      // Function: getCurrentFlowScopeName
      // get the name of the current flow scope
      //  - may return null or undefined
      //
      //-------------------------------------------
      getCurrentFlowScopeName: function () {
         if (_flowController) {
            return _flowController.getCurrentFlowScopeName();
         }
      }
   };

   //================================
   //   PRIVATE
   //================================
   const _id = uuid(true);
   let _flowController,
       _isBusy = false;

   //------------------------------------------------
   // Clean up flow controller and let anyone know that the flow is done
   function _onControllerEnd(flowResponse) {
      _flowController = null;
      _isBusy = false;

      // publish an event that we're done
      PubSub.publish(Constants.events.kFlowEndController, {response: flowResponse});
   }

   //------------------------------------------------
   // Clean up flow controller and let anyone know that there was an error
   function _onControllerError(flowResponse) {
      _flowController = null;
      if (flowResponse.error instanceof LogObj) {
         Logger.error(flowResponse.error);
      }
   }

   // -------------------------------
   // Function: _logError
   // publish error with message
   //
   // Parameters:
   //   msg - the message of the error
   // -------------------------------
   function _logError(msg, ex) {
      Logger.error(`Flow Error: ${msg}`, 'Flow API', ex);
   }

   // Manage autoNavigation instructions
   // either auto advance the flow controller in a specified time
   // or listen to an event to advance the flow controller
   const _autoNavHandler = (function () {
      const __handler = {
         handleAutoNav: function (autoNavOptions, callback) {
            // if we still have a registered event handler when the next page comes in,
            // remove it (ya, it can happen)
            if (__registeredAutoNav.navEvent) {
               PubSub.unsubscribe(__registeredAutoNav.navEvent, __doAutoNav, __handler);
            }
            if (__timer) {
               clearTimeout(__timer);
            }

            __registeredAutoNav = _.clone(autoNavOptions || {});

            if (__registeredAutoNav.navEvent) {
               PubSub.subscribe(__registeredAutoNav.navEvent, __doAutoNav, __handler);
            }
            else if (__registeredAutoNav.time) {
               __timer = setTimeout(() => {
                  __doAutoNav(callback);
               }, __registeredAutoNav.time);
            }
         }
      };
      let __registeredAutoNav = {},
          __timer             = null;

      function __doAutoNav(callback) {
         _api.doNext(__registeredAutoNav.nav, callback);
      }

      return __handler;
   })();

   return _api;
}


