export default {
   flow: {
      flowResolverOptions: {
         pathToFlows: {
            "default": "flows"
         },
         aliasMap: {
            "*": ".json"
         },
         cacheParam: null
      }
   }
};