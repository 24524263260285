/*
* data module options --------------------------
*/
export default {
    data : {
        // what DOM events to listen to on text input elements
        dataBindEvent: ["blur"], // Add 'keyup' to get as you type data-binding

        defaultTextForNullModelValue: "", // Value to show in bound text when the model value is null. - set it to something like DEBUG if you want to see bugs on your side

        // don't perform text binding to text inside these elements
        dontBindTextInNodeList: ["script", "code"],

        autoCreateModels: true, // create models if they are referenced, if false, the application will enforce the creation of models explicitly before using
        defaultModelClass: "DataModel", // default model class to use to create models defined in flow definitions


        // When binding, Xinch will convert the attributes in this list to the non data- version of the attribute
        // For example, data-class will be converted to just class
        attributeConversionList: [
            'data-class', 'data-style', 'data-value', 'data-src', 'data-href'
        ],

        visibilityFunction: null,
        disabledFunction: null
    }
};