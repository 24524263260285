export default {
    // validation module options --------------------------
    validation : {
        tooltipPosition : 'top', // position of tooltip relative to input.  supported values: 'top', 'bottom', 'right'
        hideOnFocus : false, // hide the tooltip when input field gets focus
        showOnlyOne : false, // show only one error tooltip
        showMultipleErrorsPerInput : false, // if there is more than one error, show them all
        validateOnBlur : true, // Perform validation on blur of an element
        suppressErrors : false, // Just validate and return the result, but don't show any errors messaging
        validateOnBack : false, // No validation if the customer hits back, keys off of "back" or your viewportHistory autoBackNavigationEvent
        validateOnJump : false, // No validation if the customer jumps in navigation
        useValidator : true // use the validation functionality
    },
    formatter : {
        useFormatter : true, // use the autoformatting functionality
        formatEvent : ["keyup", "blur", "paste"], // Android 2.x bug results in erroneous input when formatting after each key, we'll fix in the formatter code
        ignoreKeyCodes : [9, 16], // tab, shift
        autoFormat : false
    }
};
