import _ from 'src/core/libs/underscore-1.6.custom';
import coreOptions from './options';

import Constants from 'src/core/constants';
import Logger from 'src/core/logging';
import PubSub from 'src/core/pubsub';
import {mergeObjects, getPathValue} from "src/core/utils/objectUtils";

class Config {

    constructor () {
        this._config = {...coreOptions};
    }

    addXinchOptions (options) {
        this._config = mergeObjects(this._config, options);
    }

    /**
     * setOption
     *      Set an option only at the top level
     * @param key - option key to set
     * @param value - value to set
     *
     */
    setUserOption (key, value) {
        //const allowNewProperties = (key === "viewportOptions");
        if (!Object.keys(this._config).includes(key)) {
            Logger.warn(`Invalid Option: " ${key} " - ignoring`, 'Config');
            return;
        }
        if (_.isArray(this._config[key])) {
            this._config[key] = _.union(this._config[key], value);
        }
        else if (_.isPlainObject(value)) {
            this._config[key] = mergeObjects(this._config[key], value/*, allowNewProperties*/);
        }
        else {
            this._config[key] = value;
        }
        PubSub.publish(`${Constants.events.kOptions}.${key}`, value);
    }

    get (path, defaultValue) {
        return getPathValue(path, this._config, defaultValue);
    }


    /**
     * FOR TESTING ONLY
     */
    __reset () {
        this._config = {...coreOptions};
    }
}

export default new Config();