import Config from "src/core/config/Config";

/* eslint no-console: 0 */
export function logToConsole(message, logObj) {

   // if we don't allow console logging, get out cheap
   if (!Config.get('logging.logToConsole')) {
      return;
   }

   const msg = `[${logObj.logType}] ${logObj.message}`;
   if (console[logObj.logType]) {
      console[logObj.logType](msg);
   }
   else {
      console.log(msg);
   }
}