import {addModel, getModel, getModelGroup, getModels, removeModel, addModelToGroup} from "src/data/utils/modelAccessor";
import {setDataVal, getDataVal, getAllDataInModel, getAllData, unsetDataVal} from "src/data/utils/dataAccessor";
import {loadSchema} from "src/data/loaders/schemaLoader";

export default {
   addModel,
   getModel,
   removeModel,
   setDataVal,
   getDataVal,
   unsetDataVal,
   getAllDataInModel,
   getAllData,
   loadSchema,
   getModelGroup,
   addModelToGroup,
   getModels
}