
const Http = {
    /**
     * Make a GET request to the server
     *
     * @param url
     * @param options - optional object containing  headers and other fetch options
     * @returns {promise}
     */
    get(url, options) {
        return _send('GET', url, undefined, options);
    },

    /**
     * Make a POST request to the server
     *
     * @param url
     * @param data (JSON object
     * @param options - optional object containing  headers and other fetch options
     */
    post(url, data, options) {
        return _send('POST', url, data, options);
    },

    /**
     * Make a PUT request to the server
     *
     * @param url
     * @param data (JSON object)
     * @param options - optional object containing  headers and other fetch options
     */
    put(url, data, options) {
        return _send('PUT', url, data, options);
    },

    /**
     * Make a DELETE request to the server
     *
     * @param url
     * @param options - optional object containing  headers and other fetch options
     */
    delete(url, options) {
        return _send('DELETE', url, undefined, options);
    },

    /**
     * Make a HEAD request to the server
     *
     * @param url
     */
    head(url) {
        return fetch(url);
    },

    /**
     * Upload a file to the server
     * @param url
     * @param formData - FormData object
     * @param options - optional object containing  headers and other fetch options
     */
    upload(url, formData, options = {}) {
        options = {
            formData: true,
            ...options
        };
        return _send('POST', url, formData, options)
    }

};
export default Http;

/**
 * Make the remote request
 *
 * @param type - GET, POST, PUT, DELETE
 * @param url
 * @param postData
 * @param options
 * @returns promise
 * @private
 */
function _send (type, url, postData, options = {}) {
    options.headers = options.headers || new Headers();
    if (!options.formData) {
        options.headers.append('Accept', options.accept ? options.accept : 'application/json');
        options.headers.append('Content-Type', options.contentType ? options.contentType : 'application/json');
    }

    return new Promise((resolve, reject) => {
        fetch(url, {
            method : type,
            body : options.formData ? postData : JSON.stringify(postData),
            credentials : options.credentials || 'same-origin',
            ...options
        })
            .then((response) => {
                // check for failed requests (fetch will return non 200s here too, and not in the catch)
                return response
                    .text()
                    .then((text) => {
                        /*
                         * turn text into JSON
                         * to handle the case where server returns nothing, i.e. for logging
                         */
                        let responseData;
                        if (options.json === false) {
                            responseData = text;
                        }
                        else {
                            responseData = text ? JSON.parse(text) : {};
                        }

                        return {
                            data : responseData,
                            statusCode : response.status, // http status code
                            statusText : response.statusText, // http status text
                            ok : response.ok // non 200
                        };
                    })
                    .catch(() => {
                        return {
                            data : {},
                            statusCode : response.status,
                            statusText : response.statusText,
                            ok : response.ok
                        };
                    });
            })
            .then((resp) => {
                // Check that the fetch request response status is good
                const err = _handleRequestErrors(url, resp);
                if (err) {
                    reject(new Error(err));
                }
                else {
                    resolve(resp.data);
                }
            })
            .catch((err) => {
                // Failure
                reject(err);
            });
    });
}

/**
 * Fetch will not fail if the request fails.  It will return successfully, but with a response of not OK.
 * So we need to create a little helper to extract this
 *
 * @param url // for logging purposes
 * @param response
 * @returns {response} or throws
 * @private
 */
function _handleRequestErrors (url, response) {
    let errMsg;
    if (!response.ok || !(response.statusCode >= 200 && response.statusCode < 300)) {
        errMsg = `HTTP ${response.statusCode} ${url} ('${response.statusText}')`;
        // Try to get response data if present
        let responseData = response.data;
        if (responseData) {
            // Try to stringify
            try {
                responseData = JSON.stringify(responseData);
            }
            catch (e) {
                /*do nothing */
            }
            errMsg = `${errMsg} ('${responseData})`;
        }
    }
    return errMsg;
}
