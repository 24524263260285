/* X.loaders.JSLoader
 * 	This is a singleton javascript class that
 *  provides functionality for dynamically loading javascript files into the DOM
 *
 *   -- This class loads the JS file ASYNCRONOUSLY.
 *	 -- It will not load a file if it is already in memory
 *   -- NOTE: Safari webkit has some wierdities dynamically evaling a function directly into the DOM
 *     - some notes for creating dynamicly loaded js files for safari
 *        - dont use use the 'var' keyword when defining variables
 *        - use the  foo = function () ...  instead of function foo () to define functions
 *
 */
import $ from 'src/core/libs/zepto-1.1.3.custom';
import http from 'src/core/http/http';
import Logger from 'src/core/logging';

class JSLoader {

    /**
     * Dynamically load a javascript file
     *    - if file is already in memory, it will not load it again
     *    - will publish an exception if the file cannot be found or if the file cannot be parsed as a valid script
     *
     * @param fullPath – URI to find the javascript file
     *
     *@returns promise
     */
    load (fullPath) {
        return http.get(fullPath, {json : false})
            .then((code) => {
                $.globalEval(code);
            })
            .catch((ex) => {
                Logger.error("Failed to execute loaded script: " + fullPath, "JS Loader", ex);
                throw(ex);
            });
    }
}
export default new JSLoader();


