/**
 * Interface:
 * UIComponent
 *
 * @interface
 *
 * Implementing classes will override the 'create' function
 * which knows how to render the control and returns the X.$ element
 *
 * Note : The create function MUST return a X.$ element that represents the widget
 *
 */
import Logger from 'src/core/logging';

export default class UIComponent{

    // create a jquery DOM element that represents the component
    // Return the X.$ element
    create  (/*data*/) {
        Logger.error("Must implement 'create' function: ", "UIComponent")
    }

    // run the equation expressed by the arguments.
    update (/*data*/) {
        Logger.error("Must implement 'update' function: ", "UIComponent")
    }

    /**
     * Convenience method to convert HTML to a dom element.
     * @param html
     * @returns {*|Node}
     */
    htmlToElement(html) {
        const template = document.createElement('template');
        html = html.trim(); // Never return a text node of whitespace as the result
        template.innerHTML = html;
        return template.content.firstChild;
    }
}
