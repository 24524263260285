/**
 * @class X.components.TemplateEngineUnderscore
 * @implements X.view.I_TemplateEngine
 *
 * Receives a X.$ element and template. The element can have data specified in the data-template-options attribute.
 * X._ is used to compile the template, after which it's passed the data and the resulting html is inserted into the target element.
 *
 * TODO: The compiled templates can be cached for potential performance improvement on slow devices.
 * That might be helpful especially with complex templates (initial profiling on a desktop with simple templates did not show a significant speed benefit.)
 */
import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';

export default function TemplateEngineUnderscore () {
    const _templateCache = [];

    this.process = function ($el, $template, data) {
        try {
            const template = $template.html();
            if (!_templateCache[template]) {
                _templateCache[template] = 1;
            }
            const templateFunction = _.template(template);  // TODO: in some cases it might be worth caching the compiled template
            $el.html(templateFunction(data));
        }
        catch (ex) {
            Logger.error(ex.message, "TemplateEngineUnderscore", ex);
        }
    };
}

