// class: JSONSerializer
// about:
// Convert a javascript object to a string for sending over the wire as a message
import Logger from 'src/core/logging';
import _ from 'src/core/libs/underscore-1.6.custom';
import visit from 'src/core/utils/visit';
import {getDataResolver} from "src/core/utils/getDataResolver";
import decodeURIComponentSafe from "src/core/utils/decodeURI";

// -------------------------------
// Function: toString
// Convert Object to String
//
// Parameters:
//    obj - the object
// -------------------------------
export function toString(obj) {
   return _.isString(obj) ? obj : JSON.stringify(obj);
}

// -------------------------------
// Function: toJSON
// Convert String to Object
//  - nonstrict allows for input that does not conform strictly to the JSON standard
//    i.e. single quotes or no quotes around names
//
// Parameters:
//    jsonString - the JSON string
//    nonStrict - boolean value to allow for non-strict parsing, i.e. allow single quotes or no quotes around keys.
//    decodeStrings - boolean value to indicate to recursively iterate through the object decoding strings that may have been encoded
//
// Returns
//  JSON object if passed in string is valid
//  empty object if passed in string is invalid
//  the passed in string if it is not an string or empty
// -------------------------------
export function toJSON(jsonString, nonStrict, decodeStrings) {

   if (!_.isString(jsonString)) {
      return jsonString;
   }

   let json = {};

   // Make sure leading/trailing whitespace is removed (IE can't handle it)
   jsonString.trim();

   try {
      // Attempt to parse using the native JSON parser first
      if (!nonStrict) {
         json = JSON.parse(jsonString);
      }
      else {
         json = (new Function("return " + jsonString))();  // jshint ignore:line
      }
   } catch (ex) {
      Logger.error(`Invalid JSON\n ${jsonString}`, 'jsonSerializer', ex);
   }

   if (decodeStrings) {
      visit(json, (value, key, parentObject) => {
         if (_.isString(value)) {
            parentObject[key] = decodeURIComponentSafe(value);
         }
      })
   }
   return json;

}

/**
 * Turn the string into its primary
 *   i.e. "{'foo': 'bar'}" into an object
 *        "["foo"]" into an array,
 *        "true" into true
 *        etc.
 * @param str
 * @param outModelRefs
 * @param context
 * @returns {*}
 */
export function convertStringToJSPrimitive(str, outModelRefs = {}, context = window) {
   let primitive;

   // Resolve any model references
   str = getDataResolver().resolveDynamicData(str, outModelRefs);

   // try and turn it into a JS primitive object
   try {
      primitive = (new Function("return " + str)).call(context);  // jshint ignore:line
   }
      // will throw if the primitive is a string
   catch (ex) {
      // do nothing, just return what was passed in
      primitive = str;

   }
   if (_.isString(primitive)) {
      primitive = decodeURIComponentSafe(primitive); // unescape anything that may have been put in there
   }
   return primitive;
}


	
   
