const _models = {};

export default {

   addModel: function (name) {
      _models[name] = _models[name] || {};
   },

   removeModel: function (name) {
      if (_models[name]) {
         delete _models[name];
      }
   },

   setDataVal: function (model, key, value) {
      if (!_models[model]) {
         this.addModel(model);
      }
      _models[model][key] = value;
   },

   getDataVal: function (model, key) {
      if (_models[model]) {
         return _models[model][key];
      }
   },

   getAllDataInModel: function (name) {
      return _models[name] || {};
   }
}