import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import Registry from 'src/core/registry/Registry';
import Constants from 'src/validation/constants';
import uuid from 'src/core/utils/UUID';
import css from 'src/validation/css/validation.scss'; /* eslint no-unused-vars: 0 */

export default class BubbleRenderer {

   // -------------------------------
   // Function: _showErrorTooltip
   // show the error tooltip(s)
   //
   // Parameters:
   //    $element - the input element
   //    error = the string error message
   // -------------------------------
   show($element, errors, options) {
      let errorMsg = "";

      if (options.showMultipleErrorsPerInput) {
         _.each(errors, (error) => {
            errorMsg += error + "<br/>";
         });
      }
      else {
         errorMsg = errors[0];
      }

      const targetLeft = $element.offset().left;
      const targetTop = $element.offset().top;
      const targetRight = targetLeft + $element.width();
      let tooltip;
      let tooltipArrowClass;

      $element.addClass('xinch_validationError');
      $element.attr('aria-invalid', 'true');

      const tooltipPosition = options.tooltipPosition || "top";
      const hideOnFocus = options.hideOnFocus;

      switch (tooltipPosition) {
         case 'right':
            tooltipArrowClass = "xinch_errorTooltipArrowLeft";
            break;
         case 'bottom':
            tooltipArrowClass = "xinch_errorTooltipArrowUp";
            break;
         default:
            tooltipArrowClass = "xinch_errorTooltipArrowDown";
            break;
      }

      $element.attr("hasErrorTip", true);
      if ($element.data('errorTooltip') && $element.data('errorTooltip').is(':visible')) {
         tooltip = $element.data('errorTooltip');
         tooltip.html(errorMsg + "<div class='" + tooltipArrowClass + "' />");
      }
      else {
         const errorId = uuid(true);

         tooltip = $("<div class='xinch_errorTooltip' role='alert' id='" + errorId + "'>" + errorMsg + "<div class='" + tooltipArrowClass +
            "'></div></div>");
         //    tooltip.appendTo($element.parent()).hide().fadeIn(120);
         tooltip.appendTo("body").hide().fadeIn(120);
         $element.data('errorTooltip', tooltip);
         $element.attr('aria-describedby', errorId);

      }

      // TODO support errors in modals
      // var m = Registry.getInterface(Constants.interfaces.kModalWindow);
      // if (m && X.getContainingViewport($element) === m.getViewPortId()) {
      //    tooltip.addClass("xinch_modalTip");
      // }

      // always update the tooltip position, cuz sometimes the content of the tooltip gets bigger or smaller
      // so the tooltip container's size adjusts
      switch (tooltipPosition) {
         case 'right':
            tooltip.css("left", targetRight + 14);
            tooltip.css("top", targetTop + ($element.height() - tooltip.height()) / 2);
            break;
         case 'bottom':
            tooltip.css("left", targetLeft);
            tooltip.css("top", targetTop + $element.height() + 9);
            break;
         default:
            tooltip.css("left", targetLeft);
            tooltip.css("top", targetTop - tooltip.height() - 7);
            break;
      }


      // attach event listeners to the error tooltips, when the user clicks on them, the error tooltips should disappear
      tooltip.off("click").on("click", () => {
         this.hide($($element));
      });
      if (hideOnFocus && !$element.prop('clickHandlerSet')) {
         $element.prop('clickHandlerSet', true); // set so we don't double add the click handler
         $element.on("click", () => {
            this.hide($(this));
         });
      }
   }

   // -------------------------------
   // Function: _removeErrorTooltip
   // hide the error tooltip(s)
   //
   // Parameters:
   //    $element - the input element
   // -------------------------------
   hide($element) {
      $element.removeClass('xinch_validationError');

      const tooltip = $element.data('errorTooltip');
      if (tooltip) {
         tooltip.fadeOut(100, () => {
            tooltip.remove();
         });
      }

      $element.removeData('errorTooltip');
      $element.removeAttr('hasErrorTip');
      $element.removeAttr('aria-invalid');
      $element.removeAttr('aria-describedby');
   }

}
