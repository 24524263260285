//---------------------------------------
// class: ObjectExtensions
// about:
// Extend existing javascript primitives
//
//  !!NOTE : Do me a favor and dont extend the Array object
//           It adds enumerable properties to the array that get processed in 'for in' loops
//           Using Object.defineProperty wont work in all IE versions, so lets just stay away from extending the Array class.
//---------------------------------------

//------------------------------------------------
// Function:  Date.prototype.yyyymmdd
// add additional attributes to the Date
//------------------------------------------------
Date.prototype.yyyymmdd = function () {
    const yyyy = this.getFullYear().toString();
    const mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
    const dd = this.getDate().toString();
    return yyyy + (mm[1] ? mm : "0" + mm[0]) + (dd[1] ? dd : "0" + dd[0]); // padding
};

//------------------------------------------------
// Function: String.prototype.toNum
// String: converts a string to num
//
// Parameters:
//    bEmptyAsValid - boolean to treat null and empty as 0
//------------------------------------------------
String.prototype.toNum = function (/*treat null+empty as 0*/bEmptyAsValid) {
    if (this && this.length > 0) {
        // remove all crap
        const isNeg = this.charAt(0) === "-";
        let nVal = this.replace(/[^0-9.]/gi, "");
        nVal = parseFloat(nVal);
        if (isNaN(nVal)) {
            return NaN; // don't return null, isNaN(null) returns false (so is null a Number???)
        }
        else {
            return (isNeg) ? 0 - nVal : nVal;
        }
    }

    return bEmptyAsValid ? 0 : null;
};


//------------------------------------------------
// Function: String.prototype.toDate
// String: try and convert a formatted string to a Date object
// Use the mask to figure out how the value is formatted
// Can return an invalid date if not formatted properly
//------------------------------------------------
String.prototype.toDate = function () {
    // MM/DD/YYYY or MM-DD-YYYY
    let parts = this.match(/(\d{2})[\/|\-](\d{2})[\/|\-](\d{4})/);
    if (parts) {
        return new Date(parts[3], parts[1] - 1, parts[2]);
    }
    // YYYY-MM-DD or YYYY/MM/DD
    parts = this.match(/(\d{4})[\/|\-](\d{2})[\/|\-](\d{4})/);
    if (parts) {
        return new Date(parts[1], parts[2] - 1, parts[3]);
    }
    // MM/YYYY or MM-YYYY
    parts = this.match(/(\d{2})[\/|\-](\d{4})/);
    if (parts) {
        return new Date(parts[2], parts[1] - 1, 1);
    }

    // MM/YY or MM-YY
    parts = this.match(/(\d{2})[\/|\-](\d{2})/);
    if (parts) {
        return new Date("20" + parts[2], parts[1] - 1, 1);
    }

    // YYYY
    if (this.match(/\d{4}/)) {
        return new Date(this, 0, 1);
    }

    return new Date(this); // Try and convert, may be invalid
};

//------------------------------------------------
// Function: String.prototype.removeQuotes
// String: remove surrounding quote marks
//------------------------------------------------
String.prototype.removeQuotes = function () {
    if (this.length === 0) {
        return;
    }
    return this.replace(/^["|'](.*?)["|']$/, "$1");
};

/**
 * Function to add quotes around a string if not there already
 * @param singleQuotes
 * @returns {String|string}
 */
String.prototype.addQuotes = function (singleQuotes = true){
    const s = this;
    const q = singleQuotes ? "'" : '"';
    if (!s.match(/^["|'](.*?)["|']$/)) {
        return `${q}${s}${q}`;
    }
    return s;
}

/**
 * Replace the parts of the string from start to end with the new value
 * @param start
 * @param end
 * @param withWhat
 * @returns {string}
 */
String.prototype.replaceBetween = function(start, end, withWhat) {
    return this.substring(0, start) + withWhat + this.substring(end);
};


