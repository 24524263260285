// class: Options
import Constants from 'src/core/constants/constants';

export default {
   appId: null, // Id of the application (used for logging and exporting functionality back into YOUR namespace)
   appVersion: null, // Version of the application

   // logging options --------------------------
   logging: {
      logToConsole: true,
      alertOnExceptions: true,
      exceptionAlerts: [ //show alert messages for exceptions thrown in Xinch.  For non-prod only I hope
         Constants.log.DEPRECATED,
         Constants.log.ERROR
         /*Constants.log.WARN, */
         /*Constants.log.INFO, */
         /*Constants.log.PROFILE, */
      ]
   }
};



