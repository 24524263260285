import uuid from 'src/core/utils/UUID';

/**
 *====================================================
 *  ui-profiler
 *      - utility class to capture time based events
 *      - has ability to capture multiple events per profile in the case that one event has many interaction that need to be captured
 *
 *  Usage :
 *   Capture a single event
 *      1) new up a ui-profiler and give it a name: const profiler = new Profiler("FOO");
 *      2) start the timer: profiler.start();
 *      3) end the timer: profiler.end("success") or profiler.end("failure");
 *      4) get the information out: profiler.data()
 *
 *   Capture multiple events (example is for multiple service calls)
 *      1) new up a Profiler and give it a name  profiler = new Profiler("FOO")
 *      2) Start the timer: profiler.start();
 *      3) Set up a mark before the first service call - profiler.mark("service1");
 *      4) capture when service 1 returns - profiler.measure("ServiceCall1", "service1")
 *      5) Set up a mark before a second service call - profiler.mark("service2");
 *      6) capture after a service2 call - profiler.measure("ServiceCall2", "service2")
 *      7) capture total time - profiler.end("success") or profiler.end("failure")
 *      8) get the information out: profiler.data()
 *
 *====================================================
 * @export
 * @class Profiler
 */
export default class Profiler {
   /**
    *Creates an instance of Profiler.
    * @param {String} profileName name of the profile default to "Profile"
    * @param {Object} metadata (optional) metadata to pass to the profile
    * @param {Object} options (optional)
    *    {
    *       autoStart: boolean defaults to false
    *    }
    * @memberof Profiler
    */
   constructor(profileName = "Profile", metadata = {}, options = {autoStart: false}) {
      this._profileName = profileName;
      this._marks = {};
      this._data = {};
      this._details = [];
      this._totalTime = 0;
      this._started = false;
      this._ended = false;
      this._uuid = uuid(true);
      this._errors = [];
      this._metadata = metadata;
      this.constants = {
         START: 'start',
         END: 'end'
      };
      if (options.autoStart) {
         this.start();
      }
   }

   /**
    * Start the profiler
    *  - Logs internal error if profiler already started
    *
    * @param {Date} startTime (optional) Date - use this if you want to set a specific start time - default is to use a new Date() or current time snapshot.
    * @returns {undefined} returns void
    * @memberof Profiler
    */
   start(startTime /* if passed in, use it instead of new date */) {
      if (this._started) {
         this._errors.push(new Error(`${this._profileName} [START] already started.`));
      }
      this._started = true;
      this.mark(this.constants.START, startTime);
   }

   /**
    * End the profiler
    *  - Logs internal error if profiler not started
    *
    * @param {String} captureName name to describe the total time.  defaults to 'end'
    * @param {String} details (optional) string information about ending
    * @returns {Profile}
    * {
    *      _metadata : Object - the stuff you passed in the constructor
    *      name: String,
    *      data: Object
    * }
    * @memberof Profiler
    */
   end(captureName = this.constants.END, details) {
      if (!this._started) {
         this._errors.push(new Error(`${this._profileName} [END] called before started.`));
      }
      this.measure(this.constants.START, captureName);
      this._totalTime = this._data[captureName];
      this._ended = true;
      if (details) {
         this._details.push(details);
      }
      return this.data();
   }

   /**
    * Set a mark in the profiler such that sub-profiling can be tracked if you want reporting on fine grained steps
    *  - Logs internal error if mark not specified
    *  - Logs internal error if profiler not started
    *  - Logs internal error if profiler is ended
    *
    * @param {String} markName reporting name
    * @param {Date} inTime probably don't want to use this, but just in case, you can override the start time of this mark
    * @returns {undefined} returns void
    * @memberof Profiler
    */
   mark (markName, inTime = new Date() /* if passed in, use it instead of new date */) {
      if (!markName) {
         this._errors.push(new Error(`${this._profileName} [MARK] name not specified.`));
      }
      else if (!this._started) {
         this._errors.push(
            new Error(`${this._profileName} [MARK] ${markName} cannot mark until started.`)
         );
      }
      else if (this._ended) {
         this._errors.push(Error(`${this._profileName} [MARK] ${markName} cannot mark after ended.`));
      }

      this._marks[markName] = inTime;
   }

   /**
    * Based on a mark, capture the time from that mark until now.
    *  - Logs internal error if profiler not started
    *  - Logs internal error if profiler is ended
    *  - Logs internal error if mark does not exist
    *  - Logs internal error if no mark name passed
    *
    * @param {String} fromMark - name of the mark to capture the measure from
    * @param {String} measureName (optional) - name of to call this information in the output.  If not passed, will use the fromMark
    * @param {Date} toTime (optional) use this time instead of the current time
    * @returns {time} - the specific time for that capture
    * @memberof Profiler
    */
   measure(fromMark, measureName, toTime) {
      const nameToRecord = measureName || fromMark;
      if (!fromMark) {
         this._errors.push(
            new Error(
               `${this._profileName} [MEASURE] invalid capture mark request. No markName specified.`
            )
         );
      }
      else if (!this._started) {
         this._errors.push(
            new Error(
               `${this._profileName} [MEASURE] cannot capture mark '${fromMark}' until started.`
            )
         );
      }
      else if (this._ended) {
         this._errors.push(
            new Error(`${this._profileName} [MEASURE] cannot capture mark '${fromMark}' after ended.`)
         )
      }
      else if (!this._marks[fromMark]) {
         this._errors.push(
            new Error(
               `${this._profileName} [MEASURE] cannot capture mark '${fromMark}' does not exist.`
            )
         );
      }

      this._data[nameToRecord] = (toTime || new Date()) - this._marks[fromMark];

      return this._data[nameToRecord];
   }

   /**
    * Get the name of the profiler that you gave it during construction
    *
    * @returns {String} profiler name
    * @memberof Profiler
    */
   name() {
      return this._profileName;
   }

   /**
    * Get the data associated with this instance of the profiler
    *
    * @returns {Profile}
    * {
    *
    *      _metadata : object - the stuff you passed in the constructor
    *      _errors: [internal error messages]
    *      id : string - unique identifier for the profile
    *      name: string,
    *      totalTime: milliseconds
    *      measures: object
    *      details: [string]
    * }
    * @memberof Profiler
    */
   data() {
      return {
         _metaData: this._metadata,
         _errors: this._errors.length ? this._errors : undefined,
         id: this._uuid,
         name: this._profileName,
         totalTime: this._totalTime,
         measures: this._data,
         details: this._details
      };
   }

}
