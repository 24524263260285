/*
 * class: BaseStrategy
 * Validation Strategy Interface
 */
import $ from 'src/core/libs/zepto-1.1.3.custom'
import _ from 'src/core/libs/underscore-1.6.custom';
import {getDataResolver} from "src/core/utils/getDataResolver";

export default class BaseStrategy {

   // -------------------------------
   // Function: construct
   // construct a new BaseStrategy
   //
   // variables:
   //   allowException - the boolean value to allow alternative formats
   //   maskChar - the default masking character
   // -------------------------------
   constructor(name = "") {
      this.name = name;

      // Define our member variables;
      this.ignoreKeyCodesOnMask = [8, 46, 127];
      this.exceptions = null; // (optional Array) - A list of valid exceptions for this strategy - if no exceptions are applicable, do not override.
      this.allowException = false;
      this.regex = null;
      this.defaultMessage = null; // defaultMessage needs to be null in the base class
      this.validateIfEmpty = false; // perform validation if the field is empty;
      this.allowException = false;
      this.maskChar = "#";
   }


   getError($el, otherMsg) {
      const dv = $el.prop("validate");
      // strategy name is injected during registration of the input strategy (validationRegistry)
      let msg = (dv && dv[this.name] && dv[this.name].options.message) ? dv[this.name].options.message : otherMsg || this.defaultMessage;

      // resolve any model references
      msg = getDataResolver().resolveDynamicData(msg);
      return msg;
   }


   // -------------------------------
   // Function: validate
   // Base functionality tests against exceptions, then regex
   //
   // Parameters:
   //   value - the value to be validated
   // -------------------------------
   validate($el) {
      const value = $el.val();

      // Test against exceptions
      // If exception passes, return no error
      if (this.allowException && _.isArray(this.exceptions)) {
         if (this.containedInExceptions(value)) {
            return;
         }
      }

      // Test against regex
      if (this.regex) {
         const regexp = new RegExp(this.regex);
         if (!regexp.test(value)) {
            // doesn't match the regex
            return this.getError($el, "Invalid Entry");
         }
      }
   }

   // -------------------------------
   // Function: formatAgainstMask
   // format the element value using the elements mask
   //
   // Parameters:
   //   $el - the element's value will be formatted
   //   inVal - the mask format
   //   event - $ event that triggered the formatting
   // -------------------------------
   formatAgainstMask($el, inVal, event) {
      const startLength = inVal.length;
      let endLength = 0,
          mask      = this.mask,
          caretPos  = $el.caret();

      if (_.contains(this.ignoreKeyCodesOnMask, event.keyCode)) {
         return;
      }

      // Test against exceptions
      // if the user input is partially contained in the exception array, exit right away
      if (this.allowException && _.isArray(this.exceptions)) {
         if (this.containedInExceptions(inVal, true)) {
            return;
         }

      }

      //get the current position of the caret
      caretPos = caretPos ? caretPos.end : 0;

      inVal.replace(/\d/g, () => {
         const digit = arguments[0];
         mask = mask.replace(this.maskChar, digit);
         return arguments[0];
      });
      mask = mask.split(this.maskChar)[0];
      endLength = mask.length;

      this.setFormattedValue($el, mask, event, (startLength == endLength) ? caretPos : caretPos + (endLength - startLength));

   }

   setFormattedValue($el, formattedVal, event, caretPos) {
      if ($el.isTextInput()) {
         $el.val(formattedVal);
      }
      else if (!$el.isUserInputElement()) {
         $el.text(formattedVal);
      }

      //Set the caret back to it's position after we lost it by using .val()
      //but only if caretPos has a value.
      // skip this for "blur" events, in order to leave the focus on the new field that received it.
      if (_.isNumber(caretPos) && event && event.type !== "blur") {
         // Give control back to the browser momentarily and set the caret on the next tick
         // IE 11 and Android 2.x bug results in erroneous input when formatting after each key or blur
         setTimeout($.proxy($.fn.caret, $el, caretPos), 0);

      }
   }

   // -------------------------------
   // Function: containedInExceptions
   // if the input string is partially contained in the exception array
   //
   // Parameters:
   //   s - the input string
   //   allowPartial - allow a substring of the exception
   // -------------------------------
   containedInExceptions(s, allowPartial) {
      let valid = false;
      if (this.exceptions) {
         if (s) {
            s = s.toLowerCase();
            s = s.replace(/ /g, "");
         }
         for (let i = 0; i < this.exceptions.length; i++) {
            let itm = this.exceptions[i].toLowerCase();
            itm = itm.replace(/ /g, "");

            if (s === itm) {
               valid = true;
               break;
            }
            else if (allowPartial && itm.indexOf(s) === 0) {
               valid = true;
               break;
            }


         }
      }
      return valid;
   }
}

