/**
 * Class:  FlowResolver
 *
 * @implements {X.flow.I_FlowResolver}
 *
 * About:
 * This class will first resolve the flow reference to a javascript object that represents the flow
 * It will load the object out of a javascript file syncronously
 *
 * Options :
 *
 *      pathToFlows : path to the implementation of flow definitions.
 *                    Can be a string that represents the default path to ALL flows,
 *                    Or a hashmap that has a list of namespaces that map to different locations.
 *                    If you use an hashmap, you must define a "default" key
 *                    I.e.
 *                        pathToFlows:{
 *                                "ns1":"scripts/flows/namespace1flows/",
 *                                "ns1.flows.views":"scripts/flows/other/",
 *                                "default":"scripts/flows/"
 *                        }
 *
 *                    Then in your flow references (in the flow definitions) you reference your flows
 *                    using the namespace in using the following convention.
 *                        <namespace>.<fileReference>
 *
 *                     - everything up to the last '.' is considered a namespace and needs to be resolved in the pathToFlows
 *                     - if there is no namespace (no '.') then the default path will be used
 *
 *                        ns1.flowDef                     will be mapped to scripts/flows/namespace1flows/ (and flowDef will get resolved in the aliasMap)
 *                        ns1.flows.views.flowDef         will be mapped to scripts/flows/other
 *                        flowDef                         will be mapped to scripts/flows  (the default path)
 *
 *
 *
 *      aliasMap    :  map containing the resolution of view references to html file names
 *                     wildcard entry ('*') in the alias map will contain the default extension of the flow names
 *
 *      cacheParam : parameter to append as a query string to each resolved flow reference. can be a string or a function that returns a string.
 *
 *        Note : The item in the aliasMap marked '*' indicates that any view not existing in the map will be mapped directly to their reference name.
 *               The value of the '*' entry specifies the default extension of wildcarded view mappings.
 *
 *               You only need to supply aliasMappings here if your fileAliases do not match exactly to the name of the view.
 *               For example if the name of the fileAlias is page1 and your html file is page1.html, we'll use the wildcard convention, and you don't
 *               need to supply a mapping
 *
 *                        aliasMap:{
 *                          "Pg1":"namespace1/Page1.htm",
 *                          "ns1.Pg2":"Page2.htm",
 *                          "ns2.SubflowPg":"SubflowPage.htm",
 *                          "*":".htm"
 *                        }
 * *
 */

import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';
import http from 'src/core/http/http';
import Config from 'src/core/config/Config';

import Constants from 'src/flow/constants';
import Registry from 'src/flow/registry/flowRegistry';

const component = "Flow Resolver";

export default function () {

   /*
    * Function: resolve
    * resolve the flow
    *
    * Parameters:
    * flowRef - the reference used to find the flow in the map
    *
    * returns promise.  Resolve with definition, reject with Exception
    */
   this.resolve = function (flowRef) {
      if (!_initialized) {
         _init();
      }

      const abTestResolver = Registry.getInterface(Constants.interfaces.kABTestResolver);

      let pathAlias  = "default",
          alias      = flowRef,
          pathToFile = null,
          flow       = null,
          flowFile   = null;


      // parse the pageRef into pathAlias.fileAlias
      const ns = Constants.nameSpaceRegex.exec(flowRef);
      if (ns) {
         pathAlias = ns[1];
         alias = ns[2];
      }
      else if (flowRef !== null) {
         pathAlias = flowRef;
      }

      pathToFile = _pathToFlows[pathAlias];
      if (!pathToFile) {
         Logger.info("Path alias '" + pathAlias + "' not found, using default path", component);
         pathToFile = _pathToFlows["default"];
      }
      if (!pathToFile.match(/\/$/)) {
         pathToFile += "/";
      }

      if (_map[flowRef]) {
         flow = _map[flowRef];
         Logger.info("ALIAS REF: '" + flowRef + "' --> " + flow, component);
      }
      else if (_map[Constants.kWildCard]) {
         flow = alias + _map[Constants.kWildCard]; // wild card entry will have the default extension
         Logger.info("WILDCARD REF: '" + alias + "' --> " + flow, component);
      }
      flowFile = pathToFile + flow;

      const flowABTest = abTestResolver ? abTestResolver.getABTestFlow(flowRef, flowFile) : null;
      if (flowABTest) {
         Logger.info(flowRef + " --> " + flowABTest, component);
         flowFile = flowABTest;
      }

      const queryParam = _.isFunction(_queryParam) ? _queryParam() : _queryParam;
      if (_.isString(queryParam)) {
         const delimiter = flowFile.indexOf("?") >= 0 ? "&" : "?";
         flowFile += delimiter + queryParam;
      }

      // Now that we have a reference to the definition,
      // Load it out of memory, if its been loaded before.
      // Or off the server, if it hasn't
      if (flow) {
         const flowdef = Registry.getFlow(flowRef);
         if (flowdef) {
            Logger.info("Loading flow out of memory: '" + flow + "'", component);
            return Promise.resolve(flowdef);
         }
         else {
            return http.get(flowFile)
                .then((impl) => { // success
                  Registry.registerFlow(flowRef, impl);
                  Logger.info("Loading flow off server: '" + flowRef + "' --> " + flowFile, component);
                  return impl;
               })
               .catch((ex) => {  // fail
                  Logger.error("Failed to load flow: " + flowFile, "Flow Resolver", ex);
                  throw new Error("Failed to load flow: " + flowFile);
               });
         }
      }
      else {
         return Promise.reject(new Error(`Flow Reference: ${flowRef} not found in config`));
      }

   };


   // -- PRIVATE -- \
   //----------------\\
   let _initialized = false,
       _pathToFlows,
       _queryParam,
       _map;

   function _init() {
      const options = Config.get('flow.flowResolverOptions');

      if (!options) {
         Logger.error('missing options', "Flow Resolver")
      }

      if (options.cacheParam) {
         _queryParam = options.cacheParam;
      }

      if (_.isObject(options.pathToFlows)) {
         _pathToFlows = options.pathToFlows;

         /* Looks for a variable ${foo} in the json object value. If found, will resolve the variable by looking for a key with
          * with the variable name and substituting it's value
          *
          * NOTE: This logic does NOT support a circular reference.
          *       It also does NOT support resolving an unresolved variable during the action of substituting.
          */
         for (const key in _pathToFlows) {
            let matchedObj;
            while ((matchedObj = /\$\{(.*?)\}/g.exec(_pathToFlows[key])) !== null) {
               if (!_.isUndefined(_pathToFlows[matchedObj[1]])) {//Test that the property exists
                  const replaceVal = _pathToFlows[matchedObj[1]];
                  _pathToFlows[key] = _pathToFlows[key].replace(matchedObj[0], replaceVal);
               }
               else {
                  Logger.error(`${matchedObj[0]} variable cannot be found`, component);
               }

            }
         }

         if (typeof _pathToFlows["default"] !== "string") {
            Logger.error("pathToFlows must contain a 'default' entry.", component);
         }
      }
      else if (_.isString(options.pathToFlows)) {
         _pathToFlows = {"default": options.pathToFlows};
      }
      else {
         Logger.error("pathToFlows is invalid.  Must be an object or string.", component);
      }

      if (_.isObject(options.aliasMap)) {
         _map = options.aliasMap;
      }
      else {
         Logger.error("aliasMap is invalid.  Must be an object", component);
      }

      _initialized = true;
   }

}


