/*
 *    The main API to the validation module
 */

// Import files that do set up
import jqFormat from 'src/validation/jqExtensions/jqFormatFn'; /* eslint no-unused-vars: 0 */
import jqValidate from 'src/validation/jqExtensions/jqValidationFn'; /* eslint no-unused-vars: 0 */

// Extend the core
import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import XCore from "src/core/X-core";
import {get$} from "src/core/utils/$utils";
import {bindFormatters, bindValidators} from "src/validation/bindings/binder";
import Constants from 'src/validation/constants';
import defaultValidationOptions from 'src/validation/config/options';
import Registry from "src/validation/registry/ValidationRegistry";
import DefaultStrategies from 'src/validation/strategies/DefaultStrategies';
import BubbleRenderer from 'src/validation/components/ValidationBubbleRenderer';
import validationAPI from "src/validation/validationAPI";

export default class XinchValidation extends XCore {

   constructor() {
      super(defaultValidationOptions);

      // Add our default Strategies
      _.each(DefaultStrategies, (strategyObj, name) => {
         Registry.registerStrategy(name, strategyObj, false);
      });

      // Register the default validation renderer
      // Can be overridden by client by calling Xinch.registerInterface
      Registry.registerInterface(Constants.interfaces.kValidationRenderer, new BubbleRenderer());

      // register default validation for other packages
      Registry.registerComponent(Constants.components.kDefaultValidationApi, {
         removeErrorTips: validationAPI.removeErrorTips,
         validateElement: validationAPI.validateElement
      })
   }

   // -------------------------------
   // bind the formatters and validators to the DOM
   //
   // Parameters:
   //    $container - the dom element identifier
   //                  or the Xinch.$ element
   // -------------------------------
   attachValidators($container, options) {
      $container = get$($container);
      if (!$container.length) {
         $container = $('body');
      }
      bindFormatters($container, options);
      bindValidators($container, options);
   }

   /**
    * Remove bindings from the DOM.
    *      Otherwise memory leaks may occur if the DOM is blown away and formatters are still attached to elements
    *
    * @param $container  - the dom element identifier
    *                      or the X.$ element
    */
   detachValidators($container) {
      $container = get$($container);
      $container.unbind();
   }

   /**
    * Set/Override Xinch validation options
    *
    *  {
    *      useValidator : true, // enable/disable the use the validation functionality
    *      tooltipPosition : 'top', // position of tooltip relative to input.  supported values: 'top', 'bottom', 'right'
    *      hideOnFocus : false, // hide the tooltip when input field gets focus
    *      showOnlyOne : false, // show only one error tooltip
    *      showMultipleErrorsPerInput : false, // if there is more than one error, show them all
    *      validateOnBlur : false, // Perform validation on blur of an element
    *      suppressErrors : false // Just validate and return the result, but don't show any errors messaging
    *   }
    *
    * @param opts -  object representing validation options
    */
   setValidationOptions(opts = {}) {
      this.setOptions({validation: opts});
   }

   /**
    * Set/Override Xinch format options
    *
    *   {
    *      useFormatter : true, // enable/disable autoformatting of input elements
    *      formatEvent : ["keyup", "blur", "paste"], // Android 2.x bug results in erroneous input when formatting after each key, we'll fix in the formatter code
    *      ignoreKeyCodes : [9, 16], // tab, shift
    *      formatOnLoad : false  // Future use to autoformat a the value if it is changed via data-binding
    *   }
    *
    * @param opts     -  object representing formatting options
    */
   setFormatOptions(opts = {}) {
      this.setOptions({formatter: opts});
   }

}

// add our APIs to the prototype
_.each(validationAPI, (impl, key) => {
   XinchValidation.prototype[key] = impl;
});


