import Registry from "src/validation/registry/ValidationRegistry";
import _ from "src/core/libs/underscore-1.6.custom";
import $ from "src/core/libs/zepto-1.1.3.custom";
import Constants from "src/validation/constants";
import Logger from "src/core/logging";
import ValidationEngine from "src/validation/engine/ValidationEngine";

export default {
   /**
    * Override an existing or default validation/formatting strategy
    *
    * @param strategyName - strategy name
    * @param strategyObj - instance of X.validation.baseStrategy
    */
   registerInputStrategy(strategyName, strategyObj /*instance of X.validation.baseStrategy*/) {
      Registry.registerStrategy(strategyName, strategyObj);
   },


   /**
    * Override an existing or default validation/formatting strategy
    *
    * @param strategyName - name of an existing strategy
    * @param overrideObj - contains the key(s) and the value(s) of the properties to override
    *  *  I.e.:
    *  { defaultMessage : "Hello there" }
    *  { validate : function () { .... } }
    *
    *  or a combo: {
    *    defaultMessage : "Foo Bar",
    *        format : function ($el, evt ) { .... },
    *        validate : function ($el) { .... },
    *  }
    */
   overrideInputStrategy(strategyName, overrideObj) {
      Registry.overrideStrategy(strategyName, overrideObj);
   },


   /**
    * Register you custom validation renderer
    *
    * @param renderer - instance of your renderer that overrides the show and hide methods
    */
   registerValidationRenderer(renderer) {
      if (_.isObject(renderer) && _.isFunction(renderer.show) && _.isFunction(renderer.hide)) {
         Registry.registerComponent(Constants.interfaces.kValidationRenderer, renderer, true);
      }
      else {
         Logger.error("RegisterValidationRenderer: invalid Renderer - no show or hide function");
      }
   },

   /**
    * Validate a container and its descendant elements
    *
    * @param $container - element id or X.$ object
    * @param options - validation options {showOnlyOne , suppressErrors, showMultipleErrorsPerInput}
    * @returns {boolean} - true iff validation passes
    */
   validateSection($container, options) {
      return ValidationEngine.validateAll($container, options);
   },


   /**
    * Validate an element
    *
    * @param $el - element id or X.$ object
    * @param options - validation options {showOnlyOne , suppressErrors, showMultipleErrorsPerInput}
    * @returns {boolean} - true iff validation passes
    */
   validateElement($el, options) {
      return ValidationEngine.validateField($el, options);
   },

   /**
    * Returns an array of error object - empty array if no errors
    *      {
    *          $el : X.$ element in error
    *          errorList : [] array of error messages
    *      }
    * @param $container
    * @returns {Array|*}
    */
   getErrorList($container) {
      return ValidationEngine.getErrorList($container);
   },

   /**
    * Remove the error tooltips from a container and its descendant elements
    *
    * @param $container - element id or X.$ or jQuery object
    */
   removeErrorTips($container) {
      ValidationEngine.hideErrorTooltips($container);
   },


   /**
    * Does the container have any error tips showing
    * @param $container - element id or X.$ or jQuery object
    * @returns boolean
    */
   hasErrorToolTips($container) {
      return ValidationEngine.hasErrorToolTips($container);
   },

   /**
    * Force show an error message on an element
    *
    * @param $el - element id or X.$ or jQuery object
    * @param msg - error message
    * @param options - validation options
    * @returns {*}
    */
   showErrorMsg($el, msg, options) {
      return ValidationEngine.showErrorMsg($el, msg, options);
   },


   /**
    * Format the element based on the data-format attribute
    *
    * @param $el
    */
   formatElement($el) {
      // check to see if we've already bound the formatter,
      // if not, do so.
      let formatter = $el.data("formatter");
      if (!formatter) {
         formatter = $el.attr("data-format");
         $el.data("formatter", formatter);
      }

      //since the formatter expects an event, send a fake keydown. code 38 is 'up arrow' key
      $el.format($.Event("keydown", {keyCode: 38}));
   }
}