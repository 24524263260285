import Config from 'src/core/config/Config';
import Logger from 'src/core/logging';
import {getModel, getModels} from "./modelAccessor";

/**
 * Set a single data value
 * @param modelName - the name of the model
 * @param key - the name of the model's property to set
 * @param value - the value for the name/value pair
 * @param options - optional object with one or more of the following properties:
 *          autoCreateModel - create the model if it does not exist
 *          silent - boolean to specify that event should not to be sent (default = false)
 *          force  - boolean to set the data even if it's readOnly (default = false)
 *          changed - marks data as having changed, even if the comparison shows otherwise.  Will force a dataChange event
 */

export function setDataVal (modelName, key, value, options = {}) {
   const model = getModel(modelName, Config.get('data.autoCreateModels', !!options.autoCreateModel));
   if (model) {
      model.setDataVal(key, value, options);
   }
   else {
      Logger.error("setDataVal: Model does not exist: " + modelName, "Data API")
   }
}

/**
 * Get a model property value
 * @param modelName - the name of the model
 * @param key - the name of the model's property
 * @return {*} - the value of the model's property
 */
export function getDataVal (modelName, key) {
   let model;
   if (key !== undefined) {
      model = getModel(modelName, Config.get('data.autoCreateModels', true));
      if (model) {
         return model.getDataVal(key);
      }
      else {
         Logger.error("getDataVal: Model does not exist: " + modelName, "Data API")
      }
   }

}

/**
 * UnSet a single data value to undefined
 * @param modelName - the name of the model
 * @param key - the name of the model's property to set
 * @param options - optional object with one or more of the following properties:
 *          silent - boolean to specify that event should not to be sent (default = false)
 */
export function unsetDataVal (modelName, key, options) {
   const model = getModel(modelName);
   if (model) {
      model.remove(key, options);
   }
}

/**
 * Get the internal data representation of our data model (JSON object)
 *
 * @param modelName
 *          name of the model to extract data from
 * @param options
 *          changedDataOnly : <true | false> - get only data that has changed since the dirty flag was cleared
 *          excludeErrors : <true | false> - dont get elements that are in error
 *
 * @returns {*} - JSON Object
 */
export function getAllDataInModel (modelName, options) {
   const model = getModel(modelName);
   if (model) {
      return model.getAll(options);
   }
   else {
      Logger.error("getAllDataInModel: Model does not exist: " + modelName, "Data API")
      return undefined;
   }
}

/**
 * Get all the data in all the models in the system
 * @returns { name: data,... }
 */
export function getAllData() {
   const output = {}
   const models = getModels({fn: () => true}, null, true);
   models.forEach((model) => {
      output[model.getName()] = model.getAll();
   });
   return output;
}
