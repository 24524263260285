/*
 * Pub Sub module
 */
function PubSub() {
   const actionsLog = []; // holds a log of actions performed by pubsub
   const MAX_LOG_LENGTH = 500; // max entries in the actionsLog array
   /**
    * topics will be stored in a table of the form
    *  topics = {
    *      topic : {
    *          token : {
    *              callback : callback,
    *              context : context
    *          },
    *          token : {
    *              callback : callback,
    *              context : context
    *          }
    *      },
    *      topic : {
    *          ...
    *      }
    *  }
    *
    */
   const uuid = '_pubsub_uuid_';
   const ALL_SUBSCRIBING_MSG = '*';
   let topics = {};
   let lastUid = -1;

   function hasKeys(obj) {
      return !!Object.keys(obj).length;
   }

   /**
    *  Pushes new entry into the actionsLog array. It holds only the last MAX_LOG_LENGTH entires
    *  @param { Object } value, object to be push into the actionsLog
    *  {
    *       time: entry stamp,
    *       type: entry type (publish, subscribe etc..),
    *       message: message
    *  }
    */
   function _logEntry(value = {}) {
      setTimeout(() => {
         // make _logEntry async
         const dt = new Date();

         if (actionsLog.length >= MAX_LOG_LENGTH) {
            actionsLog.shift(); // Remove first element
         }
         value.time = dt; /* eslint-disable-line no-param-reassign */
         actionsLog.push(value); // add new actions to the log
      }, 0);
   }

   /**
    * Call a subscriber and catch the error and log it
    *
    * @param subscriber
    * @param message
    * @param data
    * @param metadata
    */
   function callSubscriberWithDelayedExceptions(subscriber, message, data, metadata) {
      // see if there is any 'context' object associated with this subscriber
      try {
         subscriber.callback.call(subscriber.context, data, message, metadata);
      } catch (ex) {
         const str = `PubSub Exception: ${ex.message}  Stack: ${ex.stack}`;
         if (console.error) {
            console.error(str);
         }
         else {
            console.log(str);
         }
         // setTimeout(throwException(ex), 0);
      }
   }

   /**
    * Call a subscriber and blow if it throws an exception.
    *
    * @param subscriber
    * @param message
    * @param data
    * @param metadata
    */
   function callSubscriberWithImmediateExceptions(subscriber, message, data, metadata) {
      subscriber.callback.call(subscriber.context, data, message, metadata);
   }

   function deliverMessage(originalMessage, matchedMessage, data, immediateExceptions, metadata) {
      const subscribers = topics[matchedMessage];
      const callSubscriber = immediateExceptions
         ? callSubscriberWithImmediateExceptions
         : callSubscriberWithDelayedExceptions;

      if (!topics[matchedMessage]) {
         return;
      }

      const subscriberList = Object.keys(subscribers);
      subscriberList.forEach((s) => {
         if (subscribers[s]) {
            callSubscriber(subscribers[s], originalMessage, data, metadata);
         }
      });
   }

   function createDeliveryFunction(message, data, immediateExceptions, metadata) {
      return function deliverNamespaced() {
         let topic = String(message);
         let position = topic.lastIndexOf('.');

         // deliver to the global subscriber
         deliverMessage(message, ALL_SUBSCRIBING_MSG, data, immediateExceptions, metadata);

         // deliver the message as it is now
         deliverMessage(message, message, data, immediateExceptions, metadata);

         // trim the hierarchy and deliver message to each level
         while (position !== -1) {
            topic = topic.substr(0, position);
            position = topic.lastIndexOf('.');
            deliverMessage(message, topic, data, immediateExceptions, metadata);
         }
      };
   }

   function hasDirectSubscribersFor(message) {
      const topic = String(message);
      return !!topics[topic] && hasKeys(topics[topic]);
   }

   function messageHasSubscribers(message) {
      let topic = String(message);
      let found = hasDirectSubscribersFor(topic) || hasDirectSubscribersFor(ALL_SUBSCRIBING_MSG);
      let position = topic.lastIndexOf('.');

      while (!found && position !== -1) {
         topic = topic.substr(0, position);
         position = topic.lastIndexOf('.');
         found = hasDirectSubscribersFor(topic) || hasDirectSubscribersFor(ALL_SUBSCRIBING_MSG);
      }

      return found;
   }

   function _publish(message, data, sync, options) {
      const {immediateExceptions, deliveryCallback, metadata = {}} = options;
      const deliver = createDeliveryFunction(message, data, !!immediateExceptions, metadata);
      const hasSubscribers = messageHasSubscribers(message);

      // function to execute when finished;
      function done(delivered) {
         if (deliveryCallback) {
            deliveryCallback({data});
         }
         return delivered;
      }

      if (!hasSubscribers) {
         return done(false);
      }

      if (sync === true) {
         deliver();
         return done(true);
      }
      setTimeout(() => {
         deliver();
         done(true);
      }, 0);

      return true;
   }

   const API = {
      /**
       * Get debugging information out of the component
       * @returns {{topics, actionsLog:Array}}
       */
      debug: () => {
         return {
            topics, // export for debug info
            actionsLog, // export for debug info,
         };
      },

      /**
       * Publish an event asynchronously
       *
       * @param topic: topic to publish
       * @param data: data to publish (can be anything)
       * @param options:
       * {
       *     immediateExceptions
       *     deliveryCallback: {Function} a callback that will be invoked after msg has been delivered, i.e. all subscribers have been invoked
       *     metadata: {} optional metadata that clients can pass and receive outside of data (e.g.: {remote:true, target: '123'})
       * }
       *
       */
      publish: (topic, data, options = {}) => {
         _logEntry({
            type: 'publish',
            topic,
         });

         return _publish(topic, data, false, options);
      },

      /**
       * Publish an event synchronously
       *
       * @param topic: topic to publish
       * @param data: data to publish (can be anything)
       * @param options:
       * {
       *     immediateExceptions
       *     deliveryCallback: {Function} a callback that will be invoked after msg has been delivered, i.e. all subscribers have been invoked
       * }
       *
       */
      publishSync: (topic, data, options = {}) => {
         _logEntry({
            type: 'publishSync',
            topic,
         });
         return _publish(topic, data, true, options);
      },

      /**
       *  Subscribe to a topic (can be hierarchical in nature)
       *
       *  @param topic: The topic to subscribe to
       *  @param callback: The function to call when a new topic is published
       *               data passed to this function is the data that is published (can be anything)
       *  @param context: context in which to execute the function
       *
       *  @returns token (that can be used for un-subscribing);
       */
      subscribe: (topic, callback, context) => {
         _logEntry({
            type: 'subscribe',
            topic
         });
         if (typeof callback !== 'function') {
            return false;
         }

         //------------------------------
         // Inner function to handle the actual
         // subscribing in the event that the
         // passed in topic is an array
         //------------------------------
         function _subscribe(_topic, _callback, _context) {
            // topic is not registered yet
            topics[_topic] = topics[_topic] || {};

            /*
             * forcing token as String, to allow for future expansions without breaking usage
             * and allow for easy use as key names for the 'topics' object
             */
            const token = uuid + String(++lastUid);

            // Add an entry into the topics table
            topics[_topic][token] = {
               callback : _callback,
               context: _context || _callback
            };

            // return token for unsubscribing
            return token;
         }

         if (Array.isArray(topic)) {
            const tokens = [];
            topic.forEach((_topic) => {
               tokens.push(_subscribe(_topic, callback, context));
            });
            return tokens;
         }
         else {
            return _subscribe(topic, callback, context);
         }
      },

      /**
       *  Subscribe to a topic, and immediately un-subscribe once the first event is received
       *
       *  @param topic: The topic to subscribe to
       *  @param callback: The function to call when a new topic is published
       *               data passed to this function is the data that is published (can be anything)
       *  @param context: context in which to execute the function
       *
       */
      once: (topic, callback, context) => {
         _logEntry({
            type: 'once',
            topic
         });
         const token = API.subscribe(topic, (...args) => {
            // before func apply, unsubscribe message
            API.unsubscribe(token);
            callback.apply(context, args);
         });
         return API;
      },

      /**
       *  Subscribe to all events
       *
       *  @param callback: The function to call when a new topic is published
       *  @param context: context in which to execute the function
       *
       */
      subscribeAll: (callback, context) => {
         _logEntry({
            type: 'subscribeAll',
            topic: ''
         });
         return API.subscribe(ALL_SUBSCRIBING_MSG, callback, context);
      },

      /**
       *   Clears all subscriptions
       */
      clearAllSubscriptions: () => {
         _logEntry({
            type: 'clearAllSubscriptions',
            topic: ''
         });
         topics = {};
      },

      /**
       * Public: Clear subscriptions for a the topic
       */
      clearTopicSubscriptions: (topic) => {
         _logEntry({
            type: 'clearTopicSubscriptions',
            topic
         });

         const topicList = Object.keys(topics);
         topicList.forEach((m) => {
            if (topics[m] && m.indexOf(topic) === 0) {
               delete topics[m];
            }
         });

         return false;
      },

      /**
       * Clear all subscriptions for a given context
       * @param context
       *
       * @returns {*|boolean|*}
       */
      clearContextSubscriptions: (context) => {
         _logEntry({
            type: 'clearContextSubscriptions',
            topic: context.toString(),
         });
         return API.unsubscribe(context);
      },

      /**
       *  Removes subscriptions.
       *
       * @param value - topic or token
       *      When passed a token, removes a specific subscription.
       *      When passed a topic, removes subscriptions based on that topic
       *
       * @param callback - callback function to unsubscribe from
       * @param context - context to unsubscribe from
       *
       * Examples
       *
       *   Example 1 - unsubscribing with a token
       *   var token = PubSub.subscribe("mytopic", someCallback);
       *   PubSub.unsubscribe(token);
       *
       *   Example 2 - unsubscribing a topic from a callback function
       *   PubSub.unsubscribe("mytopic", someCallback);
       *
       *   Example 3 - unsubscribing a topic that was subscribed in a specific context
       *   PubSub.unsubscribe("mytopic", null, someContext);
       *
       *   Example 4 - unsubscribing a topic from a callback function in a specific context
       *   PubSub.unsubscribe("myTopic", someCallback, someContext);
       *
       *   Example 5 - unsubscribing all topics that match a callback function in a specific context
       *   PubSub.unsubscribe(null, someCallback, someContext);
       *
       *   Example 6 - unsubscribing all topics associated with a callback function in any context
       *   PubSub.unsubscribe(null, someCallback);
       *
       *   Example 7 - unsubscribing all topics associated with a specific context
       *   PubSub.unsubscribe(null, null, someContext);
       *
       * @returns success - did anything get unsubscribed
       */
      unsubscribe: (value, callback, context) => {
         const isToken = typeof value === 'string' && value.indexOf(uuid) >= 0;
         const isTopic = typeof value === 'string' && !!topics[value]; // consider it a message if its in our message map
         let result = false;

         let _value = value;
         let _callback = callback;
         let _context = context;

         /*
          * If there are missing parameters
          * Fix up the inputs so it works with our API.
          * i.e shift the parameters forward.
          *        value = value || callback || context;
          *        callback = callback || context;
          */
         if (!_callback && !!_context) {
            _callback = _context;
            _context = null;
         }
         if (!_value) {
            _value = _callback;
            _callback = undefined;
         }

         // Invalid use case - TODO should we throw here?
         if (!_value) {
            console.log('Invalid Use of pubsub: No parameters passed');
            return false;
         }

         _logEntry({
            type: 'unsubscribe',
            topic: _value.toString(),
         });

         /*
          * If its a message and there is no context
          * Unsubscribe all the subcribers for that message, but not heirarchically
          */
         if (isTopic && (!_callback && !_context)) {
            delete topics[_value];
            return true;
         }

         const msgKeys = Object.keys(topics);
         msgKeys.forEach((key) => {
            const msg = key;
            const tokens = topics[key];

            /*
             * If we're just unsubscribing a token
             * tokens are unique, so we can just stop here
             */
            if (isToken && tokens[_value]) {
               delete tokens[_value];
               result = _value;
               return true; // TODO - this is not good enough for breaking out of the loop
            }

            // Ok, so now lets look inside all the topics tables and un-subscribe all the matching criteria
            const tokenKeys = Object.keys(tokens);
            tokenKeys.forEach((_key) => {
               const token = _key;
               const info = tokens[_key];

               // un-subscribing a message from a context or function from a context
               if (isTopic && _value === msg) {
                  // got passed a message, callback, and a context
                  if (_context && (info.context === _context && info.callback === _callback)) {
                     result = true;
                     delete topics[msg][token];
                  }
                  // got passed just a message and a context or callback
                  else if (!_context && (info.context === _callback || info.callback === _callback)) {
                     result = true;
                     delete topics[msg][token];
                  }
               }
               // un-subscribing a function from a context
               else if (_callback) {
                  if (_value === info.callback && _callback === info.context) {
                     result = true;
                     delete topics[msg][token];
                  }
               }
               // unsubscribing a function or a context globally
               else if (_value === info.callback || _value === info.context) {
                  result = true;
                  delete topics[msg][token];
               }
            });
         });

         return result;
      },
   };

   return API;
}

export default PubSub;
