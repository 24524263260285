import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import PubSub from "src/core/pubsub";
import Registry from 'src/application/registry/applicationRegistry';
import ViewController from "src/application/view/ViewController";
import ValidationEngine from 'src/validation/engine/ValidationEngine'
import css from 'src/application/css/modal.scss';
/* eslint no-unused-vars:0 */


export default function ModalWindow () {

    const _viewportId = "x_modal_window",
        _$modalBg = $("#xinch_modalBgContainer");
    let _closeCB,
        _blockCloseWhenErrors;

    if (!_$modalBg[0]) {
        // Add the modal background,
        // break the event loop to allow the browser to finish what its doing
        // before we try to attach the background - I.E. barfs if we dont
        setTimeout(() => {
            $("<div></div>", {
                id : "xinch_modalBgContainer"
            }).appendTo("body").hide();
        }, 0);
    }


    //------------------------------------------
    // Displays the modal window.
    // Contains logic to center and position the
    //      options -
    //          closeButton : [boolean]
    //          closeCallback : [function]
    //          closeEvent : [string]
    //-------------------------------------------
    this.show = function (options = {}) {

        // Create our DOM elements if we need to
        Registry.registerViewport(this.getViewPortId(), new ViewController(this.getViewPortId()), true);
        this.create();
        _closeCB = options.closeCallback;


        const $modal = $("#xinch_modalContainer"),
            $modalClose = $("#xinch_modalClose"),
            $modalBg = $("#xinch_modalBgContainer"),
            $window = $(window);

        if (options.closeButton) {
            $modalClose.on("click", () => {
                this.hide(undefined, true);
            });
            $modalClose.show(0);
        }
        else {
            $modalClose.off("click");
            $modalClose.hide(0);
        }

        if ($modal.is(":hidden")) {

            const viewportHeight = parseInt($window.height(), 10),
                modalHeight = parseInt($modal.height(), 10);
            let newTop = parseInt((viewportHeight / 2) - (modalHeight * 2), 10);

            if (newTop < 5) {
                newTop = 5;
            }
            //            $modal.css({'position' : 'relative', 'top' : newTop});
            $modal.fadeIn().removeClass('xinch_modalHide').addClass('xinch_modalShow');
            $modalBg.css({display : 'block'});
        }

        if (options.closeEvent) {
            PubSub.subscribe(options.closeEvent, this.hide, this);
        }

        _blockCloseWhenErrors = options.blockCloseWhenErrors;

    };

    /**
     * Hides the modal window.
     *   - will call a supplied close callback if specified in the options of the show method
     *
     * @param data
     */
    this.hide = function (data /*from close event*/, _selfClose = false) {
        const $modalWrapper = $("#xinch_modalContainer"),
            $modalClose = $("#xinch_modalClose"),
            $modalBg = $("#xinch_modalBgContainer");

        if (_blockCloseWhenErrors && !_selfClose) {
            // get the viewport and validate
            const _vp = Registry.getViewport(this.getViewPortId());
            if (_vp && !_vp.validate()) {
                return;
            }
        }
        else {
            _removeErrorTips();
        }

        // unsubscribe from our close event
        // do it here in case the closeCB fires the same event again
        PubSub.unsubscribe(this);
        Registry.removeViewport(this.getViewPortId());

        // Entirely remove the modal window from the DOM
        // So it can be re-used with different styles if necessary.
        $modalClose.off("click");
        $modalWrapper.remove();
        $modalBg.hide();
        if (_.isFunction(_closeCB)) {
            _closeCB(data);
        }
        _closeCB = null;
    };

    //------------------------------------------
    // Hides the modal window.
    //-------------------------------------------
    this.create = function () {
        let $modal = $("#xinch_modalContainer");
        if ($modal[0]) {
            return;
        }

        // Set up the modal container
        $modal = $("<div />", {
            id : "xinch_modalContainer"
        }).appendTo("body").hide();

        // Add the inner viewport
        $("<div data-viewport='" + _viewportId + "'></div>").appendTo($modal);

        // Add the close button
        $("<div title='close'>X</div>", {
            id : "xinch_modalClose"
        }).appendTo($modal).hide();

    };

    //-------------------------------------------------------
    // Get the id of the div we want to use as the viewport
    //-------------------------------------------------------
    this.getViewPortId = function () {
        return _viewportId;
    };

    const _removeErrorTips = function () {
        ValidationEngine.hideErrorTooltips(_viewportId);
    };

}
