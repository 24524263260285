/***
 * ResourceLoader Class to load various resources such as HTML, JS, css.
 */

import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';
import JSLoader from 'src/core/loaders/JsLoader';
import CssLoader from 'src/core/loaders/CssLoader';
import {getDataApi} from "src/core/utils/getDataResolver";

class ResourceLoader {


   /**
    * load js and css resources when sent a resources object and optional callback method
    * @param {Object} paramObject -  An object that should contain the following:
    *     - javascript: array with "javascript" resources
    *     - css : array with css resources
    *     - schemas : associative array of schemas to load
    *
    *  Ex: {
    *          "javascript" : ["fullPath2/javascriptFile.js", "fullPath2/secondJavascriptFile.js"],
    *          "css" : ["fullPath2/cssFile.css"],
    *          "schemas" : { foo : "path/to/fooSchema.json" }
    *      }
    * @returns promise
    */
   loadResources(paramObject) {
      if (!paramObject || !_.isObject(paramObject)) {
         Logger.error("loadJsAndCss requires a paramObject parameter. ", "Resource Loader");
      }

      paramObject = paramObject || {};


      const js = paramObject.javascript || paramObject.js || [];
      const css = paramObject.css || [];
      const schemas = paramObject.schemas || paramObject.schema || {};
      const promises = [];

      try {
         if (js) {
            _.each(js, (file) => {
               promises.push(JSLoader.load(file));
            });
         }
         if (css) {
            _.each(css, (file) => {
               promises.push(CssLoader.load(file));
            });
         }

         if (schemas.length) {
            const defaultDataApi = getDataApi();
            if (defaultDataApi.loadSchema) {
               _.each(schemas, (path) => {
                  promises.push(defaultDataApi.loadSchema(path));
               });
            }
         }

      } catch (ex) {
         Logger.error("Exception during loadResources: " + JSON.toString(paramObject), "Resource Loader", ex);
      }

      return Promise.allSettled(promises);
   }
}

export default new ResourceLoader();

