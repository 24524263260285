import {mergeObjects} from 'src/core/utils/objectUtils'
import Base from './RegistryBase';
import Constants from 'src/core/constants'

const Registry = {

    /**
     * Register any Object and give it a name;
     * This function will export the registered object into YOUR namespace using the value you supplied as your appId
     *
     * @param name
     * @param componentImpl
     * @param replace - replace the current if there is one
     * @returns {*} - the registered Object
     */
    registerComponent : function (name, componentImpl, replace) {
        return this._register(Constants.registry.kComponents, name, componentImpl, replace);
    },

    getComponent : function (name) {
        return this._get(Constants.registry.kComponents, name);
    },

    registerInterface : function (name, impl, replace) {
        return this._register(Constants.registry.kInterfaces, name, impl, replace);
    },

    getInterface : function (name) {
        return this._get(Constants.registry.kInterfaces, name);
    }
};
const _export = mergeObjects(Base, Registry);
export default _export