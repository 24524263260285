//-------------------------------------------
// return the id of the viewport that contains the given element
//-------------------------------------------
import _ from 'src/core/libs/underscore-1.6.custom';
import Registry from 'src/application/registry/applicationRegistry';
import Constants from 'src/application/constants';
import {get$} from "src/core/utils/$utils";

export default function getContainingViewport($element) {
   let vid = get$($element);
       vid = $element.attr("data-viewport");
   const viewportIds = Registry._getNames(Constants.registry.kViewports);
   if (vid && _.contains(viewportIds, vid)) {
      return vid;
   }
   const viewportsSelector = _.map(viewportIds, (id) => {
      return ("[data-viewport='" + id + "']");
   }).join(',');
   const nearestViewport = $element.closest(viewportsSelector);
   vid = nearestViewport ? nearestViewport.attr("data-viewport") : null;
   return vid;
}