/*
 * class: DefaultStrategies
 * Default validation strategies
 * 
 * about:
 * Clients can add new strategies or override existing ones by 
 * creating an object that has a 'validate' method and adding it to the mix
 * via the Xinch. addValidationStrategy(<name>, <strategyObject>) call;
 * 
 */
import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import Registry from 'src/validation/registry/ValidationRegistry';
import Constants from 'src/validation/constants';
import Logger from 'src/core/logging';
import OfficialTime from 'src/core/utils/officialTime';

/* eslint no-useless-escape: 0 */
/* eslint no-control-regex: 0 */
/* eslint no-unused-vars: 0 */
/* eslint max-len: 0 */
export default {
   // -------------------------------
   // Function: required
   // field is required
   //
   // Parameters:
   //   $el - the dom input element
   // -------------------------------
   required: {
      validateIfEmpty: true,
      validate: function ($el) {
         const err = this.validateAgainstType($el);
         if (err) {
            return this.getError($el, err);
         }
      },

      validateAgainstType: function ($el) {
         switch ($el.prop("type")) {
            case "text":
            case "password":
            case "textarea":
            case "file":
            /* falls through */
            default:
               if (!$el.val()) {
                  return "This field is required";
               }
               break;
            case "checkbox" :
               if (!$el.prop("checked")) {
                  return "This checkbox is required";
               }
               break;
            case "radio": {
               const container = $('body');
               const name = $el.attr("name");
               //if (container.find("input:radio[name='" + name + "']:checked").size() === 0)
               if (container.find("input[name='" + name + "'][type=radio]:checked").size() === 0) {
                  return "Please select an option";
               }
            }
               break;
            // required for <select>
            case "select-one":
               // added by paul@kinetek.net for select boxes, Thank you
               if (!$el.val()) {
                  return "This field is required";
               }
               break;
            case "select-multiple":
               // added by paul@kinetek.net for select boxes, Thank you
               if (!$el.find("option:selected").val()) {
                  return "This field is required";
               }
               break;

         }
      }
   },

   // -------------------------------
   // Function: requiredIf
   // field is required if the model reference has a value
   //
   // Parameters:
   //   $el - the dom input element
   //
   // Note : use some of the functionality of 'required' validator to get type specific messaging
   // -------------------------------
   requiredIf: {
      validateIfEmpty: true,
      validate: function ($el, modelVal) {
         const rq = Registry.getStrategy("required");
         const err = rq.validateAgainstType($el);

         if ((null !== modelVal && typeof modelVal !== "undefined" && modelVal !== "") && err) {
            return this.getError($el, err);
         }

      }

   },

   // -------------------------------
   // Function: requiredIf
   // field is required if the expression evaluates to true
   //
   // Parameters:
   //   $el - the dom input element
   //
   // Note : use some of the functionality of 'required' validator to get type specific messaging
   // -------------------------------
   requiredIfExpression: {
      validateIfEmpty: true,
      validate: function ($el, exp) {
         const rq = Registry.getStrategy("required");
         const err = rq.validateAgainstType($el);

         if (exp === true && err) {
            return this.getError($el, err);
         }

      }
   },

   // -------------------------------
   // Function: groupRequired
   // Validate input text fields are mutually exclusive
   // Display error if value exists in more than one of the grouped input fields
   // Display error if all grouped input fields are empty
   //
   // Parameters:
   //   value - the dom element value
   //   groupId - the id attribute of the grouped input elements
   // -------------------------------
   groupRequired: {
      validateIfEmpty: true,
      validate: function ($el, groupId) {
         const value = $el.val();
         // grab all the elements that have both grouprequired AND the group id in the data-validate tag
         // I do it this way instead of $("input[data-validate*='groupRequired(" + groupId + ")']");
         // because if the user put quotes around the groupId in the HTML, then we done get a match
         let $groupElems = $("input[data-validate*='groupRequired'][data-validate*='" + groupId + "']").filter(":visible");

         // filter out groupRequiredMultiple
         $groupElems = $groupElems.filter(function () {
            return !(this.id.match(/groupRequiredMultiple/));
         });

         let entered = 0;
         $.each($groupElems, function () {
            if (this.value.length !== 0) {
               entered++;
            }
         });
         // check if groupRequired input fields are not empty
         if (entered > 1) {
            return this.getError($el, "Only one field can be entered");
         }
         // check if groupRequired input fields are empty
         else if (entered === 0) {
            return this.getError($el, "One of these fields is required");
         }
      }
   },

   // -------------------------------
   // groupRequiredOneOrMore:
   // Validate input text fields are mutually exclusive
   // DON"T Display error if value exists in more than one of the grouped input fields (unlike the standard groupRequired)
   // Display error if all grouped input fields are empty
   // -------------------------------
   groupRequiredMultiple: {
      validateIfEmpty: true,
      validate: function ($el, groupId) {
         const value = $el.val();
         const $groupElems = $("input[data-validate*='groupRequiredMultiple'][data-validate*='" + groupId + "']").filter(":visible");

         let entered = 0;
         $.each($groupElems, function () {
            if (this.value.length !== 0) {
               entered++;
            }
         });
         // check if number of elements is greater than number of empty elements
         if (entered === 0) {
            return this.getError($el, "One or more of these fields is required");
         }
      }
   },

   // -------------------------------
   // Function: maxLength
   // Maximum characters allowed
   //
   // Parameters:
   //   value - the dom element value
   //   length - the Maximum characters allowed
   // -------------------------------
   maxLength: {
      validate: function ($el, length) {
         const value = $el.val();
         length = parseInt(length, 10);
         if (isNaN(length)) {
            Logger.error("maxLength validation set with non numeric arguement of: " + length, "defaultStrategies.maxLength");
         }
         this._max = length;
         if (value.length > length) {
            return this.getError($el, ("Maximum " + length + " characters allowed"));
         }
      },
      format: function ($el, event, length) {
         length = parseInt(length, 10);
         if (isNaN(length)) {
            Logger.error("maxLength validation set with non numeric arguement of: " + length, "defaultStrategies.maxLength");
         }
         if (!isNaN(length) && _.isNumber(length)) {
            if (($el.is("input") || $el.is("textarea")) && $el.val().length >= length) {
               $el.val($el.val().slice(0, length));
            }
            else if ($el.text().length >= length) {
               $el.text($el.text().slice(0, length));
            }
         }
      }
   },

   // -------------------------------
   // Function: minLength
   // Minimum characters required
   //
   // Parameters:
   //   value - the dom element value
   //   length - the Minimum characters allowed
   // -------------------------------
   minLength: {
      validate: function ($el, length) {
         const value = $el.val();
         length = parseInt(length, 10);
         if (isNaN(length)) {
            //invalid arguement. throw exception
            Logger.error("defaultStrategies.minLength", "minLength validation set with non numeric arguement of: " + length,);
         }
         if (_.isNumber(length) && !isNaN(length) && value.length < length) {
            return this.getError($el, ("Minimum " + length + " characters required"));
         }
      }
   },

   // -------------------------------
   // Function: exactlength
   // Value must be X number of characters
   //
   // Parameters:
   //   value - the dom element value
   //   length - the Exact number of characters allowed
   // -------------------------------
   exactLength: {
      validate: function ($el, length) {
         const value = $el.val();
         length = parseInt(length, 10);
         if (isNaN(length)) {
            Logger.error("exactLength validation set with non numeric arguement of: " + length, "defaultStrategies.exactLength");
         }
         if (_.isNumber(length) && !isNaN(length) && value.length != length) {
            return this.getError($el, ("Value must be " + length + " characters"));
         }

      },
      format: function ($el, event, length) {
         length = parseInt(length, 10);
         if (isNaN(length)) {
            Logger.error("exactLength validation set with non numeric arguement of: " + length, "defaultStrategies.exactLength");
         }
         if ($el.is("input") && _.isNumber(length) && !isNaN(length)) {
            if ($el.val().length >= length) {
               $el.val($el.val().slice(0, length));
            }
         }
         else if ($el.text().length >= length && _.isNumber(length) && !isNaN(length)) {
            $el.text($el.text().slice(0, length));
         }
      }
   },

   // -------------------------------
   // Function: max
   // Maximum value is
   //
   // Parameters:
   //   value - the dom element value
   //   max - the Maximum value allowed
   // -------------------------------
   max: {
      validate: function ($el, max) {
         let value = $el.val().replace(/\,/g, "");
         max = parseFloat(max);
         value = parseFloat(value);
         if (isNaN(max) || (!_.isNumber(max))) {
            Logger.error("max validation set with non numeric arguement of: " + max, "defaultStrategies.max");
         }
         if (isNaN(value) || !_.isNumber(value) || (parseFloat(value) > parseFloat(max))) {
            return this.getError($el, ("Maximum value is " + max));
         }
      }
   },

   // -------------------------------
   // Function: min
   // Minimum value is
   //
   // Parameters:
   //   value - the dom element value
   //   min - the Minimum value allowed
   // -------------------------------
   min: {
      validate: function ($el, min) {
         let value = $el.val().replace(/\,/g, "");
         min = parseFloat(min);
         value = parseFloat(value);
         if (isNaN(min)) {
            Logger.error("min validation set with non numeric arguement of: " + min, "defaultStrategies.min");
         }
         if (isNaN(value) || !_.isNumber(value) || (parseFloat(value) < parseFloat(min))) {
            return this.getError($el, ("Minimum value is " + min));
         }
      }
   },

   // -------------------------------
   // Function: multipleOf(x)
   // value is a multiple of x
   //
   // Parameters:
   //   value - the dom element value
   //   multiple - Integer which value must be a multiple of
   // -------------------------------
   multipleOf: {
      validate: function ($el, multiple) {
         const value = parseFloat($el.val().replace(/\,/g, ""));
         multiple = parseFloat(multiple);
         if (!_.isNumber(multiple) || isNaN(multiple)) {
            Logger.error("multipleOf validation set with non numeric arguement of: " + multiple, "defaultStrategies.min");
         }
         if (isNaN(value) || !_.isNumber(value) || (parseFloat(value) % parseFloat(multiple) !== 0)) {
            return this.getError($el, ("Must be a multiple of " + multiple));
         }
      }
   },

   // -------------------------------
   // Function: same
   // Field must be same as fieldName
   //
   // Parameters:
   //   value - the dom element value
   //   fieldId - the id of the field to match the value to
   //   fieldName - the name of the field to match the value to
   //   caseInsensitive - bool field. true = not case sensitive match
   //           default is falsy
   // -------------------------------
   same: {
      validate: function ($el, fieldId, fieldName, caseInsensitive) {
         const value = $el.val();
         const otherEl = $("#" + fieldId);
         const equalVal = otherEl.val() ? otherEl.val() : otherEl.text();
         if (caseInsensitive && (value.toLowerCase() !== equalVal.toLowerCase())) {
            return this.getError($el, ("Field must be same as " + fieldName));
         }
         else if (!caseInsensitive && (value !== equalVal)) {
            return this.getError($el, ("Field must be same as " + fieldName + " (Case Sensitive)"));
         }
      }
   },
   // -------------------------------
   // Function: sameTrimmed
   // Field must be same as fieldName after leading
   // and trailing white space is removed.
   //
   // Parameters:
   //   value - the dom element value
   //   fieldId - the id of the field to match the value to
   //   fieldName - the name of the field to match the value to
   //   caseInsensitive - bool field. true = not case sensitive match
   //           default is falsy
   // -------------------------------
   sameTrimmed: {
      validate: function ($el, fieldId, fieldName, caseInsensitive) {
         const value = $.trim($el.val());
         const otherEl = $("#" + fieldId);
         let equalVal = otherEl.val() ? otherEl.val() : otherEl.text();

         //trim whitespace
         equalVal = $.trim(equalVal);
         if (caseInsensitive && (value.toLowerCase() !== equalVal.toLowerCase())) {
            return this.getError($el, ("Field must be same as " + fieldName));
         }
         else if (!caseInsensitive && (value !== equalVal)) {
            return this.getError($el, ("Field must be same as " + fieldName + " (Case Sensitive)"));
         }
      }
   },

   // -------------------------------
   // Function: notSame
   // Field cannot be same as fieldName
   //
   // Parameters:
   //   value - the dom element value
   //   fieldId - the id of the field to validate that the value does NOT match
   //   fieldName - the name of the field to validate that the value does NOT match
   //   caseInsensitive - bool field. true = not case sensitive match
   //           default is falsy
   // -------------------------------
   notSame: {
      validate: function ($el, fieldId, fieldName, caseInsensitive) {
         //trim whitespace
         const value = $el.val();
         const otherEl = $("#" + fieldId);
         const equalVal = otherEl.val() ? otherEl.val() : otherEl.text();
         //            var equalVal = $("#" + fieldId).val();
         if (caseInsensitive && (value.toLowerCase() === equalVal.toLowerCase())) {
            return this.getError($el, ("Field cannot be same as " + fieldName));
         }
         else if (!caseInsensitive && (value === equalVal)) {
            return this.getError($el, ("Field cannot be same as " + fieldName + " (Case Sensitive)"));
         }
      }
   },
   // -------------------------------
   // Function: notSame
   // Field cannot be same as fieldName after leading and trailing white splace
   // is removed.
   //
   // Parameters:
   //   value - the dom element value
   //   fieldId - the id of the field to validate that the value does NOT match
   //   fieldName - the name of the field to validate that the value does NOT match
   //   caseInsensitive - bool field. true = not case sensitive match
   //           default is falsy
   // -------------------------------
   notSameTrimmed: {
      validate: function ($el, fieldId, fieldName, caseInsensitive) {
         //trim whitespace
         const value = $.trim($el.val());
         const otherEl = $("#" + fieldId);
         let equalVal = otherEl.val() ? otherEl.val() : otherEl.text();
         //            var equalVal = $("#" + fieldId).val();
         //trim whitespace
         equalVal = $.trim(equalVal);
         if (caseInsensitive && (value.toLowerCase() === equalVal.toLowerCase())) {
            return this.getError($el, ("Field cannot be same as " + fieldName));
         }
         else if (!caseInsensitive && (value === equalVal)) {
            return this.getError($el, ("Field cannot be same as " + fieldName + " (Case Sensitive)"));
         }
      }
   },

   // -------------------------------
   // Function: equalsVal
   // Value must be equal/
   //
   // Parameters:
   //   value - the dom element value
   //   equalVal - the value to validate against
   // -------------------------------
   equalsVal: {
      validate: function ($el, equalVal) {
         const value = $el.val();
         if (value !== (equalVal + "")) {
            return this.getError($el, ("Value must be " + equalVal));
         }
      }
   },

   // -------------------------------
   // Function: notEqualsVal
   // Value cannot be equal
   //
   // Parameters:
   //   value - the dom element value
   //   notEqualVal - the value to validate against
   // -------------------------------
   notEqualsVal: {
      validate: function ($el, notEqualVal) {
         const value = $el.val();
         if (value === (notEqualVal + "")) {
            return this.getError($el, ("Value cannot be " + notEqualVal));
         }
      }
   },

   // -------------------------------
   // Function: email
   // valid email format is enforced
   // -------------------------------
   email: {
      // Shamelessly lifted from Scott Gonzalez via the Bassistance Validation plugin http://projects.scottsplayground.com/email_address_validation/
      //regex : /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
      regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,

      getError: function ($el) {
         return this._super.getError($el, "Invalid email address");
      }
   },

   // -------------------------------
   // Function: ssn
   // valid ssn format is enforced
   //
   // Parameters:
   //   value - the dom element value
   //   allowException - the boolean value to allow alternative formats
   // -------------------------------
   ssn: {
      exceptions: ["applied for", "died", "tax exempt", "LAFCP", "unknown"],
      defaultRegex: /^\d\d\d\-\d\d\-\d\d\d\d$/,
      altRegex: /^\d\d\-\d\d\d\d\d\d\d$/,
      defaultMask: "###-##-####",
      altMask: "##-#######",
      getError: function ($el, altMsg) {
         return this._super.getError($el, (this.allowException ? "Invalid SSN" : "Invalid SSN, must be " + this.mask));
      },
      // -------------------------------
      // Function: validate
      // validate a ssn
      //
      // Parameters:
      //   value - the string to validate
      //   p1 - allowException flag
      //   p2 - validate range
      // -------------------------------
      validate: function ($el, allowException, validateRange) {
         const value = $el.val();
         this.allowException = allowException;
         this.regex = this.defaultRegex;
         this.mask = this.defaultMask;

         // EINs is also a valid exception (eins start with nn-
         if (allowException && value.match(/^\d\d\-/)) {
            this.regex = this.altRegex;
            this.mask = this.altMask;
         }
         let msg = this._super.validate($el);

         // Validate range if we passed the pattern check
         if (!msg && validateRange && !allowException) {
            //              Regular SSN range 001-01-0001 to 699-99-9999
            //                       700-01-0001 to 733-99-9999	 2003 increased 729 to 733 1/14/2004 jre
            //                       750-01-0001 to 765-99-9999	 2000
            //                       750-01-0001 to 763-99-9999  2001 change
            //                       764-01-0001 to 899-99-9999  2001 change
            //
            //              ATIN range        900-93-0000 to 999-93-9999
            //              ATIN range        is not included, given that this may not be entered in SSN field
            //              ITIN range        900-70-0000 to 999-80-9999

            let valid = false;
            const ssn = value.replace(/[^0-9]/g, "");
            const first = ssn.substr(0, 3);
            const second = ssn.substr(3, 2);
            const third = ssn.substr(5, 4);

            if ((first < 900) && (first !== 0) && (second !== 0) && (third !== 0)) {
               valid = true;
            }
            // ITIN validation / ATIN
            else if ((first > 899) && (second > 69 && second < 81)) {
               valid = true;
            }

            if (!valid) {
               msg = "Invalid Social security number";
            }

         }


         return msg;
      },
      format: function ($el, event, allowException) {
         const inVal = $el.val() || $el.text();

         if (inVal === "") {
            return;
         }

         this.allowException = allowException;
         this.mask = this.defaultMask;

         // EINs is also a valid exception (eins start with nn-
         if (allowException && inVal.match(/^\d\d\-/)) {
            this.mask = this.altMask;
         }
         this.formatAgainstMask($el, inVal, event);
      }
   },
   // -------------------------------
   // Function: regex
   // validate based on a regular expression
   //
   // Parameters:
   //   value - the dom element value
   //   re - the regular expression to validate against
   //   message - the error message to display if validation doesn't pass
   regex: {
      getError: function ($el) {
         return this._super.getError($el, this.errorMsg);
      },
      validate: function ($el, expression, message) {
         this.errorMsg = message;
         this.regex = new RegExp(expression);
         return this._super.validate($el);
      }
   },
   // -------------------------------
   // Function: date
   // valid date format is enforced
   //
   // Parameters:
   //   value - the dom element value
   //   mask - the format to validate against
   //   allowException - the boolean value to allow alternative formats
   // -------------------------------
   date: {
      exceptions: ["various", "inherit", "inherited", "continue", "continues", "none"],
      defaultMask: "MM/DD/YYYY",
      getError: function ($el) {
         return this._super.getError($el, "Invalid '" + this.mask + "' date");
      },
      // -------------------------------
      // Function: validate
      // validate a date
      //
      // Parameters:
      //   value - the string to validate
      //   p1 - date mask or allowException flag
      //   p2 - date mask or allowException flag
      // -------------------------------
      validate: function ($el, p1, p2) {  // parameters [mask,allowException] can be passed in either order
         const value = $el.val();
         // figure out which order the parameters were passed in
         if (typeof p1 === "boolean") {
            this.allowException = p1;
            this.mask = p2 || this.defaultMask;
         }
         else if (typeof p2 === "boolean") {
            this.allowException = p2;
            this.mask = p1 || this.defaultMask;
         }
         else {
            this.mask = p1 || this.defaultMask;
            this.allowException = false;
         }

         let m, d, y, parts;
         switch (this.mask) {
            case "MM/YYYY" :
               this.regex = /^(?:0?[1-9]|1[0-2])\/(?:19\d\d|20\d\d)$/;
               parts = value.split('/');
               m = parts[0];
               y = parts[1];
               break;
            case "MM/YY" :
               this.regex = /^(?:0?[1-9]|1[0-2])\/(\d\d)$/;
               parts = value.split('/');
               m = parts[0];
               y = parts[1];
               break;
            case "YYYY" :
               this.regex = /^(?:19\d\d|20\d\d)$/;
               y = value;
               break;
            case "YYYY-MM-DD" :
               this.regex = /^(?:19\d\d|20\d\d)\-(0?[1-9]|1[0-2])\-(0?[1-9]|[12][0-9]|3[01])$/;
               parts = value.split('-');
               m = parts[1];
               d = parts[2];
               y = parts[0];
               break;
            case "MM/DD" :
               this.regex = /^(?:(?:0?[1-9]|1[0-2])(\/|-)(?:0?[1-9]|[12][0-9]|3[01]))$/;
               parts = value.split('/');
               m = parts[0];
               d = parts[1];
               break;
            case "" :
            case "MM/DD/YYYY" :
            /* falls through */
            default :
               this.regex = /^(?:(?:0?[1-9]|1[0-2])(\/|-)(?:0?[1-9]|[12][0-9]|3[01]))(\/|-)(?:19\d\d|20\d\d)$/;
               parts = value.split('/');
               m = parts[0];
               d = parts[1];
               y = parts[2];
               break;  // use the default mask/regex
         }

         function _isValidDateRange(m, d, y) {
            m = parseInt(m, 10);
            d = parseInt(d, 10);
            y = parseInt(y, 10);

            // only year is in the range 1900 - 2099
            if (y && !m && !d) {
               return (y >= 1900 && y < 2100);
            }
            if (m && (m < 1) || (m > 12)) {
               return false;
            }
            if (d && (d < 1) || (d > 31)) {
               return false;
            }
            if (((m == 4) || (m == 6) || (m == 9) || (m == 11)) && (d > 30)) {
               return false;
            }
            if (m == 2 && d > 29) {
               return false;
            }

            // figure out leap years
            if (d && m == 2) {
               // is leap year
               if ((y % 4 === 0) && (y % 100 !== 0) || (y % 400 === 0)) {
                  return d <= 29;
               }
               else {
                  return d < 29;
               }
            }
            return true;
         }


         // See if we have date within ranges
         let err = _isValidDateRange(m, d, y) ? null : "Invalid date range";

         // if it passed the range check, see if it passes the valid date check
         if (!err) {
            err = this._super.validate($el);
         }

         if (err) {
            return this.getError($el, err);
         }

      },
      format: function ($el, event, mask, allowException) {
         const inVal = $el.val() || $el.text()
         let outVal;

         if (inVal === "") {
            return;
         }

         // set up mask
         this.allowException = allowException;
         mask = mask || this.defaultMask;
         this.mask = mask;
         this.mask = this.mask.replace(/[A-Za-z]/gi, "#");

         outVal = inVal;

         let caretCnt = 0;
         const caret = $el.caret();
         if (mask.indexOf("YYYY") > -1) { // 4 digit year, try to upgrade  anything not 19 or 20
            if (outVal.charAt(6) && outVal.charAt(7) && outVal.charAt(5) === '/' && !outVal.charAt(8)) {
               const leastSigYrDigits = outVal.substr(6, 2);
               if (leastSigYrDigits !== "19" && leastSigYrDigits !== "20") {
                  // try to guess the full year, based on the two year digits entered
                  let fullYear = parseInt('20' + leastSigYrDigits, 10);  // this line becomes obsolete before the year 2100
                  const yearDifference = fullYear - OfficialTime.getDate().getFullYear();
                  // assume dates are more likely to be from last century than more than two years in the future
                  if (yearDifference > 2) {
                     fullYear -= 100;
                  }
                  outVal = outVal.slice(0, 5) + fullYear.toString();

                  // unlike at other times, when we don't want to format on '0' key press,
                  // in this instance we do. We want to format on any keypress
                  // because it's part of a two digit year that should be expanded to four digits.
                  // so, after this expansion, call formatAgainstDateMask regardless of the users keypress.
                  this.formatAgainstMask($el, outVal, event);

               }
            }
         }

         const monthIdx = mask.indexOf("MM");
         if (monthIdx > -1) { // 2 Digit month. Expand one-digit entries followed by a slash
            // Check whether the user has entered a one-digit month number followed by a slash
            if (outVal.charAt(monthIdx) && outVal.charAt(monthIdx + 1) === '/') {

               // Expand month to two digits
               const fullMonth = "0" + outVal.charAt(monthIdx);
               if (!_.contains(this.ignoreKeyCodesOnMask, event.keyCode)) {
                  caretCnt++;
               }

               // Update return value
               outVal = outVal.substring(0, monthIdx) + fullMonth + outVal.substring(monthIdx + 1);

            }
         }

         const dayIdx = mask.indexOf("DD");
         if (dayIdx > -1) { // 2 Digit day. Expand one-digit entries followed by a slash
            // Check whether the user has entered a one-digit day number followed by a slash
            if (outVal.charAt(dayIdx) && outVal.charAt(dayIdx + 1) === '/') {

               // Expand day to two digits
               const fullDay = "0" + outVal.charAt(dayIdx);
               if (!_.contains(this.ignoreKeyCodesOnMask, event.keyCode)) {
                  caretCnt++;
               }

               // Update return value
               outVal = outVal.substring(0, dayIdx) + fullDay + outVal.substring(dayIdx + 1);
            }
         }

         this.formatAgainstMask($el, outVal, event);
         if (caretCnt > 0) {
            $el.caret(caret.end + caretCnt);
         }
      }

   },

   // -------------------------------
   // Function: before
   // Date entered must be before date
   //
   // Parameters:
   //   value - the dom element value
   //   date - the date to validate against
   //   inclusive - the boolean value to include the date as a valid date
   // -------------------------------
   before: {
      validate: function ($el, date, inclusive) {
         const value = $el.val();
         try {
            const inputDate = value.toDate();
            let targetDate;
            switch (date) {
               case "today" :
               case "now" :
                  targetDate = OfficialTime.getDate();
                  break;
               default :
                  targetDate = date.toDate();
                  break;
            }
            let valid = true;
            if (inclusive) {
               valid = (inputDate.yyyymmdd() <= targetDate.yyyymmdd());
            }
            else {
               valid = (inputDate.yyyymmdd() < targetDate.yyyymmdd());
            }
            return valid ? null : this.getError($el, "Date entered must be" + ((inclusive) ? " on or" : "") + " before " + date);

         } catch (err) {
            return;
         }

      }
   },

   // -------------------------------
   // Function: after
   // Date entered must be after date
   //
   // Parameters:
   //   value - the dom element value
   //   date - the date to validate against
   //   inclusive - the boolean value to include the date as a valid date
   // -------------------------------
   after: {
      validate: function ($el, date, inclusive) {
         const value = $el.val();

         try {
            const inputDate = value.toDate();
            let targetDate;
            switch (date) {
               case "today" :
               case "now" :
                  targetDate = OfficialTime.getDate();
                  break;
               default :
                  targetDate = date.toDate();
                  break;
            }
            let valid = true;
            if (inclusive) {
               valid = (inputDate.yyyymmdd() >= targetDate.yyyymmdd());
            }
            else {
               valid = (inputDate.yyyymmdd() > targetDate.yyyymmdd());
            }
            return valid ? null : this.getError($el, "Date entered must be" + ((inclusive) ? " on or" : "") + " after " + date);

         } catch (err) {
            return;
         }

      }
   },

   // -------------------------------
   // Function: phone
   // valid phone format is enforced
   //
   // Parameters:
   //   value - the dom element value
   //   mask - the format to validate against
   // -------------------------------
   phone: {
      regex: /^\(\d\d\d\) \d\d\d[\-]\d\d\d\d$/,
      defaultMask: "(###) ###-####",
      getError: function ($el) {
         return this._super.getError($el, "Invalid phone number, must be " + this.mask);
      },
      validate: function ($el, mask) {
         const value = $el.val();
         this.mask = mask || this.defaultMask;

         let pattern = this.mask.replace(/#/g, "\\d").replace(/\(/g, "\\(").replace(/\)/g, "\\)").replace(/\./g, "\\.");

         pattern = "^" + pattern + "$";
         this.regex = new RegExp("" + pattern);

         const msg = this._super.validate($el);

         // apparently new phone numbers can have a 0 or 1 in any position
         //if (!msg) {
         //    var ph = value.replace(/[^0-9]/g, "");
         //    if (ph.charAt(0) == '0' || ph.charAt(0) == '1') msg = "First digit cannot be 0 or 1";
         //    if (ph.charAt(3) == '0' || ph.charAt(3) == '1') msg = "Fourth digit cannot be 0 or 1";
         //}

         //if msg is not undefined, that means error has been set, no need to validate for all zeros
         if (!msg && /[1-9]/.test(value) === false) {
            return "Phone number can't be all zeros.";
         }

         return msg;
      },

      format: function ($el, event, mask) {
         const inVal = $el.val() || $el.text();

         if (inVal === "") {
            return;
         }

         this.mask = mask || this.defaultMask;

         //get all the non-number chars in the mask, so they're not replaced (i.e. entered by the user)
         //            var reg = new RegExp(this.mask.replace(/^#/gi, ""), 'gi');

         this.formatAgainstMask($el, inVal, event);
      }
   },

   // -------------------------------
   // Function: number
   // Number, including positive, negative, and floating decimal.
   // see numberOnly validator for validating integers
   // -------------------------------
   number: {
      regex: /^[\-\+]?(\d+(\,\d{3})*\.?\d{0,9}|\.\d{1,9})$/,
      getError: function ($el) {
         return this._super.getError($el, "Invalid number");
      },
      validate: function ($el, precision) {

         precision = precision || "100";

         let pattern = "[\\-\\+]?(\\d+(,\\d{3})*\\.?\\d{0,PRE}|\\.\\d{1,PRE})";
         pattern = pattern.replace(/PRE/g, precision);

         pattern = "^" + pattern + "$";
         this.regex = new RegExp("" + pattern);

         return this._super.validate($el);
      },
      format: function ($el, event, precision, addPrecisionOnBlur) {

         let inVal = $el.val() || $el.text();
         const startLength = inVal.length;
         let endLength = 0;
         if (inVal === "") {
            return;
         }


         // save off the cursor position
         let caretPos = $el.caret();
         caretPos = caretPos ? caretPos.end : 0;

         // get rid of all the crap
         const isNeg = inVal.charAt(0) === "-";
         inVal = inVal.replace(/[^0-9.]/gi, "");

         if (isNeg) {
            inVal = "-" + inVal;
         }

         // Add the commas in the appropriate spots
         let parts = inVal.split(".");
         parts[0] = parts[0].replace(/0*(\d+)/, "$1"); // remove leading zero's except first one
         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

         // Get rid of anything after and including a second period
         // and truncate up to the precision
         if (typeof parts[1] !== 'undefined') {
            parts[1] = parts[1].substr(0, precision);
            parts = [parts[0], parts[1]];
         }

         if (event.type == Constants.jqEvents.kAutoformat || (addPrecisionOnBlur && event.type === "blur")) {
            if (!parts[1]) {
               parts[1] = "";
            }
            for (let i = parts[1].length; i < precision; i++) {
               parts[1] += "0";
            }

         }

         inVal = parts.join(".");
         endLength = inVal.length;

         // Set the value and the caret position
         this.setFormattedValue($el, inVal, event, (startLength == endLength) ? caretPos : caretPos + (endLength - startLength));
      }

   },

   // -------------------------------
   // Function: numberOnly
   // Numbers 0-9 only
   // -------------------------------
   numberOnly: {
      regex: /^[0-9]+$/,
      getError: function ($el) {
         return this._super.getError($el, "Value is not a number");
      },
      format: function ($el, event) {
         const inVal = $el.val() || $el.text();

         if (inVal === "") {
            return;
         }
         if (inVal.replace(/[0-9]/gi, "").length === 0) {
            return inVal;
         }
         this.setFormattedValue($el, inVal.replace(/[^0-9]/g, ''));

      }
   },

   // -------------------------------
   // Function: alpha
   // Letters only
   // -------------------------------
   alpha: {
      regex: /^[a-zA-Z]*$/,
      getError: function ($el) {
         return this._super.getError($el, "Value must be all letters");
      },
      validate: function ($el, allowSpace) {
         this.regex = allowSpace ? /^[a-zA-Z\s]*$/ : this.regex;
         return this._super.validate($el);
      },
      format: function ($el, event, allowSpace) {
         const inVal = $el.val() || $el.text();

         const reg = allowSpace ? /[^a-zA-Z\s]/g : /[^a-zA-Z\s]/g;

         this.setFormattedValue($el, inVal.replace(reg, ""));
      }
   },

   alphaNumeric: {
      regex: /^[a-zA-Z0-9]*$/,
      getError: function ($el) {
         return this._super.getError($el, "Should be alphanumeric");
      },
      validate: function ($el, allowSpace) {
         this.regex = allowSpace ? /^[a-zA-Z0-9\s]*$/ : this.regex;
         return this._super.validate($el);
      },
      format: function ($el, event, allowSpace) {
         const inVal = $el.val() || $el.text();

         const reg = allowSpace ? /[^a-zA-Z0-9\s]/g : /[^a-zA-Z0-9]/g;

         this.setFormattedValue($el, inVal.replace(reg, ""));

      }
   },

   upperCase: {
      getError: function ($el) {
         return this._super.getError($el, "Should be all upper case");
      },
      validate: function ($el) {
         const val = $el.val();
         const test = val === val.toUpperCase();
         if (!test) {
            return this.getError($el);
         }
         else {
            return null;
         }

      },
      format: function ($el, evt) {
         const val = $el.val() || $el.text();
         this.setFormattedValue($el, val.toUpperCase());
      }
   },

   lowerCase: {
      getError: function ($el) {
         return this._super.getError($el, "Should be all lower case");
      },
      validate: function ($el) {
         const val = $el.val();
         const test = (val === val.toLowerCase());
         if (!test) {
            return this.getError($el);
         }
         else {
            return null;
         }

      },
      format: function ($el, evt) {
         const val = $el.val() || $el.text();
         this.setFormattedValue($el, val.toLowerCase());
      }
   },

   maskedNumber: {
      regex: /^$/,
      defaultMask: "",
      getError: function ($el) {
         return this._super.getError($el, "Invalid input, format must be " + this.mask);
      },
      validate: function ($el, mask) {
         this.mask = mask || this.defaultMask;

         // escape all non alpha so we can turn it into a regex
         let pattern = this.mask.replace(/([^a-zA-Z0-9#])/g, "\\$1");
         // now replace the "#" as a digit
         pattern = pattern.replace(/#/g, "\\d");

         pattern = "^" + pattern + "$";
         this.regex = new RegExp("" + pattern);

         return this._super.validate($el);
      },
      format: function ($el, event, mask) {
         const inVal = $el.val() || $el.text();

         if (inVal === "") {
            return;
         }

         // set up mask
         this.mask = mask ? mask : this.defaultMask;
         //this.mask = this.mask.replace(/[A-Za-z]/gi, "#").replace(/&#41;/g, ")");

         this.formatAgainstMask($el, inVal /*.replace(/[^0-9]/gi, ""*/, event);
      }
   },

   zip: {
      regex: /^\d{5}(\-\d{4})?$/,
      fivedigitMask: "#####",
      defaultMask: "#####-####",
      validate: function ($el, mustBePlusFour) {
         const value = $el.val();
         this.mask = this.defaultMask; //always use default mask

         if (mustBePlusFour && value.length != 10) {
            return this.getError($el, "Invalid zip code, must be #####-####");
         }
         if (!(value.length === 5 ||
            value.length === 10)) //hard coded lengths for zip if mask arg is ommited and default mask is used( can be 5 or 10)
         {
            return this.getError($el, "Invalid zip code, must be #####  or  #####-####");
         }
      },

      format: function ($el, event) {
         const inVal = $el.val();

         if (inVal === "") {
            return;
         }


         // set up mask
         this.mask = (inVal.length > 5) ? this.defaultMask : this.fivedigitMask; //always use default mask
         //this.mask = this.mask.replace(/[A-Za-z]/gi, "#");

         this.formatAgainstMask($el, inVal /*.replace(/[^0-9]|-/gi, "")*/, event);

      }
   },

   creditCard: {
      defaultMask: "################",
      validate: function ($el) {
         let value = $el.val().replace("-", "").replace(/\s/g, "");
         const ccFirstTwo = value.slice(0, 2);
         if (ccFirstTwo === "34" || ccFirstTwo === "37") { //Amex Cards are only 15 digits
            if (value.length !== 15) {
               //return error if if it's not 15 digits
               return this.getError($el, "Invalid Credit Card Number");
            }
            else {
               // if it is an amex of valid length
               //need to prepend a "0" so that the luhn check will pass. Adding
               //a zero to the beginning of a valid number wont affect the validation
               value = "0" + value;
            }
         }
         else {
            if (value.length !== 16) {  //It's not an Amex, Should be 16 digits
               return this.getError($el, "Invalid Credit Card Number");
            }
         }

         //passed the length requirement, now check the luhn validation.

         //###### Luhn Number validation ######
         let digit = 0, checksum = 0;
         for (let len = (value.length - 1); len >= 0; len--) {
            digit = parseInt(value.charAt(len), 10);
            if (len % 2) {
               checksum += digit;
            }
            else {
               digit = digit * 2;
               if (digit >= 10) {
                  checksum += Math.floor(digit / 10);
                  checksum += digit % 10;
               }
               else {
                  checksum += digit;
               }
            }
         }
         if (checksum % 10 === 0) {
            // return undefined (aka valid) is implied
         }
         else {
            return this.getError($el, "Invalid Credit Card Number");
         }

      },
      format: function ($el, event) {
         //todo: accept user specified mask?
         //in addition to the normal formatting options,
         const inVal = $el.val();

         const ccFirstTwo = inVal.slice(0, 2);
         //Check if it's Amex, if so, we need a 15 char mask, not 16
         if (ccFirstTwo === "34" || ccFirstTwo === "37") {
            this.mask = "###############";
         }
         else {
            this.mask = this.defaultMask;
         }

         if (inVal === "") {
            return;
         } //skip masking if empty.

         this.formatAgainstMask($el, inVal /*.replace(/[^0-9]/gi, "")*/ /* <-- replace non-numerics */, event);
      }
   },

   /*
    RTN - Bank Routing Number
    */
   rtn: {
      validate: function ($el) {
         const passedRTN = $el.val();
         let valid = false;

         if (passedRTN.match("[-\\d]+")) {
            const strippedRTN = passedRTN.replace(/-/, "");
            if (strippedRTN.length == 9) {
               const firstTwo = strippedRTN.substring(0, 2).toNum();
               if (!((firstTwo >= 1 && firstTwo <= 12) || (firstTwo >= 21 && firstTwo <= 32))) {
                  valid = false;
                  return this.getError($el, "Invalid Routing Transit Number");
               }
               let sumOfMultipliers = 0;
               const multipliers = [3, 7, 1, 3, 7, 1, 3, 7];
               for (let i = 0; i < multipliers.length; i++) {
                  sumOfMultipliers += multipliers[i] * strippedRTN.charAt(i).toNum();
               }
               const checkDigit = strippedRTN.charAt(8).toNum();
               const checkNumber = (10 - (sumOfMultipliers % 10)) % 10;
               if (checkDigit == checkNumber) {
                  valid = true;
               }
            }
         }

         if (!valid) {
            return this.getError($el, "Invalid Routing Transit Number");
         }
      }
   }

};
