/**
 * class: ViewResolver
 *
 * about:
 * Functionality to turn a reference of a view into the actual implementation of the view
 *
 * Options :
 *
 *      pathToViews : path to the implementation of html files.
 *                    Can be a string that represents the default path to ALL views,
 *                    Or a hashmap that has a list of namespaces that map to different locations.
 *                    If you use an hashmap, you must define a "default" key
 *                    I.e.
 *                        pathToViews:{
 *                                "ns1":"html/namespace1files/",
 *                                "ns1.views":"html/namespace2files/other/",
 *                                "default":"html/"
 *                        }
 *
 *                    Then in your flow references (in the flow definitions) you reference your flows
 *                    using the namespace in using the following convention.
 *                        <namespace>.<fileReference>
 *
 *                     - everything up to the last '.' is considered a namespace and needs to be resolved in the pathToFlows
 *                     - if there is no namespace (no '.') then the default path will be used
 *
 *                        ns1.page1                     will be mapped to html/namespace1files/ (and page1 will get resolved in the aliasMap)
 *                        ns1.views.page1               will be mapped to html/namespace2files/other/
 *                        page1                         will be mapped to html/  (the default path)
 *
 *
 *
 *      aliasMap    :  map containing the resolution of view references to html file names
 *                     wildcard entry ('*') in the alias map will contain the default extension of the flow names
 *
 *        Note : The item in the aliasMap marked '*' indicates that any view not existing in the map will be mapped directly to their reference name.
 *               The value of the '*' entry specifies the default extension of wildcarded view mappings.
 *
 *               You only need to supply aliasMappings here if your fileAliases do not match exactly to the name of the view.
 *               For example if the name of the fileAlias is page1 and your html file is page1.html, we'll use the wildcard convention, and you don't
 *               need to supply a mapping
 *
 *                        aliasMap:{
 *                          "Pg1":"namespace1/Page1.htm",
 *                          "ns1.Pg2":"Page2.htm",
 *                          "ns2.SubflowPg":"SubflowPage.htm",
 *                          "*":".htm"
 *                        }
 *
 *      cacheParam : parameter to append as a query string to each resolved page reference. can be a string or a function that returns a string.
 *
 *
 */
import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';
import HTMLLoader from 'src/core/loaders/HTMLLoader';
import Config from 'src/core/config/Config'
import Constants from 'src/application/constants';
import Registry from 'src/application/registry/applicationRegistry';
import PubSub from "src/core/pubsub";

export default function ViewResolver() {

   /*
    * Function: resolve
    * resolve the path to the view
    *
    * Parameters:
    * pageRef - the period-delimited filepath(eg. "a.b.c") we need to break this filepath into 2 parts.
    *      1st part is the filepath prefix. (eg. "a/b")
    *      2nd part is the actual file alias (eg. "c")
    *
    * We then look for the alias in the ABTest util class to see if there's a match, if not we'll look
    * for a match in the viewResolverConfig object
    *
    * @return promise, resolved with raw HTML, rejected with Exception
    */
   this.resolve = function (pageRef) {
      const parts = _resolve(pageRef);
      // return a promise for when the page loads off server
      return HTMLLoader.load(parts.path, parts.hash);
   };


   // -------------\\
   // -- PRIVATE -- \\
   //----------------\\

   /*
    * Function: construct
    * construct the map
    *
    * Parameters:
    * options - the options object that contains the pathToViews and aliasMap
    *
    */
   let _pathToViews,
       _map,
       _queryParam;


   function _resolve(pageRef) {
      const abTestResolver = Registry.getInterface(Constants.interfaces.kABTestResolver);

      let pathAlias  = "default",
          alias      = pageRef,
          pathToFile = null,
          page       = null;


      const hashIdx = alias.split('#');
      alias = hashIdx[0];
      const hash = hashIdx[1];

      // parse the pageRef into pathAlias.fileAlias
      const ns = Constants.nameSpaceRegex.exec(alias);
      if (ns) {
         pathAlias = ns[1];
         alias = ns[2];
      }

      pathToFile = _pathToViews[pathAlias];
      if (!pathToFile) {
         pathToFile = _pathToViews["default"];
      }
      if (!pathToFile.match(/\/$/)) {
         pathToFile += "/";
      }

      if (_map[pageRef]) {
         page = _map[pageRef];
         Logger.info("ALIAS REF: '" + pageRef + "' --> " + page, "VIEW RESOLVER");
      }
      else if (_map[Constants.kWildCard]) {
         page = alias + _map[Constants.kWildCard]; // wild card entry will have the default extension
         Logger.info("WILDCARD REF: '" + alias + "' --> " + page, "VIEW RESOLVER");
      }
      if (page) {
         pathToFile += page;
         Logger.info("FULL PATH RESOLUTION: '" + pageRef + "' --> " + pathToFile, "VIEW RESOLVER");
      }

      const pathToFileABTest = abTestResolver ? abTestResolver.getABTestPage(pageRef, pathToFile) : null;
      if (pathToFileABTest) {
         Logger.info(pageRef + " --> " + pathToFileABTest, "VIEW RESOLVER");
         pathToFile = pathToFileABTest;
      }

      const queryParam = _.isFunction(_queryParam) ? _queryParam() : _queryParam;
      if (_.isString(queryParam)) {
         const delimiter = pathToFile.indexOf("?") >= 0 ? "&" : "?";
         pathToFile += delimiter + queryParam;
      }

      return ({
         path: pathToFile,
         hash: hash || ""
      });
   }

   function _init() {
      const options = Config.get('application.viewResolverOptions');

      if (!options) {
         return Logger.error("missing options", "View Resolver")
      }

      if (options.cacheParam) {
         _queryParam = options.cacheParam;
      }

      if (_.isObject(options.pathToViews)) {
         _pathToViews = options.pathToViews;

         /* Looks for a variable ${foo} in the json object value. If found, will resolve the variable by looking for a key with
          * with the variable name and substituting it's value
          *
          * NOTE: This logic does NOT support a circular reference.
          *       It also does NOT support resolving an unresolved variable during the action of substituting.
          */
         for (const key in _pathToViews) {
            let matchedObj;
            while ((matchedObj = /\$\{(.*?)\}/g.exec(_pathToViews[key])) !== null) {
               if (_pathToViews[matchedObj[1]]) {//Test that the property exists
                  const replaceVal = _pathToViews[matchedObj[1]];
                  _pathToViews[key] = _pathToViews[key].replace(matchedObj[0], replaceVal);
               }
               else {
                  return Logger.error(matchedObj[0] + " variable cannot be found.", "View Resolver")
               }
            }
         }

         if (typeof _pathToViews["default"] !== "string") {
            return Logger.error("pathToViews must contain a 'default' entry.", "View Resolver")
         }
      }
      else if (_.isString(options.pathToViews)) {
         _pathToViews = {"default": options.pathToViews};
      }
      else {
         return Logger.error("pathToView is invalid.  Must be an object or string.", "View Resolver")
      }

      if (_.isObject(options.aliasMap)) {
         _map = options.aliasMap;
      }
      else {
         return Logger.error("aliasMap is invalid.  Must be an object", "View Resolver")
      }

   }

   // Self initialize and then listen for changes to the config.
   _init();
   PubSub.subscribe(Constants.events.kOptions, _init, this);

}

