import Constants from 'src/core/constants';

Constants.addValues({
    events : {
        kValidationFailed : "validationFailed"
    },
    jqEvents : {
        kAutoformat : "xinch.autoformat"
    },
    interfaces : {
        kValidationRenderer : "validationRenderer"
    },
    registry : {
        kInputStrategies : "inputStrategies"
    }
});
export default Constants;
