import $ from 'src/core/libs/zepto-1.1.3.custom';
import {get$} from "src/core/utils/$utils";

import {bindText, bindAttributes, bindData} from "./binder";

/**
 *
 * @param $container
 * @param options
 */
export function attach($container, options) {
   $container = get$($container);
   if (!$container.length) {
      $container = $('body');
   }
   options = options || {};
   bindText($container, options);
   bindAttributes($container, options);
   bindData($container, options);
}

/**
 * Remove bindings from the DOM.
 *      Otherwise memory leaks may occur if the DOM is blown away and formatters are still attached to elements
 *
 * @param $container  - the dom element identifier
 *                      or the X.$ element
 * @param excludeSelf - don't unbind the passed in parent container, just the children
 */
export function detach($container, excludeSelf) {
   $container = get$($container);
   $container.detach(excludeSelf);
}




