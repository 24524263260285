import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from "src/core/logging";
import Constants from 'src/data/constants';
import DataModel from 'src/data/model/DataModel';
import Schema from 'src/data/schema/Schema';
import Registry from 'src/data/registry/dataRegistry';

/**
 * Add a model
 *
 * @param  modelName :   string name of model (or instance of a X.data.Model
 * @param args object containing one or more optional properties:
 *          className:   string that is the class of the model to create - defaults to "X.data.Model"
 *          daoName:     name of DAO
 *          groupId:     name of a group to associate this model with (can be an array)
 *          schema:    reference to the JSON model schema (or the JSON object itself)
 *
 * @returns model instance
 */
export function addModel(modelName, args) {
   args = args || {};
   let schema;

   if (!modelName) {
      return Logger.error("addModel requires a valid name", "Data API")
   }
   else if (modelName instanceof DataModel) {
      return Registry.registerModel(modelName, true /* dont overwrite */);
   }

   // If the model name is invalid, bail
   if (!modelName.match(Constants.modelNameRegex)) {
      return Logger.error("invalid model name: '" + modelName + "'", "Data API")
   }


   // If the model already exists, get out cheap
   const existingModel = getModel(modelName);
   if (existingModel) {
      Logger.info("addModel: Model '" + modelName + "' already exists. Not adding model.", "Data API");
      return existingModel;
   }


   // turn schema into an an actual Schema instance if can be (model schemas should be pre-loaded)
   if (args.schema) {
      if (args.schema instanceof Schema) {
         schema = args.schema;
      }
      else if (_.isPlainObject(args.schema)) {
         schema = new Schema(args.schema);
      }
      else if (_.isString(args.schema)) {
         schema = Registry.getSchema(args.schema);
      }
   }

   // var className = args.className || Config.get('data.defaultModelClass') || "DataModel";
   // var ModelConstructor = getPathValue(className);
   const model = new DataModel(modelName, {
      daoName: args.daoName,
      schema: schema,
      groupId: args.groupId,
      inData: args.inData,
      allowInvalidDataInModel: args.allowInvalidDataInModel
   });


   return Registry.registerModel(model, true);
}


/**
 * Remove a model from the registry
 * @param name - the model name
 *
 * return promise
 */
export function removeModel(name) {
   const m = getModel(name);
   if (m) {
      m.destroy();
   }
   Registry.removeModel(name);
}


/**
 * Get a model based on its name
 * @param name - the model name
 * @param autoCreate - create the model if it does not exist
 * @returns the model instance
 */
export function getModel(name, autoCreate = false) {
   if (!name || !name.match(Constants.modelNameRegex)) {
      return Logger.error(`Model name: '${name}' is invalid, cannot contain spaces, single or double quotes`, "Data API")
   }
   let m = Registry.getModel(name);
   if (!m && autoCreate) {
      m = new DataModel(name);
      Registry.registerModel(m);
   }
   return m;
}


/**
 * Return an array of models names based upon the filters passed in against the passed in array of inModels
 * If no inModels are passed in, we'll return ALL models in the system.
 * @param filters
 *          - daoName : the name of the dao associated with the model
 *          - isDirty : has any of the data changed in the model
 *          - noError : the model is error free
 *          - groupId : the model is associated with a group
 *          - attr    : the model has the associated attribute
 *          - fn      : custom callback function that you write to return true | false
 * @param inModels - array of model names or instances to test against.  If empty we'll filter against ALL models in the system
 * @param returnModelInstances - return the array of model instances instead of the names
 * @returns {*}
 */
export function getModels(filters, inModels, returnModelInstances) {
   return Registry.getModels(filters, inModels, returnModelInstances);
}


/**
 * Get the models names associated with the given group
 *
 * @param groupName
 * @param returnModelInstances - return the array of model instances instead of the names
 *
 * @returns an associative array of modelNames: modelImpl
 */
export function getModelGroup(groupName, returnModelInstances) {
   return Registry.getModels({groupId: groupName}, null, returnModelInstances);
}


/**
 * Add a model to a specified group (or array of groups)
 * @param modelName
 * @param groupId - string or array
 */
export function addModelToGroup(modelName, groupId) {
   const m = getModel(modelName);
   if (m) {
      m.addToGroup(groupId);
   }
   else {
      Logger.error("addModelToGroup : model: " + modelName + " does not exist", "Data API");
   }
}