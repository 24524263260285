/**
 * @developers Greg Miller, Larry Buzi, Vitaliy Lee, Scott Norland
 *
 * WHAT IS Xinch:
 *
 * Xinch is a web application javascript framework for managing large scale, flow based applications.
 * It is a completely client-side web runtime framework that drives your customer experiences through configuration based flows.
 * At a high level, it could be considered a finite state machine...
 * but it is so much more. Xinch also facilitates the customer experience by abstracting complex UI paradigms into understandable HTML attributes so robust functionality can be coded by
 * non engineers and other content providers. No need to be JavaScript guru.
 *
 * In Xinch, you define your views in simple HTML and add Xinch specific attributes to DOM elements to hook up feature rich functionality such as two-way data-binding,
 * input formatting and validation, and navigation. No special tools are required to create your views. You can hand-code them if you are so inclined, or use some off the shelf tool for
 * development or templating. Xinch provides the facility to present your views to the customer and binds input data from the customer to models which can be persisted and managed.
 *
 * Hooking it all together is the Xinch Flow Controller. A configuration driven state machine that takes references to your views and strings them together in page to page flows.
 * You provide the transition logic in a simple configuration file, and Xinch handles the rest.
 *
 *
 * WHY USE Xinch:
 *
 * With the push to create rich, interactive user experiences quickly and easily across multiple platforms, the use of html and javascript is fast becoming a popular solution.
 * So it seems to be the latest craze to develop javascript based frameworks that facilitate creating these experiences and abstract away the extreme complexity involved in doing yourself.
 * That's a good thing, right. And there's a lot of really good frameworks out there.
 * So you are probably asking yourself, "Sheesh, another javascript framework.... REALLY?!"
 * In this case, YES, REALLY!
 *
 * Customer experiences in web apps can become extremely complex and hard to maintain using existing static routing mechanisms.  And the need for dynamic routing to drive
 * the personalized experiences your customers deserve does not exist in other frameworks.
 * There is no other client-side framework out there anywhere that solves for the configuration driven
 * state-machine and flow control that Xinch has implemented. If you want this sort of functionality, you have to incorporate server side code like Struts,
 * or Spring WebFlow and pay a contractor big bucks to support it.
 *
 * Xinch was built on the premise that it would be a one stop shop for creating a highly interactive, scalable, robust web applications so that you don't need to be a javascript guru to get up and running.
 * You won't need to incorporate 3-5 different frameworks to accomplish your vision. Nor would you need to write any server side code.
 *
 * Xinch JavaScript MVC Framework
 *
 **/

import pkg from '../../package';
import _ from 'src/core/libs/underscore-1.6.custom';

import Registry from 'src/core/registry/Registry'
import PubSub from 'src/core/pubsub';
import Profiler from 'src/core/profiler';
import Config from 'src/core/config/Config';
import Zepto from 'src/core/libs/zepto-1.1.3.custom';
import Http from 'src/core/http/http';
import defaultCoreOptions from 'src/core/config/options';
import Constants from 'src/core/constants';
import Logger from 'src/core/logging';
import defaultDataResolver from 'src/core/components/defaultDataResolver';
import defaultDataApi from 'src/core/components/defaultDataApi';
import defaultValidationApi from "src/core/components/defaultValidationApi";
import OfficialTime from 'src/core/utils/officialTime';

// Import files that do set up
/* eslint no-unused-vars: 0 */
import jqExtensions from 'src/core/jqExtensions/$extensions';
import objectExtensions from 'src/core/utils/objectExtensions';
import {toJSON} from "src/core/utils/JSONSerializer";
import {mergeObjects, getPathValue} from "src/core/utils/objectUtils";
import visit from 'src/core/utils/visit';


export default class XCore {

    constructor (subclassXinchOptions = {}) {
        // export common utilities
        this.version = pkg.version;
        this.pubsub = PubSub; // Expose the PubSub API as part of core
        this.profiler = Profiler; // Profiling capability
        this.logger = Logger; // Logging
        this.$ = Zepto; // expose our limited jQuery functionality
        this._ = _; // expose our limited underscore functionality
        this.constants = Constants; // Allow consumers to subscribe to our constants
        this.http = Http;
        this.utils = {
            getPathValue,
            toJSON,
            visit,
            deepMerge : mergeObjects
        };

        // set our own options
        Config.addXinchOptions(defaultCoreOptions);
        Config.addXinchOptions(subclassXinchOptions);


        // Register our default interfaces so sub projects can access them
        Registry.registerInterface(Constants.interfaces.kDataResolver, defaultDataResolver, false);
        Registry.registerComponent(Constants.components.kDefaultDataApi, defaultDataApi, false);
        Registry.registerComponent(Constants.components.kDefaultValidationApi, defaultValidationApi, false);

    }

    init (userOptions) {
        // set the user options
        this.setOptions(userOptions);
    }


    /**
     * setOptions
     *      Set a bunch of options
     * @param options - object that contains name/value pairs of options to set
     *
     */
    setOptions (options = {}) {

        // Always set options if passed
        if (!_.isPlainObject(options)) {
            Logger.error("Invalid config file: must be an object", "Xinch.setOptions");
            options = {};
        }

        // Copy the properties into the X.options
        // It will filter out all the crap
        _.each(options, (value, key) => {
            Config.setUserOption(key, value);
        });
    }

    getOption (path, defaultVal) {
        return Config.get(path, defaultVal);
    }

    /**
     * Allow application to hook into the logging mechanism
     * must implement
     *   error(msg, extraInfoObj)
     *   info(msg, extraInfoObj)
     *   warn(msg, extraInfoObj)
     *   debug(msg, extraInfoObj)
     * @param loggerImpl
     */
    setLogger (loggerImpl) {
        Logger.init(loggerImpl);
    }


    //----------------------------------------------
    // Dependency Injection Strategy
    //----------------------------------------------

    // Register an named object/component that you want to get a handle to later. Can be used for dependency injection
    registerComponent (name, component, replace = true) {
        return Registry.registerComponent(name, component, replace);
    }

    getComponent (name) {
        return Registry.getComponent(name);
    }

    registerInterface (name, impl, replace = true) {
        return Registry.registerInterface(name, impl, replace);
    }

    getInterface (name) {
        return Registry.getInterface(name);
    }

    //----------------------------------------------
    // Date / Time APIs
    //----------------------------------------------
    /**
     * Inform the application of the official time/date
     * @param d - Date object, or number of ms since 1/1/1970
     */
    setDate (d) {
        OfficialTime.setDate(d);
    }

    /**
     * Get the date that was previously set via a head request to the server or setDate
     * @returns {Date}
     */
    getDate () {
        return OfficialTime.getDate();
    }
}

// add our default dataAPIs to our prototype
Object.keys(defaultDataApi).forEach((key) => {
    XCore.prototype[key] = defaultDataApi[key];
});




