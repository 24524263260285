// This blows in IE (of course) but not consistently (of course)
// X.utils.uuid = function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)}

export default function (lastSignificant) {
   // http://www.ietf.org/rfc/rfc4122.txt
   const s = [];
   const hexDigits = "0123456789abcdef";
   for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
   }
   s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
   s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
   s[8] = s[13] = s[18] = s[23] = "-";

   const uuid = s.join("");
   const parts = uuid.split("-");
   return lastSignificant ? parts[parts.length - 1] : uuid;
}
