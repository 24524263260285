import $ from 'src/core/libs/zepto-1.1.3.custom'
import _ from "src/core/libs/underscore-1.6.custom";
import Constants from "src/core/constants";
import PubSub from "src/core/pubsub";

export default function TracePanel() {

   let _buffer       = '',
       _delayedPrint = -1;   // timer ID for delayed printing.  use -1 to mean it's not set.

   //
   // // list of all components we have logged so far,
   // // and a list of allowed components (only allowed components will be shown in the console)
   const _allComponents = [];
   const _disallowedComponents = [];

   const $traceViewPort = $('<div>');
   const $trace = $('<div id="trace_console_viewport">');

   const $traceTools = $('<div id="trace_console_tools">')
      .append(
         $('<span id="trace_help">' +
            '<div id="trace_help_inner_container">' +
            '<b>Top-level Components:</b>' +
            '<div id="trace_console_component_list"></div>' +
            '</div>' +
            '</span>')
      )
      .append($('<span class="trace_console_button" title="Add a Delimeter">')
         .text('Add Delimeter')
         .on('click', _sendDelimiter)
      )
      .append($('<span class="trace_console_button" title="Clear">')
         .text('Clear')
         .on('click', _clear)
      );

   $traceViewPort.append($trace);
   $traceViewPort.append($traceTools);


   function _sendDelimiter() {
      _traceIt('<div class="delimiter"></div>');
   }

   function _clear() {
      $trace.empty();
   }

   // -------------------------------
   // Function: send
   // send data to the window
   //
   // Parameters:
   //    text - the string data
   //    components - optional, array of components
   //    logLevel - optional log level
   // -------------------------------
   function _traceIt(text, component, logLevel) {

      if (component) {

         if (!_.contains(_allComponents, component)) {
            const currentComponent = component;
            const formattedComponentId = currentComponent.replace(/ /g, "_");
            // this component doesn't exist in our registered components list, add it
            _allComponents.push(component);

            // add it to the COMPONENT_LIST
            $("<div style='padding:4px 10px;border-width:0 0 1px;border-style:solid;border-color:#222;background-color:#333;margin-top:1px;'><input type='checkbox' id='" +
               formattedComponentId + "_checkbox' data-component-name='" + currentComponent +
               "' checked /><label for='" + formattedComponentId + "_checkbox' style='padding-left:5px;'>" +
               currentComponent + "</label></div>").appendTo("#trace_console_component_list");

            // when the component checkbox's value changes, update _disallowedComponents array
            $("#" + formattedComponentId + "_checkbox").change(function () {
               const checked = $(this).prop("checked");
               const componentName = $(this).attr("data-component-name");

               if (checked) {
                  // allow this component to be logged
                  _allowLoggingForComponent(componentName);
               }
               else {
                  // don't allow this component to be logged
                  _disallowLoggingForComponent(componentName);
               }
            });
         }

         // check to see if trace should be allowed (the components array are matched against the _allowedComponents array)
         if (_isTraceAllowed(component)) {
            // append the prefixes if it's defined
            text = '<span style="color:green;">[' + component + ']</span> ' + text;
         }
         else {
            // trace is not allowed because the component is not in the allowed list
            return;
         }
      }

      if (logLevel) {
         // if logLevel is specified, indicate it in the UI by displaying different colors
         switch (logLevel) {
            case Constants.log.WARN:
               text = '<span style="color:orange;">[WARNING]</span> ' + text;
               break;
            case Constants.log.ERROR:
               text = '<span style="color:red;">[ERROR]</span> ' + text;
               break;
            case Constants.log.INFO:
               text = '<span style="color:blue;">[INFO]</span> ' + text;
               break;
            // case Constants.log.CRITICAL:
            //    text = '<span style="color:#ff2aec;">- CRITICAL -</span> ' + text;
            //    break;
            case Constants.log.DEPRECATED:
               text = '<span style="color:yellow;">[DEPRECATED]</span> ' + text;
               break;
            default:
               break;

         }
      }

      /* for performance reasons, buffer the text and output it to the console at most once every 500 ms */
      _buffer = text + "<br/>" + _buffer;

      if ($trace !== null) {  /* just keep in the buffer if the viewport has not yet been built */
         if (_delayedPrint === -1) {   // if timer is not already set set
            _delayedPrint = setTimeout(() => {
               try {
                  const newContent = $('<span>' + _buffer + '</span>');
                  $trace.prepend(newContent);
                  _buffer = "";   // clear the buffer
                  _delayedPrint = -1;  // set flag indicating the timer is no longer set
               } catch (e) {
                  // do nothing
               }
            }, 500);
         }
      }
   }

   // -------------------------------
   // Function: _disallowLoggingForComponent
   // do not log data for component
   //
   // Parameters:
   //    component - the component
   // -------------------------------
   function _disallowLoggingForComponent(component) {
      _disallowedComponents.push(component);
   }

   // -------------------------------
   // Function: _allowLoggingForComponent
   // allow data to be logged for component
   //
   // Parameters:
   //    component - the component
   // -------------------------------
   function _allowLoggingForComponent(component) {
      const index = $.inArray(component, _disallowedComponents);
      if (index != -1) {
         _disallowedComponents.splice(index, 1);
      }
   }

   // -------------------------------
   // Function: _isTraceAllowed
   // checks to see if this trace with the specified components is allowed
   //
   // Parameters:
   //    components - the array of components
   // -------------------------------
   function _isTraceAllowed(component) {
      for (let i = 0; i < _disallowedComponents.length; i++) {
         if (_disallowedComponents[i] === "*" || _.contains(component, _disallowedComponents[i])) {
            // this component is in the allowed list, show it
            return false;
         }
      }

      return true;
   }


   PubSub.subscribe(Constants.events.kLogEvent, (logObj) => {
      _traceIt(logObj.message, logObj.component, logObj.logType)
   }, this);

   return $traceViewPort;


}
