import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';
import http from 'src/core/http/http';

import Registry from 'src/data/registry/dataRegistry';
import Schema from 'src/data/schema/Schema';
/**
 * Load a model schema (out of memory or the file system) and register it for use with models
 *  - if fullPathToSchema is an object, we'll assume that it is the schema definition and not the path.
 *    the object will be registered as a Schema an not pulled off the server
 *
 * @param name - name of the schema that will be registered
 * @param fullPathToSchema - the path to retrieve the json schema off disc ( of Schema definition object )
 * @param replace - boolean - if a schema with the name already is registered dont overwrite it.
 *
 * @returns {promise} resolved with a X.data.Schema instance.
 */
export function loadSchema (name, fullPathToSchema, replace = true) {

    // see if we already have a schema registered
    // if so, return it
    let schema = Registry.getSchema(name);
    if (schema && !replace) {
        return Promise.resolve(schema);
    }

    // if we were passed a schema definition, just register it.
    else if (_.isPlainObject(fullPathToSchema)) {
        schema = new Schema(fullPathToSchema);
        Registry.registerSchema(name, schema, replace);
        return Promise.resolve(schema);
    }

    else {
        return http.get(fullPathToSchema)
            .then((data) => { // success
                schema = new Schema(data);
                Registry.registerSchema(name, schema, replace);
                return schema;
            })
            .catch((ex) => { // error
                Logger.error("Failed to load schema: " + fullPathToSchema, "Schema Loader", ex);
                throw new Error("Failed to load schema: " + fullPathToSchema);
            });
    }
}

/**
 * Load one or multiple schemas off the server
 * @param configFile
 *      expecting file to be a json object map of name : path
 *      {
 *          foo : /path/to/fooSchema.json, (or schema definition object)
 *          bar : /path/to/barSchema.json
 *      }
 * @param replace - replace existing
 *
 * @returns {promise} resolved with an array of X.data.Schema instances.
 */
export function loadSchemas (configFile, replace) {
    const promises = [];

    _.each(configFile, (path, name) => {
        promises.push(loadSchema(name, path, replace));
    });

    return Promise.allSettled(promises);

}