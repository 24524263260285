import PubSub from './pubsub';
const PS = new PubSub();

//=========================================================
// Enhance functionality for handling our DOM Elements
//=========================================================
let _registeredDomElements = [];
const origSubscribe = PS.subscribe;

// Override the subscribe so we can save off DOM elements that are being bound to
PS.subscribe = function ( topic, callback, context) {
   if (context && context.nodeType && (context.nodeType === 1 || context.nodeType === 3)) {
      _registeredDomElements.push(context);
   }
   return origSubscribe.call(PubSub, topic, callback, context)
};

// Unsubscribe from PubSub if the callback condition is met
// If we unsubscribe, splice it out of our list of registered DOM elements
PS.offIfContextCondition = function (callback) {
   _registeredDomElements = _registeredDomElements.filter((domElement) => {
      // if this node passes the condition
      // Unsubscribe from pubsub
      // remove from our list
      if (callback(domElement)) {
         PS.unsubscribe(null, null, domElement);
         return false; // remove it from the registeredDomElements
      }
      return true;
   });
};

export default PS