import _ from 'src/core/libs/underscore-1.6.custom';

import Registry from "src/core/registry/Registry";
import Logger from 'src/core/logging';
import PubSub from 'src/core/pubsub';
import {mergeObjects} from "src/core/utils/objectUtils";
import DataModel from 'src/data/model/DataModel';
import Schema from 'src/data/schema/Schema';
import Constants from 'src/data/constants';


export default mergeObjects(Registry,{

    //==========  MODELS ============ \\
    //=================================\\

    registerModel : function (model, replace = true) {
        if (!(model instanceof DataModel)) {
            return Logger.error("Model must extend from DataModel", "Data Registry")
        }

        PubSub.publish(Constants.events.kModelRegistered, model.getName());
        return this._register(Constants.registry.kModels, model.getName(), model, replace);
    },

    getModel : function (name) {
        // if (X.flow && name === X.constants.scopes.kFlowScope) {
        //     name = X.application.Controller.getCurrentFlowScopeName();
        // }

        return this._get(Constants.registry.kModels, name);
    },

    // Get a list of models names in the system based on the filters passed in
    // If no filters, return all models
    // If inModels is passed in, the filtering is based on the inModels, not all in the system
    //    filter based on daoName, isDirty, noErrors, groupId, attr, fn
    getModels : function (filters = {}, inModels, returnModelInstances) {

        const all = inModels || this._getAll(Constants.registry.kModels);

        const filteredList = [];
        _.each(all, (model) => {
            if (_.isString(model)) {
                model = this.getModel(model);
            }

            // If no model in system 'continue' the loop by returning true out of the X._.each
            if (!model) {
                return true;
            }


            let passed = false;

            if (filters.isDirty && model.isDirty()) {
                passed = true;
            }
            // if (filters.daoName && model._dao == filters.daoName) {
            //     passed = true;
            // }
            if (filters.noErrors) {
                passed = !model.hasError();
            }
            if (filters.groupId) {
                passed = _.contains(model.getGroupIds(), filters.groupId);
            }
            if (filters.attr) {
                passed = model.getAttribute(filters.attr);
            }
            if (filters.fn && _.isFunction (filters.fn)) {
                passed = filters.fn(model);
            }

            // if no filters at all, we'll consider this a pass.
            if (Object.keys(filters).length === 0) {
                passed = true;
            }

            // if passed all our filters, put it in the list
            if (passed) {
                if (returnModelInstances) {
                    filteredList.push(model);
                }
                else {
                    filteredList.push(model.getName());
                }
            }
        });

        return filteredList;


    },

    removeModel : function (name) {
        const model = this._get(Constants.registry.kModels, name);
        if (!model) {
            return;
        }

        this._remove(Constants.registry.kModels, name);

    },


    // -------------------------------
    // Function: clear
    // clear the model
    //
    // Parameters:
    //    modelNames - the names of the models to clear
    // -------------------------------
    // clear : function (modelNames, args) {
    //     var self = this;
    //     modelNames = modelNames || _keys(this._getAll(Constants.registry.kModels));
    //     if (_.isString(modelNames)) {
    //         modelNames = [modelNames];
    //     }
    //
    //     _.each(modelNames, (name, idx) => {
    //         if (name && this._.contains(Constants.registry.kModels, name)) {
    //             this.getModel(name).clear(args);
    //         }
    //     });
    //
    // },


    //==========  DAO ============ \\
    //==============================\\

//     registerDAO : function (name, daoImpl, dontReplace) {
// //        if (!(daoImpl instanceof X.data.I_DAO)) {
// //            X.publish(X.constants.events.kException,
// //                new X.Exception("Data Registry", "DAO: " + name +
// //                                                    "  must extend from X.data.I_DAO", X.log.ERROR));
// //        }
//         daoImpl._name = name;
//         return this._register(X.constants.registry.kDAOs, name, daoImpl, dontReplace);
//     },
//
//     getDAO : function (name) {
//         return this._get(X.constants.registry.kDAOs, name);
//     },
//     getDAOs : function () {
//         return this._getAll(X.constants.registry.kDAOs);
//     },


    //==========  MODEL SCHEMAS ============ \\
    //=====================================\\
    registerSchema : function (name, def, replace = true) {
        if (!(def instanceof Schema)) {
            Logger.error(`Model Def: ${name} must be a Schema`, "Data Registry")
        }
        return this._register(Constants.registry.kSchemas, name, def, replace);
    },

    getSchema : function (name) {
        return this._get(Constants.registry.kSchemas, name);
    },
    getSchemas : function () {
        return this._getAll(Constants.registry.kSchemas);
    }
});