import Constants from 'src/core/constants';

Constants.addValues({

   specialDOMAttributes : ["data-visible", "data-disabled", "data-hidden"],
   booleanDOMAttributes : [/*"disabled",*/ "checked", "compact", "declare", "defer", "ismap",
      "multiple", "nohref", "noresize", "noshade", "nowrap", "readonly", "selected"],


   events : {
      kDataChange : "dataChange",
      kModelCreated : "modelCreated",
      kModelDeleted : "modelDeleted",
      kModelRegistered : "modelRegistered"
   },

   interfaces : {
      kDAO : "DAO"
   },

   registry : {
      kDAOs : "daos",
      kModels : "models",
      kSchemas : "schemas",
      kGroups : "groups"
   },

   modelNameRegex : /(^[^\s\r\n\'\"\.\,]+)$/,

   allDirectives: ['data-bind', 'data-listen', 'data-bind-options', 'data-listen-options']
});

export default Constants;

