/**
 * @author Greg Miller
 *
 * Data Bind
 * Purpose: easily enable two-way binding between a DOM elements and an Javascript object
 * USES:
 *   Bind an object to a DOM Element:   X.$('#DOM_ID').bindData(object);
 *
 * To specify what data gets bound to what input we are using an HTML 5 data attribute: data-bind example:
 *   <input name="Name" type="text" id="NameTextbox" data-bind="Name" value="" placeholder="Enter Name" />
 *
 * To specify a default value, use the following syntax:
 *     - refer to another model's property as the default value
 *          <input type="text" data-bind-options="defaultValue : '${model.property}'" />
 *     - specify plain text as the default value
 *          <input type="text" data-bind-options="defaultValue : 'plain text'" />
 *
 */

import $ from 'src/core/libs/zepto-1.1.3.custom';
import _ from 'src/core/libs/underscore-1.6.custom';
import Config from 'src/core/config/Config';
import PubSub from 'src/core/pubsub';
import Logger from 'src/core/logging';
import {getPathValue} from "src/core/utils/objectUtils";
import {splitDataRef} from "src/data/utils/utils";
import {setDataVal, getDataVal} from "src/data/utils/dataAccessor";
import Constants from 'src/data/constants';
import {getDataResolver} from "src/core/utils/getDataResolver";
import {attrToJSON} from "src/data/bindings/attributeBinder";
import {extend_$} from "src/core/utils/$utils";

extend_$({
    bindToModel : function (opts = {}) {
        const $el = $(this);


        const boundProperty = $el.attr("data-bind");
        const options = attrToJSON($el.attr("data-bind-options"), true) || {};
        options.$el = $el;
        options.viewport = opts.viewport;
        if (!boundProperty) {
            return;
        }


        // Get the value out of the data reference
        const parsedDataRef = splitDataRef(boundProperty);
        const boundData = getDataVal(parsedDataRef.modelName, parsedDataRef.key);
        const evtListeners = Constants.events.kDataChange + "." + parsedDataRef.modelName + "." + parsedDataRef.key;


        // Set the value of the control
        _setElementVal($el, boundData, options);


        // Now handle any options
        _handleBindingOptions($el, boundData, options);


        // Set the data in the model (which will broadcast the change event)
        // Only on change for input elements, we don't want to set data on div or table change events

        // *** In Chrome (as of v.25) the change event doesn't fire if the last change before an input field loses focus occurred programmatically (e.g. due to auto-formatter.)
        //     Therefore, we'll bind these input fields to the blur event.
        //        if ($el.is('[data-format]') && $el.is('input')) {
        //            $el.bind('blur', function () {
        //                _setDataVal(boundProperty, $el.val(), options);
        //            });
        //        }

        // determine which events will trigger a dataChange
        let changeEvent = options.dataBindEvent ? options.dataBindEvent : Config.get('data.dataBindEvent');
        changeEvent = _.isArray(changeEvent) ? changeEvent.join(" ") : changeEvent;

        if (($el.isTextInput())) {
            //changeEvent = changeEvent + " " + X.constants.jqEvents.kAutoformatApplied;
        }
        else if ($el.is('input') && ($el.attr("type") === "radio" || $el.attr("checkbox"))) {
            changeEvent = "click";
        }
        else if ($el.is('select')) {
            changeEvent = "change";
        }
        else if ($el.is('input')) {
            changeEvent = changeEvent + " " + "change";
        }
        else if ($el.attr("contentEditable") === "true") {
            changeEvent = changeEvent + " " + "input";
        }

        $el[0].dataChangeHandler = $el[0].dataChangeHandler || function (/*evt*/) {
                let val = null;
                if ($el.attr("type") === "checkbox") {
                    val = $el.prop("checked");
                }
                else if ($el.is("select")) {
                    val = $el.find(":selected").val();
                }
                else if ($el.attr("contentEditable") === "true") {
                    val = $el.text();
                }
                else {
                    val = $el.val();
                }

                _setDataVal(boundProperty, val, options);

            };
        $el.off(changeEvent, $el[0].dataChangeHandler).on(changeEvent, $el[0].dataChangeHandler);


        // Attach a listener for two way binding
        // Listen for changed data events and respond when data matches our data-bind attribute
        $el[0].databindListener = $el[0].databindListener || function (dataObj) {
                // if this data change was triggered by the same element that this element is
                // there is no need to reset the value on the element -- could lead to endless cycle??
                if (dataObj.$el === $el) {
                    return;
                }

                const _val = _.isUndefined(dataObj.index) ? dataObj.val : dataObj.val[dataObj.index];
                _setElementVal($el, _val, options);
                _handleBindingOptions($el, _val, options);
            };

        PubSub.subscribe(evtListeners, $el[0].databindListener, $el[0]);


        // Private function for setting the value of a control
        //-------------------------------------------------
        function _setElementVal ($el, data) {
            // input values can be set to null, but not undefined
            // change booleans to text we can do exact matching for radio/checkboxes that are bound to booleans in the model
            if (_.isUndefined(data)) {
                data = null;
            }
            else if (_.isBoolean(data)) {
                data = data.toString();
            }

            // Set the value of the control from data already in our model
            if ($el.attr("type") == "checkbox") {
                const checked = data && data !== "false";
                if (checked) {
                    $el.attr("checked", "checked");
                }
                else {
                    $el.removeAttr("checked");
                }
            }
            // if a radio - set the group selected item
            else if ($el.attr("type") == "radio") {
                const val = $el.val();
                if (data === val) {
                    $el.attr("checked", "checked");
                }
                else {
                    $el.removeAttr("checked");
                }

            }
            else if ($el.is('input') || $el.is('select') || $el.is('textarea')) {
                // save off the caret value so we can set it back
                $el.val(data);
            }
            else if ($el.prop("uiwidget") ) {
                const component = $el.prop("uiwidget");
                component.update(data)
            }
            else {
                //                X.publish(X.constants.events.kException,
                //                new X.Exception("Text binding", "Data binding to non input element: " + $el[0] + " - use ${ } inside of text node", X.log.DEPRECATED));
                $el.text(data);
            }

        }

        // Private function for setting data in a model
        // dataRef contains a full reference to a data value, which could be in a collection.  Ex: "XYZ.ModelName.CollectionName[5]"
        //-------------------------------------------------
        function _setDataVal (dataRef, value, options) {
            const parsedDataRef = splitDataRef(dataRef);
            setDataVal(parsedDataRef.modelName, parsedDataRef.key, value, options);
        }

        // Private function for handling binding options
        //======================================================
        function _handleBindingOptions (domEl, data, options) {
            if (!options) {
                return;
            }

            // If there is a function to call due to binding
            //----------------------------------------------
            const bindFunc = options.bindFunction;
            if (bindFunc) {
                const f = getPathValue(bindFunc);
                if (f) {
                    f(data, domEl); // call the function
                }
                else {
                    Logger.warn("Bind function not defined: " + bindFunc, "DataBinder");
                }
            }

            // See if we only need to populate the field once and not listen/broadcast changes
            // Only set the value if there is not data already present for this control
            if (!_.isUndefined(options.defaultValue)) {
                let _v = null;
                const m = splitDataRef(boundProperty);
                _v = getDataVal(m.modelName, m.key);
                // Make sure defaultValue is only set if the model data is equal to null, if it's empty string, we don't
                // want to set the defaultValue
                if (_v === undefined) {
                    const dv = getDataResolver().resolveDynamicData(options.defaultValue);
                    _setElementVal($el, dv, options);
                    _setDataVal(boundProperty, dv, options);
                }
            }
        }

    }
})


