/* 
 * class: DataModel
 *
 * about:
 * 	This is a baseclass javascript class that
 *    provides functionality for handling an In memory data model
 *
 *
 *   Constructor takes the following arguments:
 *       name : REQUIRED - the name of the mode (name CANNOT contain spaces, single, or double quotes)
 *       schema : OPTIONAL - a predefined defintion of the allowed names (keys) in the model.
 *                  If null or empty, the model can be created ad-hoc with any names
 *       mutable : OPTIONAL - allow names (keys) to be added to a predifined model schema.
 *
 *   Events : Constants.events.kModelCreated -- published when a model is constructed
 *           { "name" : name of the model,
 *             "id" : uuid of the model,
 *             "def" : the model schema object that this model uses (may be null)
 *             "groups" : array of groups that this model is associated with
 *           };
 *
 *           Constants.events.kDataChange - published whenever a data value is changed due to a setVal call
 *           {
 *             "name" : name of the model,
 *             "id" : uuid of the model,
 *             "groupdId" : the group that this model belongs to based on the defiition (may be null),
 *             "def" : the model schema for the key that is being changed
 *             modelName.key : value being set on the model
 *           }
 *
 *   Note : Derived classes should not override the construct (constructor) method.
 *          If there is any special construction functionality needed, override the 'init' function
 *
 *   Note : if no model schema is passed in, or the model schema is empty
 *          the model will be set as mutable.  If a model schema is passed in with values in it,
 *          mutable will be false unless otherwise specified.
 *
 *   Note : Persistence of models is defined outside of this class.  When a model is added to the system,
 *          it is done so with the X.addModel(model, <persistence strategy name>)  The persistence strategy is
 *          bound to the model and when a save/load is invoked on the strategy, it should query the this models
 *          'serialize' or 'deserialize' methods to get/set the internal data.
 *
 *
 *   Note : schema definition
 *          model schemas are objects created outside this class that describe the constraints of the attributes of model
 *
 *          {
 *               metaData : { // describe
 *                    version : <int>
 *                    mutable : <boolean>, // can elements be dynamically added to this model schema,
 *                                         // If no model schema is found, it will default to true,
 *                                         // If a model schema is found, it will default to false
 *                    groupId: <string>,   // Will group models that are based on this groupId for iterating.
 *                                         // Can be used for multiple copy functionality
 *                },
 *                <model element> : {
 *                     defaultValue: <value to be auto-assigned to this element>
 *                                   syntax is ${model.property} if you want to default to another models value
 *                                   or just a string value otherwise,
 *                     validate: <array> list of validators to be applied to this element,
 *                     format: <string> formatter to be applied to this element,
 *                     type: <STRING | BOOLEAN | NUMBER | DATE | ARRAY | OBJECT>,
 *                     accessibility : <string> reader text for the element
 *                     placeholderText : <string> default text showing in HTML5 compliant browsers
 *                     mapping : <desciptions of how this element is mapped to persistent storage>,
 *                },
 *
 *                ....
 *
 *          }
 *
 */
import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';
import PubSub from 'src/core/pubsub';
import uuid from 'src/core/utils/UUID';
import {toJSON} from "src/core/utils/JSONSerializer";
import {mergeObjects, getPathValue, normalizePath} from "src/core/utils/objectUtils";
import visit from "src/core/utils/visit";
import Constants from 'src/data/constants';
import Schema from 'src/data/schema/Schema';
import Registry from "src/data/registry/dataRegistry";

export default class DataModel {

   /**
    * construct a new data model
    *
    * @param args
    *    schema  - X.data.Schema instance
    *    groupId   - a group to associate this model to (a groupId can be specified in the model Def as well) and a model can be associated with multiple groups
    *    allowInvalidDataInModel - boolean - dont set data if it does not pass validation.
    *    inData - object to pre-populate the model with
    *
    * Note: Default values are set here during construction of the model if there is a definition file associated with the model.
    *       If default values are used in HTML markup, they are set when the UI element loads.
    */
   constructor(name, args = {}) {
      if (!name) {
         Logger.error("Constructor : 'name' parameter is required to create a model", `DataModel:`)
         return;
      }
      if (!name.match(Constants.modelNameRegex)) {
         Logger.error("Constructor : 'modelName' is invalid, cannot contain spaces, single or double quotes", `DataModel: ${this._name}`)
         return;
      }

      this._id = uuid();
      this._name = name;
      //      this._dao = args.daoName;
      this._model = {};
      this._pointers = {};
      this._attributes = {};
      this._groups = [];
      this._schema = new Schema();
      this._mutable = true;
      this._allowInvalidData = (_.isBoolean(args.allowInvalidDataInModel)) ? args.allowInvalidDataInModel : true;
      this._changedData = [];
      this._errorList = [];
      this._pointers = {};

      if (args.groupId) {
         this.addToGroup(args.groupId);
      }

      if (args.schema) {
         if (args.schema instanceof Schema) {
            this.setSchema(args.schema);
         }
         else if (_.isPlainObject(args.schema)) {
            this.setSchema(new Schema(args.schema));
         }
      }

      // got a model passed to us, use its data as to hydrate our model
      if (args.inData instanceof DataModel) {
         args.inData = args.inData.getAll();
      }

      // Got a string that represents JSON, turn it into an object
      else if (_.isString(args.inData)) {
         try {
            args.inData = toJSON(args.inData);
         } catch (ex) {
            Logger.error(`invalid JSON string passed to model constructor: ${args.inData}`, `DataModel: ${this._name}`, ex);
            args.inData = null;
         }
      }
      if (args.inData) {
         this.update(args.inData, {silent: true});
      }

      this.init();
      PubSub.publish(Constants.events.kModelCreated, {
         "name": this._name,
         "id": this._id,
         "def": this._schema,
         "groups": this._groups
      });
   }


   /**
    * Derived classes can add any functionality here to finish construction
    */
   init() {

   }

   /**
    * Do any clean up when a model is deleted
    */
   destroy() {
      PubSub.unsubscribe(this); // unsubscribe from everything
      PubSub.publish(Constants.events.kModelDeleted + "." + this._name,
         {"name": this._name, "id": this._id, "def": this._schema, "groups": this._groups});
   }


   /**
    * return the name of the data model
    * @returns {*}
    */
   getName() {
      return this._name;
   }

   /**
    * Add this model to the specified group(s)
    * @param groupId
    */
   addToGroup(groupId = []) {
      if (_.isString(groupId)) {
         groupId = [groupId];
      }
      this._groups = _.union(this._groups, groupId);
   }

   /**
    * return the groupIds if this model is associated with a common group of models
    * @returns {Array}
    */
   getGroupIds() {
      return this._groups;
   }


   /**
    * Set the model Defintion
    * @param schema
    */
   setSchema(schema) {

      if (!(schema instanceof Schema)) {
         Logger.error("Trying to set a schema that is not a DataSchema", `DataModel: ${this._name}`);
         return;
      }
      this._schema = schema;
      // Mutable - can the client add more properties to this model, or is it rigid based on the model schema
      this._mutable = this._schema.mutable();
      if (this._schema.groupId()) {
         this.addToGroup(this._schema.groupId());
      }

      // Set the default values
      // Iterate recursively through the schema and setting default values
      const dataResolver = Registry.getInterface(Constants.interfaces.kDataResolver);
      visit(this._schema.getAll(), (schemaValue, key, parentSchema, path) => {
         if (key === "defaultValue") {
            // found a default value in a schema item
            // we want to set the default value on the parent object so.
            // lop the last 'bit off the path if its nested as it be the path to 'defaultValue' we want one step up.
            const parentPath = path.substring(0, path.lastIndexOf('.'));
            this.setDataVal(parentPath, dataResolver.resolveDynamicData(schemaValue));
         }
      });
   }

   /**
    * returns the model schema as an instance of Schema
    *
    * @returns {*}
    */
   getSchema() {
      return this._schema;
   }


   /**
    * return the defintion for this element in this model
    *
    * @param key
    * @returns {*}
    */
   getSchemaforKey(key = "") {
      return this._schema.defForKey(key);
   }


   /**
    * attach a random attribute on a model (i.e. description)
    *
    * @param name
    * @param val
    */
   // -------------------------------
   setAttribute(name, val) {
      this._attributes[name] = val;
   }

   /**
    * retrieve a random attribute on a model may return null or undefined
    *
    * @param name
    * @returns {*}
    */
   getAttribute(name) {
      return this._attributes[name];
   }


   /**
    * has the data changed since the last time the dirty flag was cleared
    *
    * @returns true/false
    */
   isDirty() {
      return (!_.isEmpty(this._changedData));
   }


   /**
    * reset the dirty flag and any changed data
    */
   clearDirty() {
      this._changedData = [];
   }


   /**
    *
    * @param name - the name or key for the name/value pair
    * @param val - the value for the name/value pair
    * @param options [optional]
    *          silent - boolean to allow event not to be sent (default = false)
    *          force  - boolean to set the data even if it's readOnly (default = false)
    *          changed - marks data as having changed, even if the comparison shows otherwise.  Will force a dataChange event
    */
   setDataVal(name, val, options = {}) {
      const self      = this,
            _subModel = name.match(/^(.*?)\.(.*)$/),  // non-greedy will grab the key off the
            _key      = normalizePath(name, true).split(".")[0];
      let _obj,
          _oldVal,
          type   = null,
          _array = this.__isArray(name);

      // normalize the name
      // i.e. change the associated arrays in the format foo['bar'] -> foo.bar
      // also will normalize non-quoted indexes that are non-numeric foo[bar] -> foo.bar
      // but leaves along valid array indexes foo[0] -> foo[0]
      name = normalizePath(name, false);


      function __publishChange(_name, _val, oldVal) {
         if (options.silent) {
            return;
         }

         // broadcast a message that this data has changed
         const obj = {
            "$el": options.$el, // passed in from jqDataBinder
            "def": self.getSchemaforKey(name),
            "groupId": self._groupId,
            "modelName": self._name,
            "key": _name,
            "val": _val,
            "oldval": oldVal,
            "id": self._id
         };
         obj[self._name + '.' + _name] = _val;
         PubSub.publish(Constants.events.kDataChange + "." + obj.modelName + "." + obj.key, obj);

      }

      // If we're not allowed to extend this model schema and the
      // passed in name is not in our model
      if (!this._mutable) {
         if (!this._schema.hasKey(_key)) {
            return Logger.warn(`Model: ${this._name} is not mutable.  Cannot add '${name}' to the definition`, `DataModel: ${this._name}`);
         }
      }


      // unless options specify otherwise, do dispatch change event, and respect readOnly flag
      const defaults = {
         silent: false,
         force: false,
         trim: false
      };
      options = mergeObjects(defaults, options);

      // Trim the value if we need to
      if ((_.isString(val)) && options.trim) {
         val = val.trim();
      }

      const schema = this.getSchemaforKey(_key);

      if (schema && schema.readOnly) {
         if (!options.force) {
            // return without setting the readOnly property
            return Logger.warn(`Not setting read-only property'${name}' of ${this._name}`, `DataModel: ${this._name}`);
         }
      }

      // enforce schema validation if there is a schema defined
      // if we get a force flag passed to us, don't enforce schema validation.
      const enforceSchema = options.force ? false : (schema && schema.type);

      // make sure the value matches the model schema type(if it is defined), try converting it, if something goes wrong
      // log the error
      if (enforceSchema) {
         type = schema.type;

         //if (type.name) {
         //    type = type.name;
         //}
         if (_.isArray(type)) {
            if (type.length && _.isString(type[0]) && type[0].toLowerCase() === "pointer") {
               type = "ArrayOfPointers";
            }
            else {
               type = "Array";
            }
         }
         if (_.isPlainObject(type)) {
            type = "Object";
         }

         let errMsg = "",
             m, pointer;

         switch (type.toLowerCase()) {
            case "int" :
            case "integer" :
            case "number" :
               if (!_.isNumber(val)) {
                  try {
                     if (_.isString(val)) {
                        // See if the value contains a decimal
                        // get rid of the crap
                        val = val.replace(/[^0-9.-]/g, '');
                        val = (val.indexOf('.') >= 0) ? parseFloat(val) : parseInt(val, 10);
                     }
                  } catch (ex) {
                     val = null;
                  }

                  if (typeof val !== "number" || isNaN(val)) {
                     errMsg = `Cannot set '${name}' to value '${val}' - not a valid number`;
                  }
               }
               break;

            case "bool" :
            case "boolean" :
               if (!_.isBoolean(val)) {
                  try {
                     if (null === val) {
                        val = false;
                     }
                     else if (_.isString(val)) {
                        if ('true' == val || '1' == val) {
                           val = true;
                        }
                        else if ('false' == val || '0' == val) {
                           val = false;
                        }
                     }
                  } catch (ex) {
                     val = null;
                  }

                  if (!_.isBoolean(val)) {
                     errMsg = `Cannot set '${name}' to value '${val}' - not a boolean`;
                  }
               }

               break;

            case "string":
               if (!_.isString(val)) {
                  errMsg = `Cannot set '${name}' to value '${val}' - not a valid string`;
               }
               break;

            case "function" :
               if (_.isString(val)) {
                  val = getPathValue(val);
               }
               if (!_.isFunction(val)) {
                  errMsg = `Cannot set '${name}' to value '${val}' - not a valid function`;
               }
               break;

            case "array" :
               if (!_.isArray(val)) {
                  if (!_.isArray(name)) {
                     errMsg = `Cannot set '${name}' to value '${val}' - not a valid array`;
                  }
               }
               break;

            case "object" :
               if (!_.isPlainObject(val)) {
                  errMsg = `Cannot set '${name}' to value '${val}' - not a valid object`;
               }
               break;

            case "pointer" :
               // if the value comes in multiple parts, we'll assume that the first part is the model name to reference,
               // and the rest should be passed to the referenced model to handle

               // Request is to set up a pointer
               // because key is not in a.b format
               if (!_subModel) {
                  if (val instanceof DataModel) {
                     m = val;
                     val = m.getName();
                  }
                  else {
                     m = Registry.getModel(val);
                  }
                  if (!m) {
                     return Logger.warn(`Trying to set a model ( ${val} )that does not exist in the system`, `DataModel: ${this._name}`)
                  }
                  this._addPointer(_key, val);
                  this._model[_key] = m.getAll(); // reference the pointed to model as part of this model so it can be serialized
                  __publishChange(_key, this._model[_key]);

                  // subscribe to pointer being deleted
                  PubSub.once(Constants.events.kModelDeleted + "." + val, (/*modelInfo*/) => {
                     self._removePointer(_key);
                  });

               }
               // otherwise request is set pointer data
               else {
                  pointer = this._getPointer(_key);
                  m = Registry.getModel(pointer);
                  if (!m) {
                     return Logger.warn(`Trying to set a model ( ${pointer} )that does not exist in the system`, `DataModel: ${this._name}`)
                  }
                  // pass control down to the pointed to model to handle
                  m.setDataVal(_subModel[2], val, options);
               }
               return; // don't fall through to let default setting happen


            case "arrayofpointers" :
               // create the array if it hasn't been yet;
               self._model[_key] = self._model[_key] || [];

               // Request is to set up a pointer
               // because key is not in a.b format
               if (!_subModel) {
                  // reset the whole array
                  if (_.isArray(val)) {
                     // remove any existing pointers
                     self._addPointer(_key, val);
                     self._updateArrayOfPointers(_key);
                  }
                  // set a new pointer in the array
                  else if (_.isString(val) || val instanceof DataModel) {
                     // get the index and the key
                     const _arrayParts = name.match(/^(^[^\s\r\n\'\"\.\,]+)\[(\d*)]/);
                     if (_arrayParts) {
                        if (val instanceof DataModel) {
                           m = val;
                           val = m.getName();
                        }
                        else {
                           m = Registry.getModel(val);
                        }
                        if (!m) {
                           return Logger.warn(`Trying to set a model ( ${val} )that does not exist in the system`, `DataModel: ${this._name}`);
                        }
                        this._addPointer(_arrayParts[1], val, _arrayParts[2]);
                        this._model[_arrayParts[1]][_arrayParts[2]] = m.getAll(); // reference the pointed to model as part of this model so it can be serialized
                        __publishChange(_key, this._model[_key]);

                        // subscribe to pointer being deleted
                        PubSub.once(Constants.events.kModelDeleted + "." + val, self._modelDeleteListener, self, {
                           key: _arrayParts[1],
                           index: _arrayParts[2]
                        });
                     }
                     else {
                        Logger.warn("Trying to set a model pointer that is not a string", `DataModel: ${this._name}`)
                     }

                  }
               }
               // otherwise request is set pointer data
               else {
                  const __arrayParts = name.match(/^(^[^\s\r\n\'\"\.\,]+)\[(\d*)]/);
                  if (__arrayParts) {
                     const _pointer = this._getPointer(__arrayParts[1], __arrayParts[2]);
                     m = Registry.getModel(_pointer);
                     if (!m) {
                        return Logger.warn(`Trying to set a model ( ${pointer} )that does not exist in the system`, `DataModel: ${this._name}`)
                     }
                     // pass control down to the pointed to model to handle
                     m.setDataVal(_subModel[2], val, options);
                  }
                  else {
                     Logger.warn("Trying to set a model pointer that is not a string", `DataModel: ${this._name}`);
                  }
               }

               return;

            default:
               break;
         }

         if (errMsg) {
            return Logger.error(errMsg, `DataModel: ${this._name}`)
         }

      }


      // See if we block invalid data from getting into the model
      if (!this._allowInvalidData) {
         // if the element fails validation and it is not blank,
         // don't set it.
         if (val !== "" && !this.validateElement(name, val)) {
            // set an attribute on the model schema
            return;
         }
      }

      // if the n comes in as dot notation to a nested object, grab that information
      const _namespacedSet = name.match(/^(.*)\.(.*)$/);

      // xxx?.foo[0] = val
      if (_array) {
         _obj = this._findOrCreateCollection(this._model, _array[1], true);
         if (val instanceof DataModel) {
            const n = val.getName();
            this._addPointer(_array[1], n, _array[2]);
            val = val.getAll();
         }
         _obj[_array[2]] = val;
         this._setChanged(name);
         __publishChange(_array[1] + "." + _array[2], val);
      }
      // foo.bar = val
      else if (_namespacedSet) {
         _obj = this._findOrCreateCollection(this._model, _namespacedSet[1]);
         _array = this.__isArray(_namespacedSet[2]);
         if (_array) {
            _obj[_array[1]] = _obj[_array[1]] || [];
            _obj[_array[1]][_array[2]] = val;
         }
         else {
            _obj[_namespacedSet[2]] = val;
         }
         this._setChanged(name);
         __publishChange(name, val);

      }
      // foo = model
      else if (val instanceof DataModel) {
         _oldVal = val.getName();
         this._addPointer(_key, _oldVal);
         this._model[_key] = val.getAll();
         this._setChanged(_key);
         __publishChange(name, this._model[_key], _oldVal);

      }
      // name = val
      else {
         _oldVal = this._model[name];
         if (!_.isEqual(this._model[name], val)) {
            this._setChanged(name);
            this._model[name] = val;
         }

         // if setting an object, send a publish for each nested node
         if (_.isPlainObject(val)) {
            visit(val, (value, key, parent, path) => {
               __publishChange(path, value)
            }, name)
         }
         __publishChange(name, val, _oldVal);
      }
   }


   /**
    * Will return null if no name is in the model
    *
    * @param name - the name or key for the name/value pair
    * @returns {*} - null if non-existent
    */
   getDataVal(name) {
      // flatten the models arrays and objects to get to value
      return getPathValue(normalizePath(name, true), this._model);
   }


   /**
    * Remove an element completely out of the model (if it is mutable)
    *
    * @param name
    * @param options [optional]
    *          silent - boolean to allow event not to be sent (default = false)
    */
   remove(name, options) {
      if (options && !options.silent) {
         this.setDataVal(name, undefined, {force: true});
      }

      let _owner = this._model;

      // turn arrays and such into dot notation.
      name = normalizePath(name);

      // grab the name up to the last . which will be the key
      // if the request is for data in an object
      const _nv = name.match(/^(.*)\.(.*)$/);
      if (_nv) {
         _owner = getPathValue(normalizePath(_nv[1], true), this._model);
         name = _nv[2];
      }

      // Now do the deletion of the value
      const _array = this.__isArray(name);
      if (_array) {
         _owner[_array[1]] = _.without(_owner[_array[1]], _owner[_array[1]][_array[2]]);
      }
      else {
         delete _owner[name];
      }
   }


   /**
    * Will update the model with passed in javascript object (of name value pairs), if the new object contains properties
    * that the old one doesn't have, these properties will be added to the old object only if the old object is mutable.
    *
    * @param obj - where we want to get the new properties from
    * @param options [optional]
    *          silent - boolean to allow event not to be sent (default = false)
    *          force  - boolean to set the data even if it's readOnly (default = false)
    */
   update(obj, options) {
      // if obj is null or undefined, throw exception
      // cannot use typeof, because typeof null or object are both equal to "object"
      if (!_.isPlainObject(obj)) {
         Logger.error(`Trying to update model '${this.name}' with a invalid object`, `DataModel: ${this._name}`);
         return;
      }

      // visit all the nodes of the object and set the data values
      visit(obj, (value, key, parent, path) => {
         if (_.isUndefined(value)) {
            this.remove(path, options);
         }
         else {
            this.setDataVal(path, value, options);
         }
      });

   }


   /**
    * Get a reference to the internal data representation of our data model (JSON object)
    *
    * @param options
    *          changedDataOnly : <true | false> - get only data that has changed since the dirty flag was cleared
    *          excludeErrors : <true | false> - dont get elements that are in error
    *
    * @returns {*} - JSON Object
    */
   getAll(options) {
      options = options || {};
      let returnData = this._model;

      // Filter data on isDirty, not in error
      if (options.changedDataOnly) {
         returnData = _.pick(returnData, this._changedData);
      }
      if (options.excludeErrors) {
         this.validate();
         returnData = _.omit(returnData, this._errorList);
      }

      return returnData;
   }

   /**
    * Does a value exist in the model
    *
    * @param name
    * @returns {boolean}
    */
   has(name) {
      return !!getPathValue(normalizePath(name, true), this._model);
   }

   /**
    * Iterate over all elements of the data collected
    *
    * @param funcCall - function to call with the element
    *                   will be called with the element value, name, parentObject, and path as parameters,
    * @returns {*}
    */
   //---------------------------------------------
   each(funcCall) {
      visit(this._model, (value, key, parent, path) => {
         funcCall(value, key, parent, path);
      });
   }


   /**
    * Removes all attributes from the model (by setting them to null).
    * Fires a "change" event unless silent is passed as an option.
    *
    * @param options
    *      silent : boolean - don't broadcast dataChange event,
    *      restoreDefaultValues: boolean, reset values to default values defined in schema
    */
   clear(options = {}) {
      visit(this._model, (value, key, parent, path) => {
         this.remove(path, options);
      });
      this.clearDirty();

      if (options.restoreDefaultValues) {
         this.setSchema(this._schema);
      }
   }

   /**
    * Validates an element against the model schema's 'validate' list
    * sets or clears an internal list of elements that are in error;
    *
    * @param name
    * @param value
    * @returns boolean (is valid)
    */
   validateElement(name, value /*used for internal api*/) {
      let elDef = this._schema.getOptionForKey(name, "validate");
      const validationApi = Registry.getComponent(Constants.components.kDefaultValidationApi);

      if (!elDef) {
         return true;
      }
      else {
         if (_.isArray(elDef)) {
            elDef = elDef.join();
         }
         value = value || ((!_.isUndefined(this._model[name])) ? this._model[name] : "");
         const $el = "<input value='" + value + "' data-validate='" + elDef + "' />";
         const valid = validationApi.validateElement($el, {suppressErrors: true});

         if (!valid) {
            this._errorList.push(name);
         }
         return valid;
      }
   }

   /**
    * Validate all elements in a model against the model schema
    *
    * @returns {*}
    */
   validate() {
      this._errorList = [];
      _.each(this._schema.keys(), (n) => {
         this.validateElement(n);
      });

      return this._errorList;
   }


   /**
    * Return a list of element names that are in error;
    *
    * @returns {*}
    */
   getErrorList() {
      this.validate();
      return this._errorList;
   }

   hasError() {
      this.validate();
      return _.size(this._errorList) > 0;
   }


   /**
    * Save the data in this model to the DAO specified when creating the model
    * @param args
    *      - daoName : name of the dao to use even if there has been one specified in the constuction of the model
    *      - changedDataOnly : <true | false> - only serialize data that has changed since the dirty flag was cleared
    *      - excludeErrors : <true | false> - don't serialize elements that are in error
    *      - any name/value pairs that you want to pass to your DAO.  Some ones you can take advantage of for
    *                  leveraging Model Serialize API's
    *
    *     *  @return promise
    */
   // save (args) {
   //     args = args || {};
   //     var dao = this._getDAO(args.daoName);
   //     if (dao) {
   //         return dao.save([this], X._.omit(args, ["daoName"]));
   //     }
   //     else {
   //         return X.$.Deferred().resolve().promise();
   //     }
   // },

   /**
    * Load the model data from the DAO specified when creating the model
    * @param args
    *      - daoName : name of the dao to use even if there has been one specified in the construction of the model
    *
    *  @return promise
    */
   // load : function (args) {
   //     args = args || {};
   //     var dao = this._getDAO(args.daoName);
   //     if (dao) {
   //         return dao.load([this], X._.omit(args, ["daoName"]));
   //     }
   //     else {
   //         return X.$.Deferred().resolve().promise();
   //     }
   // },

   // _getDAO : function (name) {
   //     var dao = name || this._dao;
   //     dao = X.registry.getDAO(dao);
   //     if (!dao) {
   //         X.trace("Model DAO: " + this._name + "called with no DAO");
   //         return null;
   //     }
   //     return dao;
   // },

   // create the object hierarchy and return the last reference
   _findOrCreateCollection(parent, nameSpace, asArray) {
      let _array = this.__isArray(nameSpace);
      let _p;

      const _names = nameSpace.match(/^(.*?)\.(.*)$/);  // non-greedy will get name.rest.
      if (_names) {
         if (_names[1]) {
            _array = this.__isArray(_names[1]);
            if (_array) {
               parent[_array[1]] = parent[_array[1]] || [];
               parent[_array[1]][_array[2]] = parent[_array[1]][_array[2]] || {};
               _p = parent[_array[1]][_array[2]];
            }
            else {
               parent[_names[1]] = parent[_names[1]] || {};
               _p = parent[_names[1]];
            }
         }
         return this._findOrCreateCollection(_p, _names[2], asArray);
      }
      else if (_array) {
         parent[_array[1]] = parent[_array[1]] || [];
         parent[_array[1]][_array[2]] = parent[_array[1]][_array[2]] || (asArray ? [] : {});
         return parent[_array[1]][_array[2]];
      }
      else {
         parent[nameSpace] = parent[nameSpace] || (asArray ? [] : {});
         return parent[nameSpace];

      }

   }

   /**
    * Helper method to determine if a string is an array
    * @param key
    * @returns {*}
    * @private
    */
   __isArray(key) {

      return key.toString().match(/^(.*)\[(\d*?)\]$/);
   }

   _setChanged(key) {
      this._changedData = _.union(this._changedData, [key]);
   }

   /**
    * Pointers are kept in an object of the format
    *  {
    *      key : pointer
    *      key : [ pointer, pointer, pointer ]   // in the case of an array of pointers
    *  }
    * @param key
    * @param pointer
    * @param index
    * @private
    */
   _addPointer(key, pointer, index) {
      if (_.isUndefined(index)) {
         this._pointers[key] = pointer;
      }
      else {
         this._pointers[key] = this._pointers[key] || [];
         this._pointers[key][index] = pointer;
      }
   }

   _getPointer(key, index) {
      if (_.isUndefined(index)) {
         return this._pointers[key];
      }
      else {
         return this._pointers[key][index];
      }
   }

   _removePointer(key, index) {
      if (_.isUndefined(index)) {
         delete this._pointers[key];
         this.remove(key);
      }
      else {
         this._pointers[key].splice(index, 1);
         this.remove(key + "[" + index + "]");
         this._updateArrayOfPointers(key);
      }
   }

   _updateArrayOfPointers(key) {
      let m;
      // reset indexes of in array
      this._model[key] = [];
      let _validIdx = 0;
      _.each(this._pointers[key], (pointer) => {
         if (pointer instanceof DataModel) {
            m = pointer;
            pointer = m.getName();
         }
         else {
            m = Registry.getModel(pointer);
         }
         if (!m) {
            Logger.warn(`Trying to set a model ( ${pointer} )that does not exist in the system`, `DataModel: ${this._name}`)
            return; // will continue the loop
         }
         this._addPointer(key, pointer, _validIdx);
         this._model[key][_validIdx] = m.getAll(); // reference the pointed to model as part of this model so it can be serialized

         // subscribe to pointer being deleted
         PubSub.unsubscribe(Constants.events.kModelDeleted + "." + pointer, this._modelDeleteListener);
         PubSub.once(Constants.events.kModelDeleted + "." + pointer, this._modelDeleteListener, this, {
            key: key,
            index: _validIdx++
         });
      });
   }

   _modelDeleteListener(modelInfo, _context) {
      this._removePointer(_context.key, _context.index);
   }

   // TODO - if deleted model is in an array, we need to shift it somehow. incase another model is deleted

}

