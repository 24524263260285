// class: ValidationEngine

import $ from 'src/core/libs/zepto-1.1.3.custom';
import _ from 'src/core/libs/underscore-1.6.custom';
import {mergeObjects} from "src/core/utils/objectUtils";
import {get$} from "src/core/utils/$utils";
import {toJSON} from "src/core/utils/JSONSerializer";
import {isElementInView} from "src/core/utils/html_utils";
import Config from 'src/core/config/Config';
import Registry from 'src/validation/registry/ValidationRegistry';
import Constants from 'src/validation/constants';

class ValidationEngine {
   
   /* Public APIs */

   /****************************************************************************
    * Function: validateAll
    * Validate every field(that has a validate attribute in the parent container)
    * Parameters:
    *   container - the input element (jquery element OR element Id)
    *   options - validation options
    *        tooltipPosition : 'top', // position of tooltip relative to input.  supported values: 'top', 'bottom', 'right'
    *        hideOnFocus : false, // hide the tooltip when input field gets focus
    *        showOnlyOne : false, // show only one error tooltip
    *        showMultipleErrorsPerInput : false, // if there is more than one error, show them all
    *        validateOnBlur : false, // Perform validation on blur of an element
    *        suppressErrors : false, // Just validate and return the results, but don't show any errors messaging
    *        validateOnBack : false, // No validation if the customer hits back, keys off of "back" or your viewportHistory autoBackNavigationEvent
    *        validateOnJump : false, // No validation if the customer jumps in navigation
    *        useValidator : true // turn on/off the use of the validation functionality
    *
    * Returns:  true if passes all validation, false otherwise
    *
    * ****************************************************************************/
   validateAll(container, options) {
      const $container = get$(container);
      const opts = mergeObjects(Config.get('validation'), options || {});

      let isValid = true;

      const showOnlyOne = opts.showOnlyOne;

      this.hideErrorTooltips($container);
      $($container).find("[data-validate]").not(":hidden").not(":disabled").each( (idx, $el) => {
         $el = $($el);
         // Set the focus if we still are good on validation
         const setFocus = isValid;
         // only update set isValid flag to false if validateField returns false, otherwise
         // don't update isValid's value
         isValid = this.validateField($el, opts, setFocus) ? isValid : false;

         if (showOnlyOne && !isValid) {
            return false;
         }

      });

      return isValid;
   }

   /****************************************************************************
    * Function: validateField
    * Validate an individual input field
    *
    * Parameters:
    *   el - the input element (jquery element OR element Id
    *   options - validation options
    *        tooltipPosition : 'top', // position of tooltip relative to input.  supported values: 'top', 'bottom', 'right'
    *        hideOnFocus : false, // hide the tooltip when input field gets focus
    *        showOnlyOne : false, // show only one error tooltip
    *        showMultipleErrorsPerInput : false, // if there is more than one error, show them all
    *        validateOnBlur : false, // Perform validation on blur of an element
    *        suppressErrors : false, // Just validate and return the result, but don't show any errors messaging
    *        validateOnBack : false, // No validation if the customer hits back, keys off of "back" or your viewportHistory autoBackNavigationEvent
    *        validateOnJump : false, // No validation if the customer jumps in navigation
    *        useValidator : true // use the validation functionality
    *
    ****************************************************************************/
   validateField(el, options, setFocus) {
      const $el = get$(el);
      let opts = mergeObjects(Config.get('validation'), options || {});

      let elOpts = $el.attr('data-validate-options');
      elOpts = toJSON(elOpts, true) || {};
      opts = mergeObjects(opts, elOpts);

      this.setValidateOnBlur($el, opts, true);

      const suppressErrors = (opts.suppressErrors === true);

      // We added the 'validate' method to $ elements in the validationBinder
      const errors = $el.validate(opts.showMultipleErrorsPerInput);

      if (errors.length > 0) {
         // hack for radio buttons, only show the error on the first one....
         if ($el.prop("type") == "radio") {
            const name = $el.prop("name");
            const first = $("body").find("input[name='" + name + "'][type=radio]:first");
            if ($el[0] != first[0]) {
               return opts.__returnErrorObj ? {$el: $el, errorList: errors} : false;
            }
         }
         // there's an error, see if we need to display it
         if (!suppressErrors) {
            this._getRenderer().show($el, errors, opts);
            if (setFocus) {
               this._setFocus($el);
            }
         }
         return opts.__returnErrorObj ? {$el: $el, errorList: errors} : false;
      }
      else {
         // if there's an error tooltip, hide it
         this._getRenderer().hide($el);

         return true;
      }
   }

   /**
    * Run validation on all elements in the container
    *
    * @parameter $container - the input element ($ element OR element Id
    *                 If not container Id is supplied we'll search the whole body
    *
    * @return Array of errors
    *      {
    *          $el : $ element in error
    *          errorList : [] array of error messages
    *      }
    */
   getErrorList($container) {
      if ($container) {
         $container = get$($container);
      }
      else {
         $container = $("body");
      }

      const errorList = [];
      $($container).find("[data-validate]").not(":hidden").not(":disabled").each( (idx, $el) => {
         $el = $($el);
         const error = this.validateField($el, {suppressErrors: true, __returnErrorObj: true});
         if (_.isObject(error)) {
            errorList.push(error);
         }
      });

      return errorList;
   }


   hasErrorToolTips($container) {
      if ($container) {
         $container = get$($container);
      }
      else {
         $container = $("body");
      }

      return $container.find("[hasErrorTip]").length > 0;
   }

   /****************************************************************************
    * Function: hideErrorTooltips
    * hide all error tooltips
    * Parameters:
    *     $container - the input element (jquery element OR element Id
    *                 If not container Id is supplied we'll remove all
    ****************************************************************************/
   hideErrorTooltips($container) {
      if ($container) {
         $container = get$($container);
      }
      else {
         $container = $("body");
      }


      $container.find("[hasErrorTip]").each( (idx, $el) => {
         this._getRenderer().hide($($el));
      });
      if ($container.attr("hasErrorTip")) {
         this._getRenderer().hide($container);
      }

   }

   setValidateOnBlur($el, options, bValidateOnBlur) {
      // TODO - if bValidateOnBlur is false, remove the event handler
      const handlerSet = $el.prop("validationBlurSet") === true;

      // Attach blur event so we'll re-validate when the user tabs off
      // but only do it once!!
      if (!handlerSet) {
         $el.prop("validationBlurSet", true);
         //if (bValidateOnBlur) {
         $el.on("blur", () => {
            //var revalidationOpts = options || {};
            //// Only show one popup at a time, but allow corrected fields to dismiss the validation bubble
            //if (revalidationOpts.showOnlyOne) {
            //    //                       revalidationOpts.suppressErrors = true;
            //}
            this.validateField($(this), options);
         });
         //}
      }

   }

   showErrorMsg($el, msg, options) {
      $el = get$($el);
      options = options || {};

      this._getRenderer().show($el, [msg], options);
   }


   /****************************************************************************
    * Group: Private
    * Private APIs
    ****************************************************************************/

   _getRenderer() {
      return Registry.getInterface(Constants.interfaces.kValidationRenderer);
   }

   //-------------------------------
   // set focus on an element, this will be a convenience to the user
   // and scroll the input field into view if it is not already
   //-------------------------------
   _setFocus($el) {
      if (!isElementInView($el)) {
         ////check if the offset - 70 is negative and use 0 if it is.
         //var elOffset = ($el.offset().top - 70 > 0) ? $el.offset().top - 70 : 0;
         ////scroll
         //$('html,body').animate({scrollTop : elOffset }, 'fast');

         // Get the offset top of the element.
         //var elOffset = $el.offset().top;

         // Scroll to the element.  Scroll location tries to be place element in middle of screen.
         $el.scrollTo($(window).height() / 2, 200);

      }

      // and focus it
      $el.focus().select();
   }

}

export default new ValidationEngine();


