/***
 *  Css Loader: dynamically loads css files
 *    will check if it's already loaded
 *  EXAMPLE:
 *      new CssLoader("CSS/TTWFramework.css", function (response) {
 *          ... response true if successful ...
 *      });
 *  NOTE:
 *      not a singleton like most of our other stuff
 *      allows independent tracking of multiple load requests simultaneously
 *  WARNING:
 *      This doesn't not currently handle 404s appropriately
 */

import Logger from 'src/core/logging';

const CSSLoader = function () {

    const _instance = {
        /**
         * Dynamically load a javascript file
         *    - if file is already in memory, it will not load it again
         *    - will publish an exception if the file cannot be found or if the file cannot be parsed as a valid script
         *
         * @param pathToCSS – URI to find the css file
         * @param fileId – id to assign to the css file [optional]
         * @param media – media tyle to assign to the CSS file [optional] - defaults to 'screen'
         *
         *@returns nothing
         */
        load : function (/*css file name*/filePath, id, media) {

            // Check if the file has already been loaded
            if (!_isStyleLoaded(filePath)) {
                // method found here: http://www.backalleycoder.com/2011/03/20/link-tag-css-stylesheet-load-event/
                const head = document.getElementsByTagName('head')[0], // reference to document.head for appending/ removing link nodes
                    link = document.createElement('link');           // create the link node
                link.setAttribute('href', filePath);
                link.setAttribute('rel', 'stylesheet');
                link.setAttribute('type', 'text/css');
                link.setAttribute('id', id ? id : "");
                link.setAttribute('media', media ? media : "screen");


                head.appendChild(link);
                Logger.info("CSS Loaded: " + filePath, "CssLoader");

// TODO - check for 404
//                var img = document.createElement('img');
//                img.onerror = function () {
//                    (_.isFunction(callBack)) ? callBack(_isStyleLoaded(filePath)) : '';
//                };
//                img.src = filePath;
            }
            else {
                Logger.info("CSS Already Loaded: " + filePath, "CssLoader");
            }

            return Promise.resolve();
        }
    };


    /**
     * check if the stylesheet was loaded
     * - WARNING: if the file is not found the browser still adds it to the document.styleSheet array
     * @returns {boolean}
     */
    function _isStyleLoaded (filePath) {
        for (let i = 0; i < document.styleSheets.length; i++) {
            const sheet = document.styleSheets[i].href;
            const cleanFilePath = filePath.replace(new RegExp("\\.\\./", "g"), "");
            if (sheet && sheet.indexOf(cleanFilePath) !== -1) {
                return true;
            }
        }
        return false;
    }

    return _instance;
};

export default new CSSLoader();