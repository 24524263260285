import _ from 'src/core/libs/underscore-1.6.custom';
import Logger from 'src/core/logging';

//-------------
// Utility function to deep merge into target obj where source takes precedence
// returns a new merged object
export function mergeObjects(target, source, allowNewKeys = true) {
   const newObj = Object.assign({}, target);

   _.each(source, (item, key) => {
      if (!allowNewKeys && !newObj[key]) {
         Logger.info(`Not adding key: ${key}`)
      }
      else {
         // Property in destination object set; update its value.
         if (_.isPlainObject(item) && !_.isArray(item) && !_.isFunction(item) && !_.isRegExp(item)) {
            newObj[key] = mergeObjects(newObj[key], item, allowNewKeys);
         }
         else {
            newObj[key] = item;
         }
      }

   });
   return newObj;
}

//------------------------------------------------------
// Utility function to covert a string into an
// existing class function
// May return null if no function exists in the namespace
//------------------------------------------------------
export function getPathValue(str, context = window, defaultValue) {
   const arr = str.split(".");

   let fn = (context || window);
   for (let i = 0, len = arr.length; i < len; i++) {
      fn = fn[arr[i]];
      if (typeof fn === 'undefined') {
         return defaultValue;
      }
   }
   return fn;
}

/**
 * normalize the path request for a deep look into an object.
 * i.e. change the associated arrays in the format foo['bar'] -> foo.bar
 * also will normalize non-quoted indexes that are non-numeric foo[bar] -> foo.bar
 * but leaves along valid array indexes foo[0] -> foo[0]
 *
 * @param ref
 * @param convertArraysToObject - convert arrays to dot notation always foo[0] -> foo.0
 */
export function normalizePath(ref, convertArraysToObject) {
   return ref.replace(/(\[(.*?)\])/g, function () {
      if (arguments[1].match(/[\'\"]/) || arguments[2].toNum() === null || convertArraysToObject) {
         return "." + arguments[2].removeQuotes();
      }
      else {
         return arguments[0];
      }
   });
}

