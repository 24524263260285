/**
 }
 * get the element as a X.$
 */
import Zepto from 'src/core/libs/zepto-1.1.3.custom';
import _ from 'src/core/libs/underscore-1.6.custom';

export function get$(container) {
   if (Zepto.zepto.isZ(container)) {
      return container;
   }
   else if (typeof jQuery != 'undefined' && container instanceof jQuery) { /* jshint ignore:line */
      return Zepto(container[0]);
   }
   else if (_.isString(container)) {
      return Zepto("#" + container);
   }
   else {
      return Zepto(container);
   }
}

export function extend_$(extObj) {
   Zepto.fn.extend(extObj);
   if (typeof jQuery != 'undefined') {
      jQuery.fn.extend(extObj); /* jshint ignore:line */
   }
}
