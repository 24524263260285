/**
 * Bind Input elements to format on keyup if specified
 * @param container
 * @param options - viewport options
 */
import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';

import Config from 'src/core/config/Config';
import {get$} from "src/core/utils/$utils";
import {toJSON} from "src/core/utils/JSONSerializer";
import {mergeObjects} from "src/core/utils/objectUtils";
import Constants from 'src/validation/constants'
import ValidationEngine from 'src/validation/engine/ValidationEngine'

export function bindFormatters(container, options) {
   options = options || Config.get('formatter', {});
   if (!options.useFormatter) {
      return;
   }

   const $container = get$(container);

   // Find all of the input fields that have a "data-format" attribute
   const fields = $($container).find("[data-format]");

   // Iterate through all of the fields, and setup listeners and strategies
   $.each(fields, function () {
      const $control = $(this);
      let opts = toJSON($control.attr("data-format-options"), true) || {};
      opts = mergeObjects(options, opts);
      const fme = opts.formatEvent;
      const ignoreKeyCodes = opts.ignoreKeyCodes;
      const formatEvts = _.isArray(fme) ? fme.join(" ") : fme;


      if ($control.isTextInput() && formatEvts) {
         $control.on(formatEvts, (event) => {
            if (_.contains(ignoreKeyCodes, event.keyCode)) {
               return;
            }
            const valBefore = $control.val();
            $control.format(event);
            if ($control.val() != valBefore) {
               $control.trigger(Constants.jqEvents.kAutoformat);  // add event namespace corresponding to the event that triggered the formatOnLoad
            }
         });
      }

      // Look for format on load options and attach it as a property for the data-binder to look at
      // if the control is not an input control and there is a data-format attached to it, set it up for autoformat.
      const af = opts.autoFormat;
      if (af || !$control.isUserInputElement()) {
         $control.format($.Event(Constants.jqEvents.kAutoformat));
      }
   });
}

/**
 * Bind Input elements to validate on blur if specified
 * @param container
 * @param options - viewport options
 */
export function bindValidators(container, options) {
   options = options || Config.get('validation', {});
   if (!options.useValidator) {
      return;
   }

   const $container = get$(container);
   // iterate through all of the elements that have a "data-validate" attribute
   $($container).find("[data-validate]").each(function () {
      const $el = $(this);
      let opts = toJSON($el.attr("data-validate-options"), true) || {};
      opts = mergeObjects(options, opts);
      if (opts.validateOnBlur === true) {
         ValidationEngine.setValidateOnBlur($el, opts, true);
      }

   });
}


