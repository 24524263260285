import _ from 'src/core/libs/underscore-1.6.custom';

/**
 * Recursively calls itself until the current object key, has a non-object value.
 * once this is true, it will pass the key, value, parent object and path, into the visitor cb function.
 * calls the visit function with then following parameters
 *    (value, key, parentObject, stack)
 **/
function visit(obj = {}, visitorCB, stack) {
    Object.entries(obj).forEach(([key, value]) => {
        if (_.isPlainObject(value)) {
            visit(value, visitorCB, `${stack? (stack +'.') : ''}${key}`);
        } else {
            visitorCB(value, key, obj, `${stack? (stack +'.') : ''}${key}`);
        }
    });
}

export default visit;