import Logger from 'src/core/logging';

const component = "Registry";

export default {
   _register: function (nameSpace, name, componentImpl, replace = true) {
      const ns = this._createOrFindNameSpace(nameSpace);
      if (ns[name]) {
         if (!replace) {
            Logger.info(`registerComponent: Component '${name}' already exists. Not replacing`, component);
            return ns[name];
         }
         Logger.info(`Replacing component named: ${name}`, component);

         delete ns[name];
      }

      ns[name] = componentImpl;

      return componentImpl;
   },

   _get: function (nameSpace, name) {
      if (!this._registry[nameSpace]) {
         Logger.info(`Request for '${nameSpace}' : '${name}' does not exist`, component);
         return undefined;
      }
      else if (name) {
         return this._registry[nameSpace][name] || undefined;
      }
      else {
         return undefined;
      }
   },

   _getAll: function (nameSpace) {
      if (!this._registry[nameSpace]) {
         return undefined;
      }
      else {
         return this._registry[nameSpace];
      }
   },

   _getNames: function (nameSpace) {
      if (!this._registry[nameSpace]) {
         return [];
      }
      else {
         return Object.keys(this._registry[nameSpace]);
      }
   },


   _remove: function (nameSpace, name) {
      if (this._registry[nameSpace]) {
         delete this._registry[nameSpace][name];
      }
   },

   _has: function (nameSpace, name) {
      if (this._registry[nameSpace]) {
         return this._registry[nameSpace][name] ? true : false;
      }
      else {
         return false;
      }
   },

   _createOrFindNameSpace: function (nameSpace) {
      if (!this._registry[nameSpace]) {
         this._registry[nameSpace] = {};
      }

      return this._registry[nameSpace];

   },

   _registry: {},


   /**
    * FOR TESTING ONLY
    * @private
    */
   __reset () {
      this._registry = {};
   }
}


