// Bind well know events to any elements with the following attributes
// Supported events:
//      : data-nav = <navigation value>
//        data-nav-options = { validate: <true | false> }
//
//      : data-jump = <path value>
//        data-jump-options = { validate: <true | false> }
//
//      : data-loadpage = <pageAlias>
//        data-loadpage-options = { validate: <true | false> }
//
//      : data-loadflow = <flowAlias>
//        data-loadflow-options = { validate: <true | false> }
//
//      : data-event = <custom event name>
//        data-event-options = <{ custom event options }>
//
//      : data-set = <'model.propname':'value'>
//
// Attach the viewport to the event when it is fired

import _ from 'src/core/libs/underscore-1.6.custom';
import $ from 'src/core/libs/zepto-1.1.3.custom';
import PubSub from 'src/core/pubsub';
import uuid from 'src/core/utils/UUID';
import {getDataResolver, getDataApi} from 'src/core/utils/getDataResolver';

import {toJSON} from "src/core/utils/JSONSerializer";
import {extend_$} from "src/core/utils/$utils";

import {splitDataRef} from "src/data/utils/utils";

import Constants from 'src/application/constants';
import getContainingViewport from 'src/application/utils/getContainingViewport';
import Config from "src/core/config/Config";

extend_$({

   bindEvents: function ($parentContainer, inOptions) {
      let options;
      const $el             = $(this),
            _dataResolver   = getDataResolver(),
            _defaultDataAPI = getDataApi(),
            evtId           = uuid(),
            _context        = _.isObject(inOptions) ? inOptions.viewport : null;

      // Set up a click handler for this element that we can assign multiple events to
      $el.prop("eventList", []);
      $el[0].xEventClickHandler = $el[0].xEventClickHandler || function (evt) {
         const $el = $(this);
         if (!$el.attr('disabled')) {
            _.each($el.prop("eventList"), (func) => {
               func.call($el, evt);
            });
         }
      };

      // return a "throttled" function that can only be called once every 500ms
      function _throttleNavEvent(func, context) {
         let isBlocked = false;

         return function () {
            if (!isBlocked) {
               func.apply(context ? context : $el, Array.prototype.slice.call(arguments, 0));
               isBlocked = true;
            }

            setTimeout(() => {
               isBlocked = false;
            }, 500);
         };
      }

      function _addEventInfo(options) {
         options.$el = $el;
         options.context = _context || getContainingViewport($el) || Config.get('application.defaultViewport');
         options.eventId = evtId;
      }


      $el.off("click", $el[0].xEventClickHandler).on("click", $el[0].xEventClickHandler);

      // Execute any custom events or data setting before we navigate
      if ($el.attr('data-event')) {

         options = $el.attr('data-event-options');
         options = toJSON(options, true) || {};

         $el.prop("event-options", options);
         $el.prop("eventList").push(() => {
            const evt = _dataResolver.resolveDynamicData($el.attr('data-event'));
            let _opts = $el.prop("event-options");
            _opts = _dataResolver.resolveDynamicData(_opts);
            _addEventInfo(_opts);
            PubSub.publish(evt, _opts);
         });

      }
      if ($el.attr('data-set')) {

         const setProperty = $el.attr("data-set");
         const setObject = toJSON(setProperty, true) || {};
         $el.prop("eventList").push(() => {
            _.each(setObject, (property, name) => {
               const key = _dataResolver.resolveDynamicData(name);
               const val = _dataResolver.resolveDynamicData(property);

               const parsedProperty = splitDataRef(key);
               _defaultDataAPI.setDataVal(parsedProperty.modelName, parsedProperty.key, val);
            });
         });

      }

      // Navigation Events
      // Only one of these can be specified
      // Load Flow
      if ($el.attr('data-loadflow')) {
         options = $el.attr('data-loadflow-options');
         options = toJSON(options, true) || {};

         $el.prop("loadflow-options", options);

         $el.prop("eventList").push(_throttleNavEvent((event) => {

            let _opts = $el.prop("loadflow-options");
            _opts = _dataResolver.resolveDynamicData(_opts);
            _opts.flow = _dataResolver.resolveDynamicData($el.attr('data-loadflow'));
            _addEventInfo(_opts);

            event.preventDefault();
            PubSub.publish(Constants.events.kNavigation + "." + (options.viewport || _opts.context), _opts);
         }));

      }

      // Navigation Events
      // Load Page
      else if ($el.attr('data-loadpage')) {
         options = $el.attr('data-loadpage-options');
         options = toJSON(options, true) || {};

         $el.prop("loadpage-options", options);

         $el.prop("eventList").push(_throttleNavEvent((event) => {
            let _opts = $el.prop("loadpage-options");
            _opts = _dataResolver.resolveDynamicData(_opts);
            _opts.load = _dataResolver.resolveDynamicData($el.attr('data-loadpage'));
            _addEventInfo(_opts);

            event.preventDefault();
            PubSub.publish(Constants.events.kNavigation + "." + (options.viewport || _opts.context), _opts);
         }));

      }

      // Navigation Events
      // Navigate
      //-------------------------------
      else if ($el.attr('data-nav')) {
         options = $el.attr('data-nav-options');
         options = toJSON(options, true) || {};

         $el.prop("nav-options", options);

         $el.prop("eventList").push(_throttleNavEvent((event) => {

            let _opts = $el.prop("nav-options");
            _opts = _dataResolver.resolveDynamicData(_opts);
            _opts.nav = _dataResolver.resolveDynamicData($el.attr('data-nav'));
            _addEventInfo(_opts);

            event.preventDefault();
            PubSub.publish(Constants.events.kNavigation + "." + (options.viewport || _opts.context), _opts);
         }));

      }


      // Navigation Events
      // Jump
      else if ($el.attr('data-jump')) {
         options = $el.attr('data-jump-options');
         options = toJSON(options, true) || {};

         $el.prop("jump-options", options);

         $el.prop("eventList").push(_throttleNavEvent((event) => {

            let _opts = $el.prop("jump-options");
            _opts = _dataResolver.resolveDynamicData(_opts);
            _opts.jump = _dataResolver.resolveDynamicData($el.attr('data-jump'));
            _addEventInfo(_opts);

            event.preventDefault();
            PubSub.publish(Constants.events.kNavigation + "." + (options.viewport || _opts.context), _opts);
         }));

      }
   }
});
