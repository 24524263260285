/**
 * class: X.components.screenTransitioner
 *
 * Transition a screen in or out of view, based on bShow flag, and call the callback when done
 * .
 * The transitionScreen function receives options (see below) which contain the key/value pairs specified in the data-nav-options attribute of the html.
 * Those options can be used to vary the type of transition effect based on the current circumstances.
 * For example, one could specify in the html data-nav-options="{transition:"vertical",speed:"slow"}
 * This baseline implementation of the ScreenTransitioner doesn't use the options,
 * but it's easy to extend this implementation and register a different version using this syntax at the start of the application:
 * X.registerInterface(new MyEnhancedScreenTransitioner());  // MyEnhancedScreenTransitioner must extend X.flow.I_ScreenTransitioner.extend
 *
 */
export default function ScreenTransitioner() {

    /**
     * The main method of the ScreenTransitioner.  This method is responsible for transitioning a given screen into or out of view,
     * and calling the callback when the transition is complete.
     *
     * @param args - an object containing the following properties
     *      $target - jquery container which holds the screen that's to be transitioned
     *      callback - function which must be called when the transition is complete
     *      options - contains key/value pairs specified in the data-nav-options attribute.  These can be used to vary the transition effect based on the circumstance.
     */
    this.show = function (args) {
        const callback = args.callback;
//        var options = args.options || {};
        if (args.$target) {
            args.$target.fadeIn(200, callback);
        }
        else if (callback) {
            callback();
        }
    };

    this.hide = function (args) {
        // var self = this,
        //     options = args.options || {};

        if (args.$target) {
            args.$target.fadeOut(200, () => {
                if (args.callback) {
                    args.callback();
                }
            });
        }
        else if (args.callback) {
            args.callback();
        }

    };
}