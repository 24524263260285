/*
 * Formatter
 * Written by Greg Miller
 * Purpose: attach formatters to input fields so that data is formatted as the user types

 * This plugin is written for X.$ 1.6+
 *
 * valid formatter values
 * 	mask : <masked char set using the '#' symbol for replaceable characters
 * 	date : <masked date value using Y=year, M=month, D=day,  ex. DD/MM/YYYY
 * 	number : <precision> or null if no formatting is requested
 */
import $ from 'src/core/libs/zepto-1.1.3.custom';
import {extend_$} from 'src/core/utils/$utils';
import Constants from 'src/validation/constants';
import Executor from 'src/validation/engine/eventExecutor';

extend_$({
    format : function (event) {
        const $control = $(this),
            formatter = $control.attr("data-format");

        if (!formatter) {
            return;
        }
        // parse out the formatter name and any arguments
        const formatterParts = Constants.functionPartsRegex.exec(formatter);

        Executor.format($control, event, formatterParts[1], formatterParts[3]);
    }
});


