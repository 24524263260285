/**
 * This class encapsulates the functionality for Splunk logging.
 * The logger is assigned the value of the sandbox.logger
 * The instance of the Logger is exported. Every component which imports this gets the same instance
 */

import Constants from 'src/core/constants';
import PubSub from 'src/core/pubsub';
import Config from 'src/core/config/Config';
import LogObj from './LogObj';
import defaultLogger from "src/core/logging/defaultLogger";

class Logger {
   constructor() {
      this.logger = {};
   }

   /**
    * Initialize with an external logging mechanism
    * that override ours
    * @param logger
    *    {
    *       error, info, warn, profile, debug, deprecated
    *    }
    */
   init (logger) {
      this.logger = logger;
   }

   /**
    * Log an error
    * @param msgOrObject - string message of object of information to log
    * @param component - string identifying what part of the code is doing the logging
    * @param exceptionObj - native javascript Error() object if applicable
    */
   error (msgOrObject, component, exceptionObj) {
      const logObj = new LogObj(msgOrObject, component, Constants.log.ERROR, exceptionObj);
      this._send(logObj);
   }

   /**
    * Log a warning
    * @param msgOrObject - string message of object of information to log
    * @param component - string identifying what part of the code is doing the logging
    * @param exceptionObj - native javascript Error() object if applicable
    */
   warn (msgOrObject, component, exceptionObj) {
      const logObj = new LogObj(msgOrObject, component, Constants.log.WARN, exceptionObj);
      this._send(logObj);
   }

   /**
    * Log an info
    * @param msgOrObject - string message of object of information to log
    * @param component - string identifying what part of the code is doing the logging
    * @param exceptionObj - native javascript Error() object if applicable
    */
   info (msgOrObject, component, exceptionObj) {
      const logObj = new LogObj(msgOrObject, component, Constants.log.INFO, exceptionObj);
      this._send(logObj);
   }

   /**
    * Log a debug message (info)
    * @param msgOrObject - string message of object of information to log
    * @param component - string identifying what part of the code is doing the logging
    * @param exceptionObj - native javascript Error() object if applicable
    */
   debug (msgOrObject, component, exceptionObj) {
      const logObj = new LogObj(msgOrObject, component, Constants.log.DEBUG, exceptionObj);
      this._send(logObj);
   }

   /**
    * log profile information
    * @param profileData
    */
   performance (profileData) {
      const profileLog = {
         profile: profileData,
         message: "profile"
      };
      profileData.message = "profile";
      const logObj = new LogObj(profileLog, "Performance", Constants.log.PERFORMANCE);
      this._send(logObj);
   }

   /**
    * Fire off the request to the sandbox logging API if logger is set
    *   - based on Config values, log to the console as well
    *   - based on Config values, show a javascript alert on errors
    *   - log to external TTO service if constructor param is set
    * @param logObj
    * @private
    */
   _send(logObj) {
      const {message} = logObj;
      const type = logObj.logType.toLowerCase();
      // log our default way (to the console)
      if (defaultLogger[type]) {
         defaultLogger[type](message, logObj)
      }
      // now call the injected logger (if it exists)
      if (this.logger[type]) {
         this.logger[type](message, logObj)

      }
      // Publish the log event just for fun
      PubSub.publish(Constants.events.kLogEvent, logObj);

      if (Config.get('logging.alertOnExceptions') &&
         Config.get('logging.exceptionAlerts').includes(logObj.logType)) {
         alert(logObj.message); // eslint-disable-line no-alert
      }
   }
}

export default new Logger();
