import {logToConsole} from "src/core/logging/logToConsole";

export default {
   error: (message, data) => {
      logToConsole(message, data)
   },
   info: (message, data) => {
      logToConsole(message, data)
   },
   warn: (message, data) => {
      logToConsole(message, data)
   },
   performance: (message, data) => {
      data.message = JSON.stringify(data.profile);
      logToConsole(message, data)
   },
   debug: (message, data) => {
      logToConsole(message, data)
   },
   deprecated: (message, data) => {
      logToConsole(message, data)
   }
}