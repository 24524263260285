import _ from 'src/core/libs/underscore-1.6.custom';
import {toString, toJSON} from "src/core/utils/JSONSerializer";
import Config from 'src/core/config/Config';
import Logger from 'src/core/logging';

import {splitDataRef} from "src/data/utils/utils";
import Constants from 'src/data/constants';
import Registry from 'src/data/registry/dataRegistry';
import decodeURIComponentSafe from "src/core/utils/decodeURI";

const _originalObjectToString = Object.prototype.toString;
const _originalArrayToString = Array.prototype.toString;

export default class DataResolver {

   constructor() {
      this._expCache = [];
   }

   /**********************************************************************************
    * Resolve an object or string that can contain model references and/or could be an expression,
    *
    * Objects and Arrays will be returned in their native representation instead of [object Object]
    *
    * @param inData - the attribute value, before it was resolved
    * @param outModelRefs - return any model references in the attribute
    * @param addlTokens - additional information passed to the expression templating logic
    */
   resolveDynamicData(inData, outModelRefs, addlTokens) {
      addlTokens = addlTokens || {};
      outModelRefs = outModelRefs || {};
      let resolved;
      //var expressionVars = _reduceStringWithRefs(addlTokens);
      const expressionVars = addlTokens;

      if (!_.isPlainObject(inData) && !_.isString(inData)) {
         return inData;
      }

      if (_.isPlainObject(inData)) {
         inData = toString(inData);
         inData = this.resolveDynamicData(inData, outModelRefs, expressionVars);
         return toJSON(inData);
      }

      resolved = this._reduceStringWithRefs(inData, outModelRefs, expressionVars);

      // There are cases when model references are not resolved if they are not in an expression.
      resolved = resolved.replace(/_x_refs\[(\d)\]/g, function () {
         const _v = addlTokens._x_refs[arguments[1]];
         return toString(_v);
      });

      resolved = decodeURIComponentSafe(resolved); // unescape anything that may have been put in there

//    X.$.each(attrs, function (idx, attrObj) {
//    var resolved = X.utils.attributeUtil.resolveAttribute(attrObj.text, {});
//       _setResolvedAttribute($el, attrObj.attrName, resolved, false /*not initial layout*/);
//    });


      return resolved;
   }


   /**------------------------------------------------------
    *
    * Utility function to resolve any model references within a string
    * Note : will convert all model values to strings (like booleans, arrays, objects, etc)
    * Note2: will resolve nested model references such as ${${FLOW_SCOPE.modelName}.propertyName}
    *
    * Differs from resolveModelRefs in that this method does not expect the inStr to be a model reference.  It will only resolve the values in the string
    * So you can do concatenations and such ${model.name}.${model.val} or W2${FLOW_SCOPE.curIndex}.firstName
    *
    * @param inStr - the input string, which may contain model references
    * @param outModelRefs - optional object. if provided, the model refs will be added as properties of the object, each having the format 'modelName.modelProp'
    * @param expressionVars - information that has tokenized model references and tokenized string
    * @returns - the string with the model names resolved
    */
   _reduceStringWithRefs(inStr, outModelRefs, expressionVars) {

      expressionVars._x_refs = expressionVars._x_refs || [];

      if (!_.isPlainObject(inStr) && !_.isString(inStr)) {
         return inStr;
      }

      if (_.isPlainObject(inStr)) {
         inStr = toString(inStr);
         inStr = this.resolveDynamicData(inStr, outModelRefs, expressionVars);
         return toJSON(inStr);
      }

      // if not found, look for last ${} or @{}
      const firstExp = inStr.lastIndexOf('@{');
      const firstMR = inStr.lastIndexOf('${');

      const start = firstExp > firstMR ? firstExp : firstMR;
      const end = inStr.indexOf('}', start);


      // nothing to resolve - return
      if (start < 0 || end < 0) {
         return inStr;
      }

      const dataRef = inStr.substring(start, end + 1);

      // If this is an expression
      if (start === firstExp) {
         // if _x_refs are inside quotes - replace the value because it doesn't need to be a variable
         // expl
         // look for single or double quote
         // followed by any number of spaces
         // followed by _x_refs[number]
         // followed by any number of spaces
         // followed by the same opening quote mark

         inStr = inStr.replace(/(["'])(\s*)?(_x_refs\[(\d)\])(\s*?)?\1/g, function () {
            const rpl = toString(expressionVars._x_refs[arguments[4]]);
            const rtn = arguments[0].replace(arguments[3], rpl);
            return rtn;
         });
         inStr = inStr.replace(dataRef, this._resolveExpression(dataRef, expressionVars));

         return this.resolveDynamicData(inStr, outModelRefs, expressionVars);

      }
      // otherwise if a model reference
      else {
         let modelNameDotProp = inStr.substring(start + 2, end);
         let innerValue;

         // There are cases when model references are multi-nested that the properties have
         // been changed to references to the actual value in the tokenized info refs array.
         // Sort them out here.
         modelNameDotProp = modelNameDotProp.replace(/_x_refs\[(\d)\]/g, function () {
            return expressionVars._x_refs[arguments[1]];
         });

         const m = splitDataRef(modelNameDotProp);
         //      innerValue = X.getDataVal(m.modelName, m.key);
         const _model = Registry.getModel(m.modelName, Config.get('data.autoCreateModels', true));
         if (_model && m.key) {
            innerValue = _model.getDataVal(m.key);
         }
         // if no key, get the whole model
         else if (_model) {
            innerValue = _model.getAll();
         }

         // add the found model ref to the refs object, if it was passed in
         if (outModelRefs && m.modelName && !_.isUndefined(m.key)) {
            outModelRefs[m.modelName + '.' + m.key] = innerValue;
         }

         if (typeof innerValue === "undefined" || null === innerValue) {
            innerValue = Config.get('data.defaultTextForNullModelValue', '');
         }
         //else if (typeof innerValue == "object") {
         //    innerValue = X.utils.jsonSerializer.toString(innerValue);
         //}
         inStr = inStr.replace(dataRef, "_x_refs[" + expressionVars._x_refs.length + "]");
         expressionVars._x_refs.push(innerValue);

         return this.resolveDynamicData(inStr, outModelRefs, expressionVars);
      }
   }

   _resolveExpression(text, additionalTokens) {
      additionalTokens = additionalTokens || {};

      let fn;
      let resolved = text;

      resolved = resolved.replace(Constants.expressionRegexPattern, function () {
         return "<%=" + arguments[1] + "%>";
      });

      // now turn the expression into an underscore template.
      //----------------------------------------------------
      try {
         if (this._expCache[resolved]) {
            fn = this._expCache[resolved];
         }
         else {
            fn = _.template(resolved);
            this._expCache[resolved] = fn;
         }

      } catch (ex) {
         Logger.error(`Invalid expression: ${test} (${ex.message})`, "resolveAttribute", ex);
         resolved = "";
      }

      // Execute the underscore template.
      // Temporarily switch out the toString methods for Object and Array so that we use our version
      // i.e. we don't want to evaluate Object.toString to [object Object] but rather '{ ... }'
      //----------------------------------------------------
      try {
         const _extraInfo = this.resolveDynamicData(additionalTokens);
         Object.prototype.toString = function _tempToString() {
            return toString(this);
         };
         Array.prototype.toString = function _tempToString() {
            return toString(this);
         };
         resolved = fn(_extraInfo);
      } catch (ex) {
         Logger.error(`Expression error for ${text} : ${ex.message}`, "resolveExession", ex);
         resolved = "";  // don't subscribe or change the attr if there's an exception
      }
      Object.prototype.toString = _originalObjectToString;
      Array.prototype.toString = _originalArrayToString;


      return resolved;
   }
}


