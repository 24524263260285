import http from 'src/core/http/http';

class OfficialTime {

    constructor () {
        this._officialTime = null;
        //Get the official date off your server
        //Make a head request and grab the date header
        http.head("").then((response) => {
            const date = response.headers.get("Date");
            this._officialTime = new Date(date).getTime();
        });

        this._localTimeWhenSet = (new Date()).getTime();
    }

    /**
     * Inform the application of the official time/date
     * @param d - Date object, or number of ms since 1/1/1970
     */
    setDate (d) {
        if (d instanceof Date) {
            this._officialTime = d.getTime();
        }
        else if (typeof d === "number") {
            this._officialTime = d;
        }
        this._localTimeWhenSet = (new Date()).getTime();
    }


    /**
     * Get the date that was previously set via a head request to the server or setDate
     * @returns {Date}
     */
    getDate () {
        const adjustment = (new Date()).getTime() - this._localTimeWhenSet;

        return (new Date(this._officialTime + adjustment));
    }

}
export default new OfficialTime();
