import _ from 'src/core/libs/underscore-1.6.custom';
import {mergeObjects} from "src/core/utils/objectUtils";
import Registry from 'src/core/registry/Registry';
import Logger from 'src/core/logging';
import Constants from 'src/validation/constants';
import BaseStrategy from 'src/validation/strategies/BaseStrategy';
import StrategyInstance from 'src/validation/strategies/StrategyInstance';

export default mergeObjects(Registry,{

    registerStrategy : function (name, strategyObj, replace = true) {
        if (!_.isPlainObject(strategyObj)) {
            return Logger.error(`Invalid strategy definition - must be an object or instance of BaseStrategy : ${name}`, "X.validation.strategies");
        }
        if (!(strategyObj instanceof BaseStrategy)) {
            strategyObj = new StrategyInstance(name, strategyObj);
        }
        return this._register(Constants.registry.kInputStrategies, name, strategyObj, replace);
    },

    overrideStrategy : function (name, overrideObj) {
        const s = this.getStrategy(name);
        if (s) {
            // if the strategy already exists, add/replace with the new properties
            this.registerStrategy(name, overrideObj, true);
            Logger.info(`X.validation.strategies: overriding ${name} name`);

        }
        else {
            Logger.warn(`overrideStrategy: ${name} does not exist - not overriding`, "X.validation.strategies" );
        }

    },

    getStrategy : function (name) {
        return this._get(Constants.registry.kInputStrategies, name);
    },

    getStrategies : function () {
        return this._getAll(Constants.registry.kInputStrategies);
    }
});
