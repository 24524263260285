/**
 * Helper function to wrap promises so we can resolve them externally
 * instead of wrapping whole functions inside a promise constructor
 */
export default function defer () {
    let _resolve, _reject;
    const promise = new Promise((resolve, reject) => {
        _resolve = resolve;
        _reject = reject;
    });
    promise.resolve = (...args) => {
        _resolve.apply(promise, args);
        return promise;
    };
    promise.reject = (...args) => {
        _reject.apply(promise, args);
        return promise;
    };

    return promise;
}