import Registry from 'src/core/registry/Registry';
import {mergeObjects} from "src/core/utils/objectUtils";
import Constants from 'src/flow/constants';

export default mergeObjects(Registry,{
    registerFlow : function (name, flowObj, replace) {

        return this._register(Constants.registry.kFlows, name, flowObj, replace);
    },

    getFlow : function (name) {
        return this._get(Constants.registry.kFlows, name);
    },

    getFlows : function () {
        return this._getAll(Constants.registry.kFlows);
    }
});

