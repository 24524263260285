/*
 * class: InputStrategies
 * Maintains a list of validation strategies
 */
import {getArrayOfArgs} from "src/core/utils/function_utils";
import Registry from 'src/validation/registry/ValidationRegistry';
import Logger from 'src/core/logging';

const component = "Event Executor";

class EventExecutor {
   constructor() {
      this.VALIDATE = "validate";
      this.FORMAT = "format";
   }


   validate($el, name, params) {
      return this._execute(this.VALIDATE, $el, null, name, params);
   }

   format($el, event, name, params) {
      return this._execute(this.FORMAT, $el, event, name, params);
   }


   // -------------------------------
   // Function: executeStrategy
   // evaluate the validation or formatting strategy
   //
   // Parameters:
   //   action - the validation or format action to execute
   //   $el - the input element
   //   name - the strategy name
   //   params - the parameters
   // -------------------------------
   _execute(/* either validate or format */action, $el, event, /* strategy name */name, params) {
      const strategy = Registry.getStrategy(name);
      let result = null;

      if (!strategy) {
         // Check to see if the specified strategy exists
         Logger.warn("Missing strategy: '" + name + "'", component);
         return;
      }

      if (!strategy[action]) {
         // Check to see if the specified action (either validate or format) exists for this strategy
         Logger.warn("Missing action:" + action + " for strategy:" + name, component);
         return;
      }

      // Now turn the parameters into an array
      // and resolve any model references
      const args = getArrayOfArgs(params);

      try {
         // if there is no value and the name of the strategy is not
         // in the array of validator name that that should validate
         // when empty (i.e. required is one of them) then just return and don't validate. Otherwise
         // if the strategy is in the list, validate as if it were not empty
         if (action === this.VALIDATE && !$el.val() && !strategy.validateIfEmpty) {
            return "";
         }

         // add the $element as the first parameter
         args.splice(0, 0, $el);
         //add the event if it's a formatter
         if (action === this.FORMAT) {
            args.splice(1, 0, event);
         }
         result = strategy[action].apply(strategy, args);
      } catch (ex) {
         Logger.warn("Invalid " + action + " definition " + name + ": " + params, component, ex);
      }

      return result;
   }

}

export default new EventExecutor()
