// Check to see whether a DOM element is completely in view in the browser
import {get$} from 'src/core/utils/$utils'

export function isElementCompletelyInView($el) {
   const el = get$($el)[0];

   const rect = el.getBoundingClientRect();

   return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
   );
}


// Check to see whether a DOM element is at least partly visible in the browser
export function isElementInView($el) {
   const el = get$($el)[0];
   const rect = el.getBoundingClientRect();

   return (
      rect.top >= 0 &&
      rect.left >= 0
   );
}

// Check to see whether a DOM element is at least partly visible in the browser
export function isElementTopInView($el) {
   const el = ($el)[0];
   const rect = el.getBoundingClientRect();

   return rect.top >= 0;
}

export function htmlEscape(str) {
   return String(str)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
}

export function htmlUnescape(value) {
   return String(value)
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&');
}
