/*************************************************************************************
 * class:
 * Console
 *
 * about:
 * public APIs
 *    - enable()  // the first method that should be called to initialize the debug console
 *                // need a path to a css file so the console won't look ugly and messed up
 *    - disable()
 *    - openWindow()   // opens the debug console
 *    - closeWindow()  // closes the debug console
 *    - toggleWindow() // toggles the debug console on and off
 *    - send()    // logs a user message, can contain an array of components/classnames
 *                // which will get shown in different colors. Also these components will
 *                // be added an internal list of components, which the user can use to turn
 *                // on and off
 *    - addPanel()     // adds a DOM element into the debug window, the newly added DOM element
 *                     // will have its own tab
 *    - clearWindow()  // clear all of the existing logs
 *    - sendDelimeter()   // adds a delimiter in the logs window(aka viewport)
 *
 *************************************************************************************/
import $ from 'src/core/libs/zepto-1.1.3.custom';
import Constants from 'src/debug/constants';
import PubSub from 'src/core/pubsub';
import Logger from 'src/core/logging';

// import the debug styles
import 'src/debug/css/debug.scss'

export default function DebugConsole () {
    const _impl = {

        // -------------------------------
        // Function: enable
        // initialize the debug console
        // -------------------------------
        enable : function (bEnable = true) {
            _enabled = bEnable;

            if (_enabled) {
                _attach();
                PubSub.publish(Constants.events.kConsoleEnabled);
            }
            else {
                PubSub.publish(Constants.events.kConsoleDisabled);
                $($debugConsole).hide();
            }

        },

        // -------------------------------
        // Function: openWindow
        // open the window
        // -------------------------------
        open : function () {
            if (!_enabled) {
                return;
            }

            $("#debug_console_body").slideDown(500);

            _open = true;
            $("#debug_console_toggle_button").text("close");
        },

        // -------------------------------
        // Function: closeWindow
        // kill the window
        // -------------------------------
        close : function () {
            if (!_enabled || !_created) {
                return;
            }

            $("#debug_console_body").slideUp(500);

            _open = false;
            $("#debug_console_toggle_button").text("debug");
        },

        /************************************************
         * Function: addPanel
         * append new panel
         *
         * Parameters:
         * options - can have the following properties
         *     - label (display name of the panel, required)
         *     - id (id of the panel, required)
         *     - htmlPage - url to the resource on the server
         *     - $el - X.$ element to inject as a panel
         *     - setAsDefault - default the panel to on when attached
         *
         ************************************************/
        addPanel : function (options) {
            if (!options) {
                Logger.warn("cannot add panel without options", "debugConsole");
                return false;
            }

            const label = options.label,
                id = options.id,
                htmlpage = options.htmlpage || options.htmlPage,
                $el = options.$el;


            if (!label || !id) {
                Logger.warn("cannot add panel without label and id", "debugConsole");
                return false
            }
            if (!htmlpage && !$el) {
                Logger.warn("cannot add panel without a dom element or fileName", "debugConsole");
                return false
            }

            // if this dom element already exists don't add it again
            if ($("#" + id).length > 0) {
                return false;
            }

            // append the new menu button
            const newPanelButton = $('<button id="' + id + '_button" class="debug_tab">' +
                                        label + '</button>');
            $debugConsole.find("#debug_menu_bar").append(newPanelButton);
            newPanelButton.on("click", _onMenuButtonClick);

            // append the dom element to the debug_content div
            const $newPanel =  $('<div id="' + id + '" class="debug_panel_off"></div>');
            $debugConsole.find("#debug_content").append($newPanel);
            if ($el) {
                $newPanel.append($el);
//                $debugConsole.find("#debug_content").append($('<div id="' + id + '" class="debug_panel_off"></div>').append($el));
            }
            else {
                //var $newPanel = $('<div id="' + id + '" class="debug_panel_off"></div>');
                // IE fix w/ jQuery
                // if (document.all && document.documentMode && !_.isUndefined(jQuery)) { // jshint ignore:line
                //     $newPanel.load(htmlpage);
                // } else {
                    $newPanel.loadHTML(htmlpage);
         //       }
            }

            if (options.setAsDefault) {
                newPanelButton.click();
            }

            PubSub.publish(Constants.events.kDebugPanelAdded, {label: options.label, id: options.id})

            return true;
        }
    };


    /****************************************************************
     * Group: Private
     * Private API
     ***************************************************************/
    let _enabled = false,
        _created = false,
        _open = false,
        $debugConsole = null;
        // _trace = null,
        // _tracePanel = null,
        //
        // _buffer = '',
        // _delayedPrint = -1;   // timer ID for delayed printing.  use -1 to mean it's not set.

    // -------------------------------
    // Function: toggleWindow
    // open/close the window
    // -------------------------------
    function _toggleWindow () {
        if (_open) {
            _impl.close();
        }
        else {
            _impl.open();
        }
    }

    // -------------------------------
    // Function: onMenuButtonClick
    // handles the event where the user clicks on one of the menu buttons inside the console
    //
    // Parameters:
    //     event - the event to trigger
    // -------------------------------
    function _onMenuButtonClick (event) {
        const target = event.target;
        const buttonId = $(target).attr("id");
        const panelId = buttonId.replace(/_button/g, "");

        // toggle the buttons
        $(".debug_tab_on").removeClass("debug_tab_on");
        $(target).addClass("debug_tab_on");

        // toggle the panels
        $(".debug_panel_on").removeClass("debug_panel_on").addClass("debug_panel_off");
        $("#" + panelId).addClass("debug_panel_on").removeClass("debug_panel_off");

    }


    // -------------------------------
    // Function: attach
    // attach the console to the current document body
    // -------------------------------
    function _attach () {
        if (!_created) {
            $debugConsole = $('<div id="debug_console_wrapper">');

            const $toggle = $('<span id="debug_console_toggle_button" title="Toggle Debug">');
            $toggle.text("debug")
            $toggle.on('click', _toggleWindow);

            const $debugbody = $('<div id="debug_console_body">');
            const $debugmenu = $('<div id="debug_menu_bar">');
            const $debugcontent = $('<div id="debug_content">');

            $debugConsole.append($toggle);
            $debugConsole.append($debugbody);
            $debugbody.append($debugmenu);
            $debugbody.append($debugcontent);

            $('body').append($debugConsole);
            _enableResize();
            _created = true;
        }
        $($debugConsole).show();
    }

    function _enableResize () {
        let clicking = false;

        $(document).on('mousedown', "#debug_menu_bar", (e) => {
            clicking = true;
            e.preventDefault();
        });

        $(document).on('mouseup', () => {
            clicking = false;
        });

        $(window).on('mousemove', (e) => {
            if (clicking === false) {
                return;
            }
            //window.getSelection().removeAllRanges();

            // Mouse click + moving logic//
            // Browser Viewport
            const winHeight = $(window).height();
            // Height of mouse from bottom
            const mousePosnY = winHeight - e.clientY;

            $('#debug_console_body').height(mousePosnY);
            $('#debug_console_viewport').height(mousePosnY - 80);
        });
    }

    return _impl;

}


