export default {
    application: {
        ABTestConfig: {},

        viewResolverOptions: {
            pathToViews: {"default": "views"},
            aliasMap: {
                "*": ".html"
            },
            cacheParam: null
        },

        // VIEWPORT options --------------------------
        defaultViewport: null, // name of the data-viewport that will be used as the default viewport
        viewportOptions: {
            "default": {
                scrollToTop: true, // scroll to the top of the browser on page navigation.
                scrollOptions: {
                    toViewport: false, // scroll the top of the viewport into view upon navigation.
                    isAnimated: false, // slide the view or instantly jump.
                    animationDuration: 100
                },
                setFocusOnFirstInput: true, // set focus on the first visible input element on page when it renders
                setFocusInputTypes: ["input", "select", "textarea", "button"], // list of valid input types to automatically set focus on
                screenTransitionerClass: "X.components.ScreenTransitioner",
                viewportHistory: {
                    enableTracking: false, // Tell all viewports to manage their history
                    autoBackNavigationEvent: null,  // KEY navigation value used in data-nav that will trigger a "back" using the history to figure out where to go
                    historySize: null, // maximum number of views (or flow nodes) that will be tracked in the history, null for unlimited
                    allowDuplicates: false // allow for the a screen to be put on the history if the current screen is the same (to prevent backing up to the same page)
                },
                validationOptions: {}, // to be set by the validation/formatting module
                formatOptions: {} // to be set by the validation/formatting module
            }
        },

        // application debug alerting
        enableTraceConsole: true // Set up the debugging console
    }

}


