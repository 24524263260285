import DebugConsole from "src/debug/components/console";
import tracePanel from "src/debug/components/tracePanel";
import {getDataResolver} from "src/core/utils/getDataResolver";

export default function debugAPI () {
    const _debugConsole = new DebugConsole();

    return {

        /**
         *
         * @param enable
         */
        enableDebugConsole (enable = true) {
            _debugConsole.enable(enable);
           if (enable) { // will only add the panel once if it exists
              _debugConsole.addPanel({
                 label : "Xinch Trace",
                 id : "trace_console_menu",
                 $el : new tracePanel(),
                 setAsDefault : true
              });
           }
        },

        /************************************************
         * Function: addPanel
         * append new panel
         *
         * Parameters:
         * options - can have the following properties
         *     - label (display name of the panel, required)
         *     - id (id of the panel, required)
         *     - htmlPage - url to the resource on the server
         *     - $el - X.$ element to inject as a panel
         *     - bind - boolean to apply bindings once the panel is added
         *     - setAsDefault - default the panel to on when attached
         *
         ************************************************/
        addDebugPanel(options) {
            _debugConsole.addPanel(options)
        },

        openDebugConsole() {
            _debugConsole.open();
        },

        closeDebugConsole() {
            _debugConsole.close();
        },

        resolveExpression(expr, outModelRefs, addlTokens) {
            return getDataResolver.resolveDynamicData(expr, outModelRefs, addlTokens);
        }
    }
}