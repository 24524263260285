import _ from 'src/core/libs/underscore-1.6.custom';
import baseConstants from './constants';
import {mergeObjects} from "src/core/utils/objectUtils";

class Constants {

   constructor() {
      const values = mergeObjects({}, baseConstants);
      _.each(values, (value, key) => {
         this[key] = value;
      })
   }

   addValues (constants) {
      const values = mergeObjects(this, constants);
      _.each(values, (value, key) => {
         this[key] = value;
      })
   }

}

export default new Constants();