import $ from "src/core/libs/zepto-1.1.3.custom";
import Logger from 'src/core/logging';
import {getDataResolver} from "src/core/utils/getDataResolver";
import {toJSON} from "src/core/utils/JSONSerializer";

import Registry from 'src/application/registry/applicationRegistry'

class UIComponentFactory {

    create ($el) {
        const configStr = $el.attr("data");
        let component,
            configObj = {},
            $uiwidget;

        try {
            if (configStr) {
                configObj = getDataResolver().resolveDynamicData(configStr);
               if (typeof configObj === 'string') {
                  configObj = toJSON(configObj, true);
               }
            }
        }
        catch (ex) {
            Logger.error("could not parse data specification: " + configStr, "componentFactory", ex);
        }

        const componentName = $el.attr("name");
        const componentTemplate = Registry.getUIComponent(componentName);
        if (componentTemplate) {
            component = new componentTemplate();
        }
        else {
            Logger.error("The specified component " + componentName + " is not found in the list of registered components", "componentFactory")
            return;
        }

        // Get the DOM element from the create method
        $uiwidget = component.create(configObj);

        // Turn it into a Xinch element
        $uiwidget = $($uiwidget);
        $uiwidget.prop('uiwidget', component);

        // Copy any html attributes from the original <uiWidget ... >
        this.copyDataAttributes($el, $uiwidget);

        return $uiwidget;
    }

    // copy the data attributes from one X.$ element to another
    // Also copy some key attributes like ID and Name
    copyDataAttributes ($from, $to) {
        for (let i = 0; i < $from[0].attributes.length; i++) {
            const a = $from[0].attributes[i];
            // IE 7 has trouble setting the following attributes. Skip them!
            if (a.name != 'name' && a.name != 'data' && a.name != "dataFormatAs" && a.name != "implementation") {
                $to.attr(a.name, a.value);
            }
        }
        //IE 7 also seems to like to disable input attributes that have attributes copied over in this fashion.
        //so remove the disabled attr if it's not on the source element
        if ($from.attr("disabled") !== "disabled") {
            $to.removeAttr('disabled');
        }
    }

}

export default new UIComponentFactory();
