import Registry from 'src/core/registry/Registry'
import Constants from "src/core/constants";
import defaultDataResolver from "src/core/components/defaultDataResolver";
import defaultDataApi from 'src/core/components/defaultDataApi';

export function getDataResolver() {
   const resolver = Registry.getInterface(Constants.interfaces.kDataResolver);
   return resolver || defaultDataResolver;
}

export function getDataApi () {
   const api = Registry.getComponent(Constants.components.kDefaultDataApi);
   return api || defaultDataApi;
}